import { FC, memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { FileUploadConfirmForm } from './components/fileUploadConfirmForm/fileUploadConfirmForm'
import { UploadFile } from 'models/domain/uploadFile'
import { FormValues } from './components/fileUploadConfirmForm/useFileUploadConfirmForm'
export type FileUploadConfirmDialogProps = {
  title: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: FormValues) => void
  uploadFiles: UploadFile[]
  isSubmitting?: boolean
  showNeedNotification?: boolean
}

export const FileUploadConfirmDialog: FC<FileUploadConfirmDialogProps> = memo(
  (props) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <FileUploadConfirmForm
          onSubmit={props.onSubmit}
          onCancel={props.onClose}
          uploadedFiles={props.uploadFiles}
          isSubmitting={props.isSubmitting}
          showNeedNotification={props.showNeedNotification}
        />
      </Modal>
    )
  }
)
