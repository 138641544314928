import { FC, memo, useEffect } from 'react'

import { useGetContractPlanQuery } from '@crew/apis/contract/contractApis'
import { useValueChangeEffect } from '@crew/hooks'
import {
  useSubscribeUnread,
  useSubscribeUserSetting,
} from '@crew/providers/websocket'
import { useLoadPresenceStates, useLoadUserSettings } from '@crew/states'

import { setCurrentPlan } from 'features/app/states/appSlice'
import { useChangeDocumentTitle } from 'hooks/useChangeDocumentTitle'
import { useAppDispatch, useAppSelector } from 'states/hooks'

import { AppBar } from '../appBar/appBar'
import { AppContentPanel } from '../appContentPanel/appContentPanel'
import { AppLeftSideBar } from '../appLeftSideBar/appLeftSideBar'
import { AppRequestNotificationPermissionBar } from '../appRequestNotificationPermissionBar/appRequestNotificationPermissionBar'
import { AppRightSideBar } from '../appRightSideBar/appRightSideBar'

export const AppContainer: FC = memo(() => {
  useChangeDocumentTitle()

  const dispatch = useAppDispatch()
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const [loadUserSettings] = useLoadUserSettings()
  const [loadPresenceStates] = useLoadPresenceStates()

  // 個人設定関連のsubscribeを行う
  useSubscribeUserSetting(dispatch, useAppSelector)

  // 個人設定をAPI経由で取得してReduxに保存する
  useValueChangeEffect(
    () => {
      if (loggedInUser) {
        loadUserSettings()

        // Get presence states
        loadPresenceStates()
      }
    },
    [loggedInUser, loadUserSettings, loadPresenceStates],
    loggedInUser
  )

  // 未読件数のsubscribeを行う
  useSubscribeUnread()

  // get contract data information
  const { data: getContractPlanResult } = useGetContractPlanQuery()

  //set contractPlan to redux
  useEffect(() => {
    if (getContractPlanResult) {
      dispatch(setCurrentPlan(getContractPlanResult.plan))
    }
  }, [dispatch, getContractPlanResult])

  return (
    <>
      <div
        id="app-container"
        data-testid="app-container"
        // CREW-14584で報告されているように、ウィンドウ幅 < 750pxの場合、トップメニューのレイアウトが崩れる
        // 上記のエラーを発生しないように、コンテナの最小幅を750pxに設定する
        className="flex flex-col h-[100dvh] min-w-[750px]"
      >
        <AppBar />
        <AppRequestNotificationPermissionBar />
        <div className="flex flex-row flex-1 relative overflow-hidden">
          <AppLeftSideBar />
          <AppContentPanel />
          <AppRightSideBar />
        </div>
      </div>
    </>
  )
})
