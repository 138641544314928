import { memo } from 'react'
import { usePersonalSettingDisplayForm } from './usePersonalSettingDisplayForm'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewRequireDot } from 'components/elements/crewRequireDot'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting, useLoadUserSettings } from '@crew/states'
import { ROWS_PER_PAGE_OPTIONS } from 'configs/constants'
import { useToast } from 'hooks/useToast'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { EventTimePeriod, SettingKeyType } from '@crew/enums/app'
import { useCallback, useEffect, useMemo } from 'react'
import {
  AppTheme,
  Sort,
  UserChatSettingDisplayFormat,
  UserChatSettingDisplayRange,
} from 'enums/app'
import {
  DEFAULT_LIST_DISPLAY_NUMBER,
  FormValues,
  formInitialValues,
} from './usePersonalSettingDisplayForm'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
export const PersonalSettingDisplayForm = memo(() => {
  const {
    control,
    reset,
    handleSubmit,
    setError,
    validateRules,
    updateUserSettings,
    isLoadingInsertOrReplaceSetting,
  } = usePersonalSettingDisplayForm()

  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const [loadUserSettings] = useLoadUserSettings()

  // Get the display setting for logged in user
  const defaultAppearanceTheme = useUserSetting(
    SettingKeyType.AppearanceTheme,
    AppTheme.Light
  )
  const defaultChatDisplayFormat = useUserSetting(
    SettingKeyType.ChatDisplayFormat,
    UserChatSettingDisplayFormat.Timeline.value
  )
  const defaultChatDisplayRange = useUserSetting(
    SettingKeyType.ChatDisplayRange,
    UserChatSettingDisplayRange.DisplayAll.value
  )
  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_LIST_DISPLAY_NUMBER
  )
  const defaultTaskHistoryDisplayOrder = useUserSetting(
    SettingKeyType.TaskHistoryDisplayOrder,
    Sort.Ascending.value
  )
  const defaultShowNewArrival = useUserSetting(
    SettingKeyType.ShowNewArrival,
    'false'
  )
  const defaultShowMySchedule = useUserSetting(
    SettingKeyType.ShowMySchedule,
    'true'
  )
  const defaultShowMyTask = useUserSetting(SettingKeyType.ShowMyTask, 'true')
  const defaultShowStartUpGuide = useUserSetting(
    SettingKeyType.ShowStartUpGuide,
    'true'
  )
  const defaultScheduleInitialDisplay = useUserSetting(
    SettingKeyType.ScheduleInitialDisplay,
    EventTimePeriod.GroupMonthly.value
  )

  // Display saved settings on the screen
  useEffect(() => {
    //theme
    formInitialValues.theme = String(defaultAppearanceTheme)
    //list display number
    formInitialValues.displayNumber = Number(defaultListDisplayNumber)
    //chat display format
    formInitialValues.displayFormat = String(defaultChatDisplayFormat)
    //chat display range
    formInitialValues.displayRange = String(defaultChatDisplayRange)
    //task history display order
    formInitialValues.historyDisplayOrder = String(
      defaultTaskHistoryDisplayOrder
    )
    // メニュー: 新着の表示
    formInitialValues.showNewArrival =
      defaultShowNewArrival === 'true' ? true : false
    // メニュー: マイスケジュールの表示
    formInitialValues.showMySchedule =
      defaultShowMySchedule === 'true' ? true : false
    // メニュー: マイタスクの表示
    formInitialValues.showMyTask = defaultShowMyTask === 'true' ? true : false
    // メニュー: スタートアップガイド
    formInitialValues.showStartUpGuide =
      defaultShowStartUpGuide === 'true' ? true : false

    formInitialValues.scheduleInitialDisplay = String(
      defaultScheduleInitialDisplay
    )

    reset({ ...formInitialValues })
  }, [
    defaultAppearanceTheme,
    defaultChatDisplayFormat,
    defaultChatDisplayRange,
    defaultShowMySchedule,
    defaultShowMyTask,
    defaultShowNewArrival,
    defaultListDisplayNumber,
    defaultTaskHistoryDisplayOrder,
    defaultShowStartUpGuide,
    reset,
    defaultScheduleInitialDisplay,
  ])

  // Get the list of themes
  const appThemes = useMemo(() => {
    return Object.values(AppTheme).map((item) => {
      return {
        value: item,
        key: t(`label.${item}`),
      }
    })
  }, [t])

  // Get the list of display number
  const displayNumbers = useMemo(() => {
    return ROWS_PER_PAGE_OPTIONS.map((item) => {
      return {
        value: item,
        key: t('label.itemPerPage', { displayNumber: item }),
      }
    })
  }, [t])

  // Get the list of display format for chat message
  const displayFormats = useMemo(() => {
    return Object.values(UserChatSettingDisplayFormat).map((item) => {
      return {
        value: item.value,
        key: t(item.text),
      }
    })
  }, [t])

  // Get the list of display range for chat message
  const displayRanges = useMemo(() => {
    return Object.values(UserChatSettingDisplayRange).map((item) => {
      return {
        value: item.value,
        key: t(item.text),
      }
    })
  }, [t])

  // Get the list of history display order
  const historyDisplayOrders = useMemo(() => {
    return Object.values(Sort).map((item) => {
      return {
        value: item.value,
        key: t(item.text),
      }
    })
  }, [t])

  // Get the list of display format for chat message
  const scheduleInitialDisplayItems = useMemo(() => {
    return Object.values(EventTimePeriod).map((item) => {
      return {
        value: item.value,
        key: t(item.text),
      }
    })
  }, [t])

  // Event handle when the Save button is clicked
  const handleSaveButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // Execute update user setting process
        await updateUserSettings(data)

        // Display a toast indicating that the setting update was successful
        success(t('message.personalSetting.saveUserSettingSuccess'))

        // Reload the settings
        loadUserSettings()
      } catch (err) {
        // show error api
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    loadUserSettings,
    showApiErrors,
    success,
    t,
    updateUserSettings,
  ])

  return (
    <form className="flex flex-col gap-y-5">
      {/* 外観 */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.appearance')}</p>

        {/* 表示名 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.theme')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="theme"
            name="theme"
            className="w-80"
            items={appThemes}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.theme}
            displayExpr="key"
            valueExpr="value"
          />
        </div>
      </div>

      {/* メニュー */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.menu')}</p>

        {/* 新着 */}
        <div className="flex items-center justify-between h-8">
          <span className="crew-text-gray-4">{t('label.newArrival')}</span>
          <div className="w-80">
            <CrewCheckBoxField
              id="showNewArrival"
              control={control}
              name="showNewArrival"
              label={t('label.show')}
            />
          </div>
        </div>

        {/* マイスケジュール */}
        <div className="flex items-center justify-between h-8">
          <span className="crew-text-gray-4">{t('label.schedule')}</span>
          <div className="w-80">
            <CrewCheckBoxField
              id="showMySchedule"
              control={control}
              name="showMySchedule"
              label={t('label.show')}
            />
          </div>
        </div>

        {/* マイタスク */}
        <div className="flex items-center justify-between h-8">
          <span className="crew-text-gray-4">{t('label.myTask')}</span>
          <div className="w-80">
            <CrewCheckBoxField
              id="showMyTask"
              control={control}
              name="showMyTask"
              label={t('label.show')}
            />
          </div>
        </div>

        {/* スタートアップガイド */}
        <div className="flex items-center justify-between h-8">
          <span className="crew-text-gray-4">{t('label.startUpGuide')}</span>
          <div className="w-80">
            <CrewCheckBoxField
              id="showStartUpGuide"
              control={control}
              name="showStartUpGuide"
              label={t('label.show')}
            />
          </div>
        </div>
      </div>

      {/* リスト */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.list')}</p>

        {/* 表示件数 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.displayNumber')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="displayNumber"
            name="displayNumber"
            className="w-80"
            items={displayNumbers}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.displayNumber}
            displayExpr="key"
            valueExpr="value"
          />
        </div>
      </div>

      {/* チャット */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.chat')}</p>

        {/* 表示形式 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.displayFormat')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="displayFormat"
            name="displayFormat"
            className="w-80"
            items={displayFormats}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.displayFormat}
            displayExpr="key"
            valueExpr="value"
          />
        </div>

        {/* 表示範囲 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.displayRange')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="displayRange"
            name="displayRange"
            className="w-80"
            items={displayRanges}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.displayRange}
            displayExpr="key"
            valueExpr="value"
          />
        </div>
      </div>

      {/* タスク */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.task')}</p>

        {/* 履歴の表示順 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.historyDisplayOrder')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="historyDisplayOrder"
            name="historyDisplayOrder"
            className="w-80"
            items={historyDisplayOrders}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.historyDisplayOrder}
            displayExpr="key"
            valueExpr="value"
          />
        </div>
      </div>

      {/* スケジュール */}
      <div className="flex flex-col gap-y-2.5 py-2.5 border-b crew-border-gray">
        <p className="font-bold leading-5">{t('label.schedule')}</p>

        {/* 履歴の表示順 */}
        <div className="flex items-center justify-between">
          <span className="crew-text-gray-4">
            {t('label.initialDisplay')}
            <CrewRequireDot />
          </span>
          <CrewSelectBoxField
            control={control}
            id="scheduleInitialDisplay"
            name="scheduleInitialDisplay"
            className="w-80"
            items={scheduleInitialDisplayItems}
            showClearButton={false}
            minSearchLength={0}
            rules={validateRules.scheduleInitialDisplay}
            displayExpr="key"
            valueExpr="value"
          />
        </div>
      </div>

      {/* Action */}
      <CrewButton
        text={t('action.toSave')}
        type="primary"
        className="mr-auto"
        onClick={handleSaveButtonClick}
        disabled={isLoadingInsertOrReplaceSetting}
      />
    </form>
  )
})
