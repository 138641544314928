import { FileExtensions } from '@crew/enums/app';
/**
 * 引数のbase64エンコード文字列からblobに変換する関数
 * @param base64 base64エンコード文字列
 * @returns
 */
export const base64ToBlob = (base64) => {
    // base64データのファイルタイプを抜き出す
    const fileType = base64.slice(base64.indexOf(':') + 1, base64.indexOf(';'));
    var bin = window.atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    try {
        var blob = new Blob([buffer.buffer], {
            type: fileType,
        });
    }
    catch (e) {
        return;
    }
    return blob;
};
/**
 * convertToByte
 * @date 6/16/2023 - 2:02:57 PM
 *
 * @param {number} value
 * @param {('B' | 'KB' | 'MB' | 'GB')} [inputUnit='MB']
 * @returns {number}
 */
export const convertToByte = (value, inputUnit // bytes, kilobytes, megabytes, gigabytes
) => {
    switch (inputUnit) {
        case 'B':
            return value;
        case 'KB':
            return value * Math.pow(1024, 1);
        case 'MB':
            return value * Math.pow(1024, 2);
        case 'GB':
            return value * Math.pow(1024, 3);
        default:
            return value;
    }
};
/**
 * 引数のblobエンコード文字列からbase64に変換する関数
 * @param blob blobエンコード文字列
 * @returns
 */
export const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};
// react-doc-viewer Supported file types
// https://github.com/cyntler/react-doc-viewer#supported-file-types
export const PREVIEW_SUPPORTED_EXTENSIONS = [
    FileExtensions.Bmp,
    FileExtensions.Csv,
    FileExtensions.Odt,
    FileExtensions.Gif,
    FileExtensions.Htm,
    FileExtensions.Html,
    FileExtensions.Jpg,
    FileExtensions.Jpeg,
    FileExtensions.Pdf,
    FileExtensions.Png,
    FileExtensions.Tiff,
    FileExtensions.Txt,
    FileExtensions.Doc,
    FileExtensions.Docx,
    FileExtensions.Xls,
    FileExtensions.Xlsx,
    FileExtensions.Ppt,
    FileExtensions.Pptx,
];
// video support extensions by light box
export const PREVIEW_SUPPORTED_VIDEO_EXTENSIONS = [
    FileExtensions.Mp4,
    FileExtensions.Avi,
    FileExtensions.Webm,
    FileExtensions.Mov,
];
// video support file type by light box
export const PREVIEW_SUPPORTED_VIDEO_FILE_TYPES = [
    'video/mp4',
    'video/x-msvideo',
    'video/webm',
    'video/mov',
];
// generate file type from file extension
export const getFileType = (fileExtension) => {
    switch (fileExtension === null || fileExtension === void 0 ? void 0 : fileExtension.toLocaleLowerCase()) {
        // For the office file, the type of preview file is Pdf
        case FileExtensions.Pdf:
        case FileExtensions.Doc:
        case FileExtensions.Docx:
        case FileExtensions.Xls:
        case FileExtensions.Xlsx:
        case FileExtensions.Ppt:
        case FileExtensions.Pptx:
            return 'application/pdf';
        case FileExtensions.Txt:
            return 'text/plain';
        case FileExtensions.Tiff:
            return 'image/tiff';
        case FileExtensions.Csv:
            return 'text/csv';
        case FileExtensions.Gif:
            return 'image/gif';
        case FileExtensions.Htm:
            return 'text/htm';
        case FileExtensions.Html:
            return 'text/html';
        case FileExtensions.Png:
            return 'image/png';
        case FileExtensions.Jpg:
            return 'image/jpg';
        case FileExtensions.Jpeg:
            return 'image/jpeg';
        case FileExtensions.Bmp:
            return 'image/bmp';
        case FileExtensions.Odt:
            return 'application/vnd.oasis.opendocument.text';
        case FileExtensions.Mp4:
            return 'video/mp4';
        case FileExtensions.Avi:
            return 'video/x-msvideo';
        case FileExtensions.Webm:
            return 'video/webm';
        case FileExtensions.Mov:
            return 'video/avi';
        default:
            return 'application/octet-stream';
    }
};
// image package of backend only supports 3 formats jpeg, gif, and png
// https://pkg.go.dev/image#section-directories
export const MEETING_BACKGROUND_IMAGE_VALID_EXTENSIONS = [
    FileExtensions.Jpeg,
    FileExtensions.Jpg,
    FileExtensions.Png,
    FileExtensions.Gif,
];
// Check background image file support valid extensions
export const isValidMeetingBackgroundFile = (fileName) => {
    const fileExtension = fileName.toLowerCase().split('.').pop();
    return (MEETING_BACKGROUND_IMAGE_VALID_EXTENSIONS.findIndex((extensionItem) => extensionItem === fileExtension) > -1);
};
// Check preview file and video file support valid extensions
export const isValidPreviewFile = (fileName) => {
    const fileExtension = fileName.toLowerCase().split('.').pop();
    return (PREVIEW_SUPPORTED_EXTENSIONS.findIndex((extensionItem) => extensionItem === fileExtension) > -1 ||
        PREVIEW_SUPPORTED_VIDEO_EXTENSIONS.findIndex((extensionItem) => extensionItem === fileExtension) > -1);
};
// List thumbnail support valid extensions
export const THUMBNAIL_SUPPORT_EXTENSIONS = [
    FileExtensions.Mp4,
    FileExtensions.Webm,
    FileExtensions.Ogg,
    FileExtensions.Pdf,
    FileExtensions.Svg,
    FileExtensions.Png,
    FileExtensions.Jpg,
    FileExtensions.Jpeg,
    FileExtensions.Gif,
    FileExtensions.Bmp,
    FileExtensions.Tiff,
    FileExtensions.Tif,
    FileExtensions.Doc,
    FileExtensions.Docx,
    FileExtensions.Xls,
    FileExtensions.Xlsx,
    FileExtensions.Ppt,
    FileExtensions.Pptx,
];
// Check thumbnail support valid extensions
export const isValidThumbnailFile = (fileName) => {
    const fileExtension = fileName.toLowerCase().split('.').pop();
    return (THUMBNAIL_SUPPORT_EXTENSIONS.findIndex((extensionItem) => extensionItem === fileExtension) > -1);
};
// The extension list of office file
export const OFFICE_FILE_EXTENSIONS = [
    FileExtensions.Doc,
    FileExtensions.Docx,
    FileExtensions.Xls,
    FileExtensions.Xlsx,
    FileExtensions.Ppt,
    FileExtensions.Pptx,
];
// Check for valid office file
export const isOfficeFile = (fileName) => {
    const fileExtension = fileName.toLowerCase().split('.').pop();
    return (OFFICE_FILE_EXTENSIONS.findIndex((extensionItem) => extensionItem === fileExtension) > -1);
};
// check for valid preview video
export const isValidPreviewVideo = (fileName) => {
    const fileExtension = fileName.toLowerCase().split('.').pop();
    return (PREVIEW_SUPPORTED_VIDEO_EXTENSIONS.findIndex((extensionItem) => extensionItem === fileExtension) > -1);
};
