// enumの定義では恒常的にredeclareする(値と型)ため、no-redeclareチェックをファイル全体で無効化する
/* eslint-disable @typescript-eslint/no-redeclare */
import { AttentionType } from './domain';
/**
 * 時間軸上での向き
 */
export const TimelineDirection = {
    /** より新しい方 */
    Newer: 'newer',
    /** より古い方 */
    Older: 'older',
    /** Both newer and orlder */
    BothNewerAndOlder: 'bothNewerAndOlder',
};
// フィルタ条件（タスク種別）
export const TimeFlag = {
    Newer: 'newer',
    Default: 'default',
    Older: 'older',
    Independent: 'independent',
};
/**
 *  エンティティタイプ（タスク関連で使用）
 */
export const EntityTypeForTask = {
    Project: 'projects',
};
/**
 *  エンティティタイプ（ファイル関連で使用）
 */
export const EntityTypeForFile = {
    Project: 'projects',
};
/**
 * チャットの表示形式
 * - timeline: 追記スタイル(Rocket.Chatライク)
 * - threadList: スレッド一覧
 * - thread: スレッド表示
 */
export const ChatView = {
    /**  追記スタイル(Rocket.Chatライク) */
    Timeline: 'timeline',
    /** スレッド一覧 */
    ThreadList: 'threadList',
    /** スレッド表示 */
    Thread: 'thread',
};
/**
 * チャットの検索範囲
 * - allMessage: すべてのメッセージ
 * - onlyThisRoom: このチャットルームのみ
 */
export const SearchRange = {
    AllMessage: 'allMessage',
    OnlyThisRoom: 'onlyThisRoom',
};
// ブックマークのフィルタ
// TODO: messageSliceのArchiveFilterと情報が重複している。
//      下記タスクできれいにまとめる対応を行うまではどちらかに変更が発生した場合は両方を修正すること。
//      https://break-tmc.atlassian.net/browse/CREW-11591
export const BookmarkFilters = {
    Unarchive: {
        value: 'unarchive',
        text: 'label.unarchive',
    },
    Archived: {
        value: 'archived',
        text: 'label.archived',
    },
    All: {
        value: 'all',
        text: 'label.allBookmark',
    },
};
/**
 *  Type project setting member state
 */
export const MemberStateType = {
    Joined: { value: 'joined', text: 'label.joined' },
    Inviting: { value: 'inviting', text: 'label.inviting' },
    WaitingApproval: { value: 'waitingApproval', text: 'label.approvalPending' },
};
/**
 * 基本的なユーザ設定のキーリスト。
 *
 * dynamoDb.settings.Keyの値を定義しているため、キャメルケースに従う必要はない
 */
export const SettingKeyType = {
    // 個人設定/プロフィール/ユーザー情報/表示名
    UserProfileDisplayName: 'Profile/DisplayName',
    // 個人設定/プロフィール/ユーザー情報/別名(ふりがな)
    UserProfileAlias: 'Profile/Alias',
    // 個人設定/プロフィール/ユーザー情報/検索キー
    UserProfileSearchKey: 'Profile/SearchKey',
    // 個人設定/プロフィール/ユーザー情報/検索キー
    UserProfileEmail: 'Profile/Email',
    // 個人設定/プロフィール/ユーザー情報/コメント
    UserProfileLanguage: 'Profile/LanguageAndRegion/Language',
    // 個人設定/プロフィール/言語と地域/言語
    UserProfileRegion: 'Profile/LanguageAndRegion/Region',
    // 個人設定/表示/外観/テーマ
    AppearanceTheme: 'Appearance/Theme',
    // 個人設定/一覧/表示件数
    ListDisplayNumber: 'List/DisplayNumber',
    // 個人設定/表示/チャット/表示形式
    ChatDisplayFormat: 'Chat/DisplayFormat',
    // 個人設定/表示/チャット/表示範囲
    ChatDisplayRange: 'Chat/DisplayRange',
    // 個人設定/表示/タスク/履歴の表示順
    TaskHistoryDisplayOrder: 'Task/HistoryDisplayOrder',
    // 個人設定/表示/スケジュール/初期表示
    ScheduleInitialDisplay: 'Schedule/InitialDisplay',
    // 個人設定/表示/メニュー/新着
    ShowNewArrival: 'Menu/ShowNewArrival',
    // 個人設定/表示/メニュー/マイスケジュール
    ShowMySchedule: 'Menu/ShowMySchedule',
    // 個人設定/表示/メニュー/マイタスク
    ShowMyTask: 'Menu/ShowMyTask',
    // 個人設定/表示/メニュー/スタートアップガイド
    ShowStartUpGuide: 'Menu/ShowStartUpGuide',
    // 個人設定/通知/通知の方法/メンション
    NotificationMention: 'Notification/Mention',
    // 個人設定/通知/通知の方法/返信
    NotificationReply: 'Notification/Reply',
    // 個人設定/通知/通知の方法/メッセージ
    NotificationMessage: 'Notification/Message',
    // 個人設定/通知/通知の方法/リアクション
    NotificationReaction: 'Notification/Reaction',
    // 個人設定/セキュリティ/メールアドレス
    SecurityMailAddress: 'Security/MailAddress',
    // 個人設定/セキュリティ/パスワード
    SecurityPassword: 'Security/Password',
    // 個人設定/セキュリティ/2要素認証
    SecurityAuthenticationType: 'Security/AuthenticationType',
    // 個人設定/セキュリティ/認証コード
    SecurityAuthenticationCode: 'Security/AuthenticationCode',
    // 組織/組織/組織名
    OrganizationName: 'Organization/Name',
    // 組織/組織/組織のドメイン
    OrganizationDomain: 'Organization/Domain',
    // ドメインを制限する
    OrganizationRestrictDomains: 'Organization/RestrictDomains',
    // 組織/外部ユーザー/外部ユーザーの追加
    OrganizationExternalUser: 'Organization/ExternalUser',
    // 組織/外部ユーザー/外部ユーザーの有効期限(初期値)
    OrganizationExternalUserExpirationDate: 'Organization/ExternalUserExpirationDate',
    // 組織/セキュリティ/パスワードの文字数
    OrganizationPasswordLength: 'Organization/PasswordLength',
    // 組織/セキュリティ/複雑なパスワードを強制する
    OrganizationForceComplexPassword: 'Organization/ForceComplexPassword',
    // 組織/セキュリティ/2要素認証を強制する
    OrganizationForceTwoFactorAuthentication: 'Organization/ForceTwoFactorAuthentication',
    NotificationMeOnlyWhenOffline: 'Notification/MeOnlyWhenOffline',
    // 個人設定/セキュリティ/2要素認証/Destination
    SecurityTwoFactorAuthDestination: 'Security/TwoFactorAuth/Destination',
    // 個人設定/セキュリティ/2要素認証/Country Code
    SecurityTwoFactorAuthCountryCode: 'Security/TwoFactorAuth/CountryCode',
    // イベント管理
    DoEventManagement: 'DoEventManagement',
    // タスク管理
    DoTaskManagement: 'DoTaskManagement',
    // プロジェクト管理
    DoProjectManagement: 'DoProjectManagement',
};
/**
 * 基本的なユーザ設定のキーリスト。アプリ起動時に自動的に読み込まれてReduxに保管され、設定変更時に自動でリロードされる。
 */
export const GeneralSettingKeys = [
    SettingKeyType.ListDisplayNumber,
    SettingKeyType.TaskHistoryDisplayOrder,
    SettingKeyType.UserProfileLanguage,
    SettingKeyType.AppearanceTheme,
    SettingKeyType.ChatDisplayFormat,
    SettingKeyType.ChatDisplayRange,
];
/**
 * 役割コード
 *
 * 権限はドキュメント上でもスネークケースで定義しているため、キャメルケースに従う必要はない
 */
export const Roles = {
    SysTenantAdmin: {
        value: 'sys_tenant_admin',
        text: 'label.roles.sysTenantAdmin',
    },
    SysAdmin: {
        value: 'sys_admin',
        text: 'label.roles.sysAdmin',
    },
    SysUser: {
        value: 'sys_user',
        text: 'label.roles.sysUser',
    },
    SysExternalUser: {
        value: 'sys_external_user',
        text: 'label.roles.sysExternalUser',
    },
    PrjAdmin: {
        value: 'prj_admin',
        text: 'label.roles.prjAdmin',
    },
    PrjMember: {
        value: 'prj_member',
        text: 'label.roles.prjMember',
    },
    PrjGuest: {
        value: 'prj_guest',
        text: 'label.roles.prjGuest',
    },
};
export const Permissions = {
    SysContractViewEdit: {
        value: 'sys_contract_view_edit',
        text: 'label.permissions.sysContractViewEdit',
        type: 'system',
    },
    SysTenantSettingViewEdit: {
        value: 'sys_tenant_setting_view_edit',
        text: 'label.permissions.sysTenantSettingViewEdit',
        type: 'system',
    },
    SysProjectCreateDelete: {
        value: 'sys_project_create_delete',
        text: 'label.permissions.sysProjectCreateDelete',
        type: 'system',
    },
    SysPublicProjectViewJoin: {
        value: 'sys_public_project_view_join',
        text: 'label.permissions.sysPublicProjectViewJoin',
        type: 'system',
    },
    SysInternalUserInvite: {
        value: 'sys_internal_user_invite',
        text: 'label.permissions.sysInternalUserInvite',
        type: 'system',
    },
    SysExternalUserInvite: {
        value: 'sys_external_user_invite',
        text: 'label.permissions.sysExternalUserInvite',
        type: 'system',
    },
    SysUserListView: {
        value: 'sys_user_list_view',
        text: 'label.permissions.sysUserListView',
        type: 'system',
    },
    PrjProjectEdit: {
        value: 'prj_project_edit',
        text: 'label.permissions.prjProjectEdit',
        type: 'project',
    },
    PrjProjectSettingEdit: {
        value: 'prj_project_setting_edit',
        text: 'label.permissions.prjProjectSettingEdit',
        type: 'project',
    },
    PrjMemberAdd: {
        value: 'prj_member_add',
        text: 'label.permissions.prjMemberAdd',
        type: 'project',
    },
    PrjMemberEdit: {
        value: 'prj_member_edit',
        text: 'label.permissions.prjMemberEdit',
        type: 'project',
    },
    PrjMemberDelete: {
        value: 'prj_member_delete',
        text: 'label.permissions.prjMemberDelete',
        type: 'project',
    },
    PrjEventCreate: {
        value: 'prj_event_create',
        text: 'label.permissions.prjEventCreate',
        type: 'project',
    },
    PrjEventEdit: {
        value: 'prj_event_edit',
        text: 'label.permissions.prjEventEdit',
        type: 'project',
    },
    PrjEventDelete: {
        value: 'prj_event_delete',
        text: 'label.permissions.prjEventDelete',
        type: 'project',
    },
    PrjEventMeetingManage: {
        value: 'prj_event_meeting_manage',
        text: 'label.permissions.prjEventMeetingManage',
        type: 'project',
    },
    PrjTaskCreate: {
        value: 'prj_task_create',
        text: 'label.permissions.prjTaskCreate',
        type: 'project',
    },
    PrjTaskEdit: {
        value: 'prj_task_edit',
        text: 'label.permissions.prjTaskEdit',
        type: 'project',
    },
    PrjTaskDelete: {
        value: 'prj_task_delete',
        text: 'label.permissions.prjTaskDelete',
        type: 'project',
    },
    PrjFileCreate: {
        value: 'prj_file_create',
        text: 'label.permissions.prjFileCreate',
        type: 'project',
    },
    PrjFileEdit: {
        value: 'prj_file_edit',
        text: 'label.permissions.prjFileEdit',
        type: 'project',
    },
    PrjFileDownload: {
        value: 'prj_file_download',
        text: 'label.permissions.prjFileDownload',
        type: 'project',
    },
    PrjFileDelete: {
        value: 'prj_file_delete',
        text: 'label.permissions.prjFileDelete',
        type: 'project',
    },
    PrjFolderCreate: {
        value: 'prj_folder_create',
        text: 'label.permissions.prjFolderCreate',
        type: 'project',
    },
    PrjFolderEdit: {
        value: 'prj_folder_edit',
        text: 'label.permissions.prjFolderEdit',
        type: 'project',
    },
    PrjFolderDelete: {
        value: 'prj_folder_delete',
        text: 'label.permissions.prjFolderDelete',
        type: 'project',
    },
    PrjMemberRoleEdit: {
        value: 'prj_member_role_edit',
        text: 'label.permissions.prjMemberRoleEdit',
        type: 'project',
    },
};
/**
 * Contract Plan
 */
export const ContractPlan = {
    Standard: `standard`,
    Professional: `professional`,
    Free: `free`,
};
/**
 * Plan Interval
 */
export const PlanInterval = {
    PlanIntervalMonth: 'month',
    PlanIntervalYear: 'year',
};
export const BillingCycle = {
    Year: `year`,
    Month: `month`,
};
export const StorageCapacity = {
    GB100: 100,
    GB200: 200,
    GB300: 300,
    GB500: 500,
    TB1: 1000,
    TB2: 2000,
    TB3: 3000,
    TB5: 5000,
};
/**
 * Stripe 請求方法
 *
 * StripeのSubscriptionCollectionMethodへ参照する値のため、キャメルケースに従う必要はない
 */
export const CollectionMethod = {
    ChargeAutomatically: `charge_automatically`,
    SendInvoice: `send_invoice`,
};
/**
 * 支払方法
 */
export const PaymentMethod = {
    NotSet: 'notSet',
    BankTransfer: 'bankTransfer',
    CreditCard: 'creditCard',
};
export const PermissionType = {
    ProjectPermission: 'project',
    SystemPermission: 'system',
};
export const Currency = {
    CurrencyJPY: 'jpy', // Japanese Yen,
    CurrencyUSD: 'usd', // United States Dollar
};
// Here are the most common image file types, which are supported in all browsers (Chrome, Edge, Firefox, Safari, Opera)
// https://www.w3schools.com/html/html_images.asp
export const ImageExtensionsImageHtmlSupported = {
    Apng: 'apng',
    Gif: 'gif',
    Ico: 'ico',
    Jpg: 'jpg',
    Jpeg: 'jpeg',
    Jfif: 'jfif',
    Pjpeg: 'pjpeg',
    Pjp: 'pjp',
    Png: 'png',
    Svg: 'svg',
};
export const TaskHistoryType = {
    Subject: {
        key: 'subject',
        displayText: 'subject',
    },
    StartDate: {
        key: 'startDate',
        displayText: 'startDate',
    },
    DueDate: {
        key: 'dueDate',
        displayText: 'dueDate',
    },
    AssignToUser: {
        key: 'assignToUser',
        displayText: 'assignedUser',
    },
    TaskPriority: {
        key: 'taskPriority',
        displayText: 'priority',
    },
    TaskState: {
        key: 'taskState',
        displayText: 'taskState',
    },
    TaskKind: {
        key: 'taskKind',
        displayText: 'taskKind',
    },
    TaskCategory: {
        key: 'taskCategory',
        displayText: 'taskCategory',
    },
    EstimatedWorkTime: {
        key: 'estimatedWorkTime',
        displayText: 'scheduledTime',
    },
    ActualWorkTime: {
        key: 'actualWorkTime',
        displayText: 'actualTime',
    },
    RemainingWorkTime: {
        key: 'remainingWorkTime',
        displayText: 'remainingWorkTimes',
    },
    ActualProgress: {
        key: 'actualProgress',
        displayText: 'progressRate',
    },
    WbsNumber: {
        key: 'wbsNumber',
        displayText: 'wbsNumber',
    },
    IsProgressManagementDisabled: {
        key: 'isProgressManagementDisabled',
        displayText: 'excludedFromProgressManagement',
    },
    ParentTask: {
        key: 'parentTask',
        displayText: 'parentTask',
    },
};
export const ImageFormat = {
    Png: 'png',
    Svg: 'svg',
};
// メンションタイプ
export const MentionType = {
    All: {
        id: 'all',
        label: 'message.chat.mentionAll',
    },
    Here: {
        id: 'here',
        label: 'message.chat.mentionHere',
    },
};
export const InvitationRoleType = {
    Admin: {
        key: 'admin',
        label: 'label.invitationRoleType.admin',
    },
    Internal: {
        key: 'internal',
        label: 'label.invitationRoleType.internal',
    },
    External: {
        key: 'external',
        label: 'label.invitationRoleType.external',
    },
};
// valueはIANAが定義しているタイムゾーンを指定すること
// https://www.iana.org/time-zones
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// IANAのタイムゾーンへ参照する値のため、キャメルケースに従う必要はない
export const Region = {
    Japan: {
        text: 'label.japanRegion',
        value: 'Asia/Tokyo',
    },
    Vietnam: {
        text: 'label.vietnamRegion',
        // Vietnam time zone is `Asia/Ho_Chi_Minh`
        // refer: https://www.zeitverschiebung.net/en/country/vn
        value: 'Asia/Ho_Chi_Minh',
    },
};
export const FilterType = {
    Custom: 'custom',
    BuiltIn: 'built-in',
};
export const CheckExistenceFilterNameResultType = {
    // 対象フィルタ名が存在していない
    DoesNotExist: 'doesNotExist',
    // 別ユーザーが作成したフィルタ名が存在している
    CreatedByAnotherUser: 'createdByAnotherUser',
    // 自分が作成したフィルタ名が存在している
    CreatedByMyself: 'createdByMyself',
};
// filter scope
export const FilterScope = {
    Public: {
        key: 'public',
        name: 'label.public',
    },
    Private: {
        key: 'private',
        name: 'label.private',
    },
};
// StripeのSubscriptionのstatusを扱うenum
// Backend側で扱っている「SubscriptionStatus」と同じものを定義している
// StripeのSubscriptionStatusへ参照する値のため、キャメルケースに従う必要はない
export const StripeSubscriptionStatus = {
    Active: 'active',
    Canceled: 'canceled',
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    PastDue: 'past_due',
    Paused: 'paused',
    Trialing: 'trialing',
    Unpaid: 'unpaid',
};
export const FileExtensions = {
    Pdf: 'pdf',
    Csv: 'csv',
    Doc: 'doc',
    Docx: 'docx',
    Xls: 'xls',
    Xlsx: 'xlsx',
    Ppt: 'ppt',
    Pptx: 'pptx',
    Mp4: 'mp4',
    Mov: 'mov',
    Mpg: 'mpg',
    Mpeg: 'mpeg',
    Wmv: 'wmv',
    Avi: 'avi',
    Flv: 'flv',
    Png: 'png',
    Jpg: 'jpg',
    Jpeg: 'jpeg',
    Gif: 'gif',
    Bmp: 'bmp',
    Tiff: 'tiff',
    Tif: 'tif',
    Txt: 'txt',
    Htm: 'htm',
    Html: 'html',
    Odt: 'odt',
    Webm: 'webm',
    Ogg: 'ogg',
    Svg: 'svg',
    Mp3: 'mp3',
    Other: 'other',
};
export const TaskDependencyTypes = {
    FinishToStart: {
        key: 'fs',
        name: 'label.finishToStart',
        description: 'label.finishToStartDescription',
    },
    FinishToFinish: {
        key: 'ff',
        name: 'label.finishToFinish',
        description: 'label.finishToFinishDescription',
    },
    StartToStart: {
        key: 'ss',
        name: 'label.startToStart',
        description: 'label.startToStartDescription',
    },
    StartToFinish: {
        key: 'sf',
        name: 'label.startToFinish',
        description: 'label.startToFinishDescription',
    },
};
// アテンションをタブ切り替えするときのタブの種類
export const AttentionDisplayGroup = Object.assign({ All: 'all' }, AttentionType);
// 未読の種類
export const UnreadType = {
    // メンションではない通常投稿の未読
    Default: 'default',
    // グループ宛メンションの未読
    ToGroup: 'toGroup',
    // 自分宛メンションの未読
    ToMe: 'toMe',
};
// There are three supported video formats in HTML: MP4, WebM, and OGG.
// https://www.w3schools.com/tags/tag_video.asp
export const VideoExtensionsVideoHtmlSupported = {
    Mp4: 'mp4',
    WebM: 'webm',
    Ogg: 'ogg',
    // 上記のドキュメントによると、`mov`形式に対応していないが、Chrome・Edge・Firefoxブラウザーで動作確認を行ったところ、`mov`のファイルは再生できる。
    Mov: 'mov',
};
// TODO: 以下でWeb側とMobile側を共通化する
// CREW-15745の対応時、packages配下から参照が必要だったためコピーしてきた。CREW-11967でこちらに一元化すること
// https://break-tmc.atlassian.net/browse/CREW-11967
/** [個人設定] チャット/表示形式 */
export const UserChatSettingDisplayFormat = {
    Timeline: {
        value: 'timeline',
        text: 'label.timelineDisplay',
    },
    Thread: {
        value: 'thread',
        text: 'label.threadDisplay',
    },
};
// event scope
export const EventScope = {
    Public: {
        key: 'public',
        name: 'label.public',
    },
    Private: {
        key: 'private',
        name: 'label.private',
    },
};
export const PersonalProjectSetingType = {
    // 個人別プロジェクト設定/通知
    PersonalProjectNotification: 'PersonalProjectNotification',
};
export const ChatReadStatus = {
    All: {
        text: 'label.all',
        value: 'all',
    },
    Read: {
        text: 'label.read',
        value: 'read',
    },
    Unread: {
        text: 'label.unread',
        value: 'unread',
    },
};
export const TaskKanbanBucketType = {
    TaskState: {
        value: 'taskState',
        text: 'label.status',
    },
    AssignToUser: {
        value: 'assignToUser',
        text: 'label.assignedUser',
    },
    TaskPriority: {
        value: 'taskPriority',
        text: 'label.priority',
    },
    TaskKind: {
        value: 'taskKind',
        text: 'label.classification',
    },
    TaskCategory: {
        value: 'taskCategory',
        text: 'label.category',
    },
};
export const DataType = {
    String: 'string',
    Number: 'number',
};
export const SettingNotificationProject = {
    // すべてのメッセージ
    All: {
        text: 'action.allMessage',
        value: 'allMessage',
        displayOrder: 3,
    },
    // メンション
    Mention: {
        text: 'action.mention',
        value: 'mention',
        displayOrder: 1,
    },
    // メンションと返信
    MentionAndReply: {
        text: 'action.mentionAndReply',
        value: 'mentionAndReply',
        displayOrder: 2,
    },
    // オフ(通知なし)
    None: {
        text: 'action.off',
        value: 'none',
        displayOrder: 4,
    },
};
export const EventTimePeriod = {
    GroupMonthly: {
        value: 'groupMonthly',
        text: 'label.groupMonthly',
    },
    Monthly: {
        value: 'monthly',
        text: 'label.monthly',
    },
    Weekly: {
        value: 'weekly',
        text: 'label.weekly',
    },
};
export const RecurrenceOption = {
    None: {
        value: 'none',
        text: 'label.recurrence.none',
    },
    Daily: {
        value: 'daily',
        text: 'label.recurrence.daily',
    },
    Weekdays: {
        value: 'weekdays',
        text: 'label.recurrence.weekdays',
    },
    Weekly: {
        value: 'weekly',
        text: 'label.recurrence.weekly',
    },
    MonthlyDate: {
        value: 'monthlyDate',
        text: 'label.recurrence.monthlyDate',
    },
    MonthlyDayOfWeek: {
        value: 'monthlyDayOfWeek',
        text: 'label.recurrence.monthlyDayOfWeek',
    },
    Yearly: {
        value: 'yearly',
        text: 'label.recurrence.yearly',
    },
};
// event scope
export const EventType = {
    Personal: {
        key: 'personal',
        name: 'label.personal',
    },
    Project: {
        key: 'project',
        name: 'label.project',
    },
};
