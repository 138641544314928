import {
  apiBaseUrl,
  APP_VERSION,
  UPLOAD_MAX_FILE_SIZE,
} from '@crew/configs/constants'
import axios, { AxiosInstance, HttpStatusCode } from 'axios'

// Create an Axios instance with custom configuration
export const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true, // Ensure credentials are sent with every request
  headers: {
    'Content-Type': 'multipart/form-data',
    'app-version': APP_VERSION,
  },
  // config maximum content length for upload/download
  maxContentLength: UPLOAD_MAX_FILE_SIZE,
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    // Check if the error is unauthorized
    if (error.status === HttpStatusCode.Unauthorized) {
      // call refresh token API
      const refreshTokenUrl = apiBaseUrl() + 'api/v1/refresh'
      try {
        const result = await fetch(refreshTokenUrl, {
          method: 'GET',
          credentials: 'include',
        })
        if (result.ok) {
          // Refresh token success
          return axiosInstance(originalRequest)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }
)
