import { useEndMeetingMutation } from '@crew/apis/meeting/meetingApis'
import { EndMeetingActionType } from '@crew/enums/domain'
import { setMediaPipeline } from 'features/webMeeting/states/webMeetingSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useEventDetailPanel = () => {
  const dispatch = useAppDispatch()
  // Get function for end meeting process
  const [endMeetingMutation, { isLoading: isLoadingEndMeeting }] =
    useEndMeetingMutation()

  // End meeting
  const endMeeting = useCallback(
    async (meetingId: string, actionType: EndMeetingActionType) => {
      // clear media pipeline
      dispatch(setMediaPipeline(null))

      // 会議終了API
      await endMeetingMutation({ meetingId, actionType })
    },
    [dispatch, endMeetingMutation]
  )

  return {
    endMeeting,
    isLoadingEndMeeting,
  }
}
