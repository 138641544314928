import { FC, memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

type Event = {
  id: string
  subject: string
  startDatetime: string
  endDatetime: string
  isAllDay: boolean
}

export type WeekAppointmentProps = {
  data: { appointmentData: Event }
}

export const WeekAppointment: FC<WeekAppointmentProps> = memo((props) => {
  const { t } = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  return (
    <div className="text-crew-gray-900 text-sm">
      {
        <div className="flex flex-col items-start justify-center gap-0.5">
          <p className="truncate w-full">
            {props.data.appointmentData.subject}
          </p>

          {/* Dont show startTime-endTime when event isAllDay */}
          {!props.data.appointmentData.isAllDay && (
            <div className="flex items-center gap-0.5 flex-wrap">
              {/* start time */}
              <span>
                {t('format.shorttime', {
                  value: props.data.appointmentData.startDatetime,
                  timeZone: defaultUserProfileRegion,
                })}
              </span>
              <span>-</span>
              {/* end time */}
              <span>
                {t('format.shorttime', {
                  value: props.data.appointmentData.endDatetime,
                  timeZone: defaultUserProfileRegion,
                })}
              </span>
            </div>
          )}
        </div>
      }
    </div>
  )
})
