import { memo, useMemo } from 'react'
import { useAppSelector } from 'states/hooks'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { useTranslation } from '@crew/modules/i18n'
import StartupGuidePng from 'assets/images/png/startupguide.png'
import PlayCircleOutline from '~icons/material-symbols/play-circle-outline'
import { MediaCard } from './components/mediaCard/mediaCard'
import MenuBookOutline from '~icons/material-symbols/menu-book-outline'
import { AboutCard } from './components/aboutCard/aboutCard'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import IconInfo from '~icons/material-symbols/info-outline'

const SUPPORT_SITE_FAQ_URL =
  'https://support.crewworks.net/support/solutions/articles/150000197087'

// 動画で知るCrewWorks Step1(ユーザー招待)
const Step1VideoUrl = {
  url: 'https://www.youtube.com/embed/HsF0it2HFwk?si=DcVTZly6F7mAanQO',
}

// 動画で知るCrewWorks Step2
const Step2VideoUrls = [
  // プロジェクト作成
  {
    description: 'label.startupGuide.videoDescription.createProject',
    url: 'https://www.youtube.com/embed/KC6hhFzZvPo?si=lzm_GbcZchrEAO9o',
  },
  // チャット
  {
    description: 'label.startupGuide.videoDescription.chat',
    url: 'https://www.youtube.com/embed/gGP5aPCZXNE?si=i_zMYugZYMJyR_En',
  },
  // Web会議
  {
    description: 'label.startupGuide.videoDescription.webMeeting',
    url: 'https://www.youtube.com/embed/2wnTTt43I7A?si=HuOuyhgXQ3RtJCz6',
  },
  // タスク管理
  {
    description: 'label.startupGuide.videoDescription.taskManagement',
    url: 'https://www.youtube.com/embed/zgYD1ZIjkts?si=jcoriBVsxXE2iW_P',
  },
  // ファイル共有
  {
    description: 'label.startupGuide.videoDescription.fileSharing',
    url: 'https://www.youtube.com/embed/NwIXjduvafI?si=6xL5D-_6FnmQbTx5',
  },
  // 横断検索
  {
    description: 'label.startupGuide.videoDescription.crossSearch',
    url: 'https://www.youtube.com/embed/zETLhNuu4Pg?si=b0ANt_jMVJcMaeXg',
  },
]

const LearnMoreUrls = [
  // プロジェクト
  {
    title: 'label.startupGuide.project.heading',
    urls: [
      {
        title: 'label.startupGuide.project.createProject',
        url: 'https://support.crewworks.net/support/solutions/articles/150000136476',
      },
      {
        title: 'label.startupGuide.project.inviteUser',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136484',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/folders/150000533430',
      },
    ],
  },
  // ビジネスチャット
  {
    title: 'label.startupGuide.businessChat.heading',
    urls: [
      {
        title: 'label.startupGuide.businessChat.sendMessage',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136477',
      },
      {
        title: 'label.startupGuide.businessChat.addBookmark',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136492',
      },
      {
        title: 'label.startupGuide.businessChat.uploadDownloadFile',
        url: 'https://support.crewworks.net/support/solutions/articles/150000136480',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/folders/150000533426',
      },
    ],
  },
  // Web会議
  {
    title: 'label.startupGuide.webMeeting.heading',
    urls: [
      {
        title: 'label.startupGuide.webMeeting.startWebMeeting',
        url: 'https://support.crewworks.net/support/solutions/articles/150000136478',
      },
      {
        title: 'label.startupGuide.webMeeting.checkAttendanceResponse',
        url: 'https://support.crewworks.net/a/solutions/articles/150000162951',
      },
      {
        title: 'label.startupGuide.webMeeting.addTaskAfterMeeting',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136514',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/folders/150000533428',
      },
    ],
  },
  // タスク管理
  {
    title: 'label.startupGuide.taskManagement.heading',
    urls: [
      {
        title: 'label.startupGuide.taskManagement.basicOperation',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136479',
      },
      {
        title: 'label.startupGuide.taskManagement.searchTask',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136531',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/folders/150000533427',
      },
    ],
  },
  // ファイル共有
  {
    title: 'label.startupGuide.fileSharing.heading',
    urls: [
      {
        title: 'label.startupGuide.fileSharing.uploadFile',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136506',
      },
      {
        title: 'label.startupGuide.fileSharing.downloadFile',
        url: 'https://support.crewworks.net/a/solutions/articles/150000166218',
      },
      {
        title: 'label.startupGuide.fileSharing.createFolder',
        url: 'https://support.crewworks.net/a/solutions/articles/150000193106',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/folders/150000533429',
      },
    ],
  },
  // スマホアプリ
  {
    title: 'label.startupGuide.smartphoneApplication.heading',
    urls: [
      {
        title: 'label.startupGuide.smartphoneApplication.downloadAndLogin',
        url: 'https://support.crewworks.net/a/solutions/articles/150000176476',
      },
      {
        title: 'label.startupGuide.smartphoneApplication.screenLayoutStructure',
        url: 'https://support.crewworks.net/a/solutions/articles/150000167408',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/folders/150000536736',
      },
    ],
  },
  // 個人設定・サポート
  {
    title: 'label.startupGuide.personalSettingAndSupport.heading',
    urls: [
      {
        title:
          'label.startupGuide.personalSettingAndSupport.receiveNotification',
        url: 'https://support.crewworks.net/a/solutions/articles/150000136515',
      },
      {
        title:
          'label.startupGuide.personalSettingAndSupport.customizeSideMenuDisplay',
        url: 'https://support.crewworks.net/a/solutions/articles/150000189543',
      },
      {
        title:
          'label.startupGuide.personalSettingAndSupport.contactOnSupportSite',
        url: 'https://support.crewworks.net/support/solutions/articles/150000197087',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/folders/150000533424',
      },
    ],
  },
  // 管理者設定
  {
    title: 'label.startupGuide.adminSetting.heading',
    urls: [
      {
        title: 'label.startupGuide.adminSetting.changeUserCount',
        url: 'https://support.crewworks.net/a/solutions/articles/150000176850',
      },
      {
        title: 'label.startupGuide.adminSetting.checkUsage',
        url: 'https://support.crewworks.net/a/solutions/articles/150000197083',
      },
      {
        title: 'label.startupGuide.adminSetting.setUserPermission',
        url: 'https://support.crewworks.net/a/solutions/articles/150000197086',
      },
      {
        title: 'label.startupGuide.learnMore',
        url: 'https://support.crewworks.net/support/solutions/150000213589',
      },
    ],
  },
]

export const StartupGuidePage = memo(() => {
  const { t } = useTranslation()
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  return (
    <div className="p-2.5 @container">
      <div className="flex flex-wrap justify-center gap-8 p-2.5">
        <div className="flex flex-col gap-2.5">
          {/* CrewWorksへようこそ */}
          <div className="flex flex-row items-end gap-2">
            <img src={LogoImg} alt="logo" className="h-11" />
            <span className="text-2xl">{t('label.startupGuide.welcome')}</span>
          </div>

          {/* スタートアップガイド */}
          <p className="font-bold text-5xl">
            {t('label.startupGuide.gettingStartedGuide')}
          </p>

          {/* CrewWorksの使い方をご案内します。 */}
          <p className="text-2xl">{t('label.startupGuide.guideDescription')}</p>

          {/* 背景画像(右下に寄せる) */}
          <div className="flex justify-end">
            <img
              src={StartupGuidePng}
              alt={t('label.startupGuide.gettingStartedGuide')}
              className="w-80"
            />
          </div>
        </div>

        {/* 動画で知るCrewWorks Step1 */}
        <div className="flex flex-col gap-2 items-center">
          <div className="flex gap-2.5 items-center">
            <PlayCircleOutline
              width={64}
              height={64}
              className="text-crew-cyan-600"
            />

            <p className="text-4xl font-bold">
              {t('label.startupGuide.videoGuide')}
              <span className="text-crew-cyan-600">
                {t('label.startupGuide.step1')}
              </span>
            </p>
          </div>

          <div className="px-6">
            <p className="text-xl">
              <span className="text-crew-red-500">
                {t('label.startupGuide.forAdmin')}
              </span>
              <span>{t('label.invitingUsers')}</span>
            </p>

            {/* Step2の動画表示よりも若干大きく表示する */}
            <div className="w-[24rem]">
              <MediaCard url={Step1VideoUrl.url} />
            </div>
          </div>
        </div>
      </div>

      {/* 動画で知るCrewWorks Step2 */}
      <div className="crew-bg-gray-1 p-2.5 mt-5">
        <div className="flex flex-row items-center gap-2.5 justify-center py-5">
          <PlayCircleOutline
            width={64}
            height={64}
            className="text-crew-cyan-600"
          />

          <p className="text-4xl font-bold">
            {t('label.startupGuide.videoGuide')}
            <span className="text-crew-cyan-600">
              {t('label.startupGuide.step2')}
            </span>
          </p>
        </div>

        <div className="flex flex-wrap justify-center gap-8">
          {Step2VideoUrls.map((video, index) => (
            // サイズを設定しておかないとflex-wrapがうまく効かない
            // w-[21rem]にしているのは、それより小さいとサムネイル表示がぼやけて表示されてしまうため。
            // おそらくYouTubeで以下の複数種類のサムネイルのサイズがあることが関係しており、
            // フレームサイズが小さいときに低解像度のサムネイルが使用されてしまうためと思われる。
            // https://portal-cms.net/sct/176
            <div className="flex flex-col gap-2 w-[21rem]" key={index}>
              <MediaCard url={video.url} />
              <p className="text-lg">{t(video.description)}</p>
            </div>
          ))}
        </div>
      </div>

      {/* もっと詳しく使い方を知る */}
      <div className="p-2.5">
        <div className="flex flex-row items-center gap-2.5 justify-center py-5">
          <MenuBookOutline
            width={64}
            height={64}
            className="text-crew-cyan-600"
          />

          <p className="text-4xl font-bold">
            {t('label.startupGuide.moreDetail')}
          </p>
        </div>

        <div className="flex flex-wrap justify-center gap-8">
          {LearnMoreUrls.map((item, index) => (
            // サイズを設定しておかないとflex-wrapがうまく効かない
            <div className="w-80" key={index}>
              <AboutCard title={item.title} urls={item.urls} />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2 py-10 justify-center text-center text-lg">
        <div className="flex justify-center gap-2.5">
          <IconInfo width={24} height={24} />
          <span>
            {t('label.startupGuide.hideGettingStartedGuide.prefix')}
            {/* 個人設定-表示タブに遷移 */}
            <CrewLink
              to={'/personal-settings/display'}
              title={t('label.startupGuide.hideGettingStartedGuide.linkText')}
            >
              {t('label.startupGuide.hideGettingStartedGuide.linkText')}
            </CrewLink>
            {t('label.startupGuide.hideGettingStartedGuide.suffix')}
          </span>
        </div>
        <div className="flex justify-center gap-2.5">
          <IconInfo width={24} height={24} />
          <span>
            {t('label.startupGuide.openSupportSite.prefix')}
            {/* サポートサイトを別タブで開く */}
            <CrewLink
              title={t('label.startupGuide.openSupportSite')}
              to={SUPPORT_SITE_FAQ_URL}
              target="_blank"
            >
              {t('label.startupGuide.openSupportSite.linkText')}
            </CrewLink>
            {t('label.startupGuide.openSupportSite.suffix')}
          </span>
        </div>
      </div>
    </div>
  )
})
