// フロントエンドのバージョン
export const APP_VERSION = '1.0.40';
// モバイルアプリのストア審査を受けるときに使うテナントのエイリアス(UI上ではテナントIDと表記)
//
// アプリ審査時はバックエンドは未リリースであり、旧バージョンで動作している。
// APIに破壊的変更が入った場合、新バージョンのアプリと旧バージョンのバックエンドでAPIの互換性がなくなるため、
// そのままでは正常動作せず、審査が通らない。
// 対策として、モバイルアプリ審査用の特定テナントへの接続のみ、prodではない、eas.jsonで指定した審査用バックエンドへ接続させる。
const reviewTenantIds = ['test'];
// APIベースURLを設定から取得する
let apiBaseUrlFromEnv;
let reviewApiBaseUrlFromEnv;
try {
    // expo上ではexpo-constantsで設定値を読み取る必要があるが、web上では利用できない。
    // そのため、requireをtry-catchで囲い、例外を使って処理を分岐する
    const Constants = require('expo-constants').default;
    apiBaseUrlFromEnv = Constants.expoConfig.extra.apiBaseUrl;
    reviewApiBaseUrlFromEnv = Constants.expoConfig.extra.reviewApiBaseUrl;
}
catch (_a) {
    // expo-constantsのrequireに失敗した=expo環境ではない≒web環境である
    apiBaseUrlFromEnv = process.env.REACT_APP_API_BASE_URL;
    reviewApiBaseUrlFromEnv = process.env.REACT_APP_REVIEW_API_BASE_URL;
}
if (!apiBaseUrlFromEnv) {
    // apiBaseUrlFromEnvがない=設定ミスなのでエラーをthrowする
    throw new Error('⚠⚠⚠ apiBaseUrl is not defined ⚠⚠⚠');
}
// APIベースURL(configure前)
const apiBaseUrlPreConfigure = apiBaseUrlFromEnv;
// モバイルアプリの審査を受けるときに使うAPIベースURL(configure前)。設定されていない場合は通常のAPIを使用する。
const reviewApiBaseUrlPreConfigure = reviewApiBaseUrlFromEnv !== null && reviewApiBaseUrlFromEnv !== void 0 ? reviewApiBaseUrlFromEnv : apiBaseUrlFromEnv;
// 設定済のAPIベースURL
let apiBaseUrlConfigured = undefined;
// 設定済のテナント間で共通なAPIベースURL
let commonApiBaseUrlConfigured = undefined;
// サブドメインに使用するテナントIDをinitApiBaseUrlに組み込んだサブドメイン付きのURLを生成する
// NOTE: テナントIDはWebはページ表示のタイミングでURLから取得、モバイルはログイン時に取得する
export const configureApiBaseUrl = (tenantId) => {
    // テナントIDがアプリレビュー用の場合、レビュー用APIを使用する。
    // そうでない場合、通常のAPIを使用する。
    const usingApiBasUrl = reviewTenantIds.includes(tenantId)
        ? reviewApiBaseUrlPreConfigure
        : apiBaseUrlPreConfigure;
    const url = new URL(usingApiBasUrl);
    // テナントIDをサブドメインとして組み込む
    if (tenantId) {
        apiBaseUrlConfigured = `${url.protocol}//${tenantId}.${url.host}/`;
    }
    // 共通APIのベースURL
    commonApiBaseUrlConfigured = `${url.protocol}//app.${url.host}/`;
};
// APIのベースURLを取得する
// FIXME: constant.ts は apiBaseUrlWithTenantIdに値が入る前にindex.htmlにロードされてしまうため、定数にできない。
export const apiBaseUrl = () => {
    // configure済ならそちらを優先する
    if (apiBaseUrlConfigured) {
        return apiBaseUrlConfigured;
    }
    return apiBaseUrlPreConfigure;
};
// 共通APIのベースURLを取得する
export const commonAppApiBaseUrl = () => {
    // configure済ならそちらを優先する
    if (commonApiBaseUrlConfigured) {
        return commonApiBaseUrlConfigured;
    }
    return apiBaseUrlPreConfigure;
};
export const ALL_EVENTS = 'allEvents';
export const ALL_EVENT_KINDS = 'allKinds';
export const ALL_PROJECT_TASK_TYPES = 'allTypes';
// インクリメンタルサーチと即時検索のディレイ
export const SEARCH_TIMEOUT_MSEC = 500;
export const LOAD_DATA_GRID_TIMEOUT = 50;
// 1week = 5days, 1day = 8hours, 1hour = 60minutes
// 1week = 5*8*60 minutes
// 1day = 8*60 minutes
// 1hours = 60 minutes
export const MINUTES_PER_WEEK = 2400;
export const MINUTES_PER_DAY = 480;
export const MINUTES_PER_HOUR = 60;
/**
 * WebSocket再接続時の基本待機時間。最初の再接続時はこの時間を使い、以降リトライ回数毎にback-offさせる
 */
export const WEBSOCKET_1ST_RECONNECT_DELAY_MSEC = 2000; // msec
/**
 * 再接続時にランダムに加算する待機時間(jitter)の最大値
 */
export const WEBSOCKET_RECONNECT_JITTER_MSEC = 500; // ±msec
/**
 * 再接続の最大回数
 */
export const WEBSOCKET_RECONNECT_MAX_TRY_COUNT = 5; // 回
/**
 * 再接続回数をリセットするまでの時間。接続完了後、この時間が過ぎると再接続回数が0にリセットされる
 */
export const WEBSOCKET_RECONNECT_COUNTER_RESET_TIME_MSEC = 60000; // msec
/**
 * WebSocket接続完了を待つときのポーリング間隔
 */
export const WEBSOCKET_CONNECTED_POLLING_DELAY_MSEC = 100; // msec
/**
 * subscribe handlerが全て無くなってから実際にunsubscribeするまでの遅延秒数
 */
export const WEBSOCKET_UNSUBSCRIBE_DELAY_MSEC = 1000; // msec
/**
 * フィード・メッセージ表示時の最小縦幅。messageFetchLimitの計算でのみ使用する
 */
export const MESSAGE_AREA_MIN_HEIGHT = 40; //px
/**
 * 複雑なパスワードを強制するのデフォルト値
 * NOTICE: 値を変更する場合は、バックエンドの定義も変更すること
 *         pkg\util\password_util\password_util.go
 */
export const DEFAULT_PASSWORD_COMPLEXITY = false;
/**
 * パスワードの最小桁数のデフォルト値（「複雑なパスワードを強制する」OFFの場合）
 * NOTICE: 値を変更する場合は、バックエンドの定義も変更すること
 *         pkg\util\password_util\password_util.go
 */
export const DEFAULT_PASSWORD_MIN_LENGTH = 6;
/**
 * パスワードの最大桁数
 * NOTICE: 値を変更する場合は、バックエンドの定義も変更すること
 *         pkg\util\password_util\password_util.go
 */
export const PASSWORD_MAX_LENGTH = 32;
/**
 * パスワードの最小桁数のデフォルト値（「複雑なパスワードを強制する」ONの場合）
 * NOTICE: 値を変更する場合は、バックエンドの定義も変更すること
 *         pkg\util\password_util\password_util.go
 */
export const DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH = 8;
/**
 * パスワードで許可している記号
 * NOTICE: 値を変更する場合は、バックエンドの定義も変更すること
 *         pkg\util\password_util\password_util.go
 */
export const PASSWORD_SYMBOLS = '@$!%*?&';
/**
 * メンションをHTMLで表現するときに使う定数群
 */
export const mention = {
    TAG_NAME: 'SPAN',
    CLASS_NAME: 'mention',
    STYLE_MENTION_OTHER_CLASS_NAME: 'crew-mention-other',
    STYLE_MENTION_ME_CLASS_NAME: 'crew-mention-me',
    STYLE_MENTION_ALL_HERE_CLASS_NAME: 'crew-mention-all-here',
    MENTION_ID_DATA_ATTRIBUTE: 'data-mention-id',
    MENTION_ID_DATA_ATTRIBUTE_NAME: 'mentionId',
};
// 利用規約URL
export const TERM_OF_SERVICE_URL = 'https://crewworks.net/terms-of-service/';
// プライバシーポリシーURL
export const PRIVACY_POLICY_URL = 'https://crewworks.net/privacypolicy/';
// 価格URL
export const PRICE_URL = 'https://crewworks.net/price/';
// よくある質問URL
export const FAQ_URL = 'https://crewworks.net/faq/';
/**
 * カード払を有効化する時のタイムアウト
 */
export const UPDATE_PAYMENT_TO_CARD_TIMEOUT_MSEC = 1000 * 60 * 30; // 30分
// タスク一覧取得時、「親タスクIDがないもの」を表現するために使用
export const NONE = 'none';
/**
 * 未読更新処理を実行するまでのデバウンス時間
 */
export const UPDATE_LAST_READ_MESSAGE_DEBOUNCE_MSEC = 1000; //msec
// 契約プランがフリーの場合のテナントに参加できるユーザー数
export const MAX_NUMBER_OF_USERS_FOR_FREE_PLAN = 50;
// aws media capture pipeline
// https://aws.amazon.com/vi/blogs/business-productivity/capture-amazon-chime-sdk-meetings-using-media-capture-pipelines/
export const AWS_MEDIA_PIPELINE_PREFIX = 'aws:MediaPipeline';
// バックエンド(フロントエンド)の定義も合わせて修正が必要
export const UPLOAD_MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024; // 10GB
