import { memo, useState } from 'react'

import { PlanSelectItem } from './components/planSelectItem'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useMemo } from 'react'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { useAppSelector } from 'states/hooks'
import { Plan } from './components/planSelectItem'
import { PlanId } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { ComponentCallbackHandler } from '@crew/utils'
import { BillingCycle } from '@crew/enums/app'

/**
 * Plan select panel
 * @date 8/16/2023 - 5:28:07 PM
 *
 * @type {*}
 */
export const AppPlanSelectPage = memo(() => {
  const { t } = useTranslation()
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const { navigate } = useCrewNavigate()

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  // 文字サイズを変更するためにrenderを使う
  const renderRadioGroupItem = useCallback(
    (item: { id: string; name: string }) => {
      return <span className="text-2xl">{item.name}</span>
    },
    []
  )

  const billingCycleItems = useMemo(() => {
    return Object.values(BillingCycle).map((item) => {
      return {
        id: item,
        name: t(`label.planBillingCycle.${item}`),
      }
    })
  }, [t])

  const [selectBillingCycle, setSelectBillingCycle] = useState<BillingCycle>(
    BillingCycle.Year
  )

  const handleSelectBillingCycleChanged = useCallback<
    ComponentCallbackHandler<typeof CrewRadioGroup, 'onValueChanged'>
  >((event) => {
    setSelectBillingCycle(event.value)
  }, [])

  // plan data description
  const plans: Plan[] = useMemo(() => {
    return [
      {
        name: t('label.planType.standard'),
        description: [
          t('label.planDescription.standard.line1'),
          t('label.planDescription.standard.line2'),
        ],
        // 1ユーザー当たり
        price: {
          annual: '10,800',
          monthly: '1,050',
        },
        // 月額換算（年額契約時有効）
        priceMonthly: '900',
        feature: [
          t('label.planFeature.businessChat'),
          t('label.planFeature.taskManagement'),
          t('label.planFeature.fileSharing'),
          t('label.planFeature.webMeeting'),
          t('label.planFeature.numberOfStorage', { numberOfStorage: 100 }),
        ],
        id: PlanId.Standard,
      },
      {
        name: t('label.planType.starter'),
        description: [t('label.planDescription.starter.line1')],
        // 1ユーザー当たり
        price: {
          annual: '7,200',
          monthly: '700',
        },
        // 月額換算（年額契約時有効）
        priceMonthly: '600',
        feature: [
          t('label.planFeature.businessChat'),
          t('label.planFeature.taskManagement'),
          t('label.planFeature.fileSharing'),
          t('label.planFeature.numberOfStorage', { numberOfStorage: 100 }),
        ],
        id: PlanId.Starter,
      },
    ]
  }, [t])

  //handle set plan data and go email panel
  const handleTryForFreeButtonClick = useCallback(
    (plan: Plan) => {
      navigate('/signup', {
        plan: plan.id,
        billingCycle: selectBillingCycle,
      })
    },
    [navigate, selectBillingCycle]
  )

  return (
    <div className="flex flex-col items-center h-[100dvh] pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto mb-8" />
      {/* プラン選択 */}
      <p className="text-center text-2xl text-crew-blue-600 dark:text-crew-blue-400">
        {t('label.choosePlan')}
      </p>
      <p className="text-center text-2xl">{t('label.choosePlanHint')}</p>
      {/* 年間契約/月額契約 */}
      <div>
        <CrewRadioGroup
          layout="horizontal"
          dataSource={billingCycleItems}
          valueExpr="id"
          displayExpr="name"
          value={selectBillingCycle}
          itemRender={renderRadioGroupItem}
          onValueChanged={handleSelectBillingCycleChanged}
        />
      </div>
      {/* 各プラン詳細  */}
      <div className="flex flex-row justify-between gap-2.5 py-2.5 w-1/2">
        {/** List contract plan */}
        {plans.map((item) => {
          return (
            <PlanSelectItem
              key={item.id}
              plan={item}
              billingCycle={selectBillingCycle}
              onTryFreeNowButtonClick={handleTryForFreeButtonClick}
            />
          )
        })}
      </div>
    </div>
  )
})
