import { useCallback } from 'react'
import {
  PersonalProjectSetingType,
  SettingNotificationProject,
} from '@crew/enums/app'
import { ReplacePersonalProjectSettingsRequest } from '@crew/apis/setting/models/replacePersonalProjectSettings/request'
import { useReplacePersonalProjectSettingsMutation } from '@crew/apis/setting/settingApis'
import { useAppDispatch } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyPersonalProjectSettingEvent,
} from 'features/app/states/appSlice'
import { Project } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'

export const useCrewNotificationProject = (projectId: string) => {
  const dispatch = useAppDispatch()

  const [
    replacePersonalProjectSettings,
    { isLoading: isLoadingReplacePersonalProjectSettings },
  ] = useReplacePersonalProjectSettingsMutation()

  // プロジェクトの通知設定を更新する関数
  const updateNotificationSetting = useCallback(
    async (notificationType: SettingNotificationProject) => {
      const params: ReplacePersonalProjectSettingsRequest = {
        projectId: projectId,
        settings: [
          {
            key: PersonalProjectSetingType.PersonalProjectNotification,
            value: notificationType.value,
          },
        ],
      }

      const result = await replacePersonalProjectSettings(params).unwrap()

      const objectEventMessage: ObjectEventMessage<Project> = {
        eventType: NotifyEventType.Updated,
        id: projectId,
        object: undefined,
      }

      // 設定値が更新されたことを通知
      dispatch(notifyPersonalProjectSettingEvent(objectEventMessage))

      return result
    },
    [dispatch, projectId, replacePersonalProjectSettings]
  )

  return {
    updateNotificationSetting,
    isLoadingReplacePersonalProjectSettings,
  }
}
