import { FC, memo, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewContentShare } from 'components/chime/crewContentShare'
import { CrewAttendees } from 'components/chime/crewAttendees/crewAttendees'
import { CrewCameraToggleButton } from 'components/chime/crewCameraToggleButton'
import { CrewContentShareButton } from 'components/chime/crewContentShareButton'
import { CrewMicrophoneMuteToggleButton } from 'components/chime/crewMicrophoneMuteToggleButton'
import { WebMeetingSettingDialog } from 'features/webMeeting/components/webMeetingSettingDialog/webMeetingSettingDialog'
import { useEventDetailWebMeetingPanel } from './useEventDetailWebMeetingPanel'
import { EventDetailWebMeetingAttendeeList } from './components/eventDetailWebMeetingAttendeeList/eventDetailWebMeetingAttendeeList'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useRef, useCallback, useState } from 'react'
import { useFullscreen, useToggle } from '@dx-system/react-use'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import {
  MediaPipeline,
  setIsEndMeeting,
  setMediaPipeline,
} from 'features/webMeeting/states/webMeetingSlice'
import {
  useContentShareState,
  useLocalVideo,
  useMeetingManager,
  useRosterState,
} from 'modules/amazon-chime-sdk-component-library-devextreme'
import {
  TOPIC_MEETING_OFF_AUDIO_VIDEO,
  TOPIC_MEETING_PERMIT_USER,
  TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
} from 'configs/constants'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { AttendeeViewStyles } from 'enums/app'
import BaselineFullscreen from '~icons/ic/baseline-fullscreen'
import FiberManualRecord from '~icons/material-symbols/fiber-manual-record'
import GroupOutline from '~icons/material-symbols/group-outline'
import OutlineSettings from '~icons/ic/outline-settings'
import SensorDoor from '~icons/material-symbols/sensor-door'
import CloseCircle from '~icons/mdi/close-circle'
import { useToast } from 'hooks/useToast'
import { DataMessage } from 'amazon-chime-sdk-js'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import Check from '~icons/material-symbols/check'
import { useLazyCheckStorageCapacityExceededQuery } from '@crew/apis/tenant/tenantApis'
import { AWS_MEDIA_PIPELINE_PREFIX } from '@crew/configs/constants'
import { useCrewAttendees } from 'components/chime/crewAttendees/useCrewAttendees'
import { MeetingAttendeeJoinState } from '@crew/enums/domain'
import { EventDetailWebMeetingWaitingRoom } from './components/eventDetailWebMeetingAttendeeList/components/eventDetailWebMeetingWaitingRoom/eventDetailWebMeetingWaitingRoom'
import { CrewDropDownButton } from 'components/elements/crewDropDownButton/crewDropDownButton'
import ViewSidebarOutlineSharp from '~icons/material-symbols/view-sidebar-outline-sharp'
import { useValueChangeEffect } from '@crew/hooks'

type DropDownItem = {
  text: string
  value: string
  icon: JSX.Element
}

// 録画中かどうかを示す要約変数
const isNowRecording = (
  mediaPipeline: MediaPipeline | null
): mediaPipeline is MediaPipeline => mediaPipeline !== null

const LIFE_TIME_MS = 1000
const TopicRecordingStarted = 'recordingStarted'
const TopicRecordingStopped = 'recordingStopped'

export type RecorderInfo = {
  meetingId: string
  userId: string
  displayName: string
}

export type ItemDropDownAttendeeView = {
  key: string
  value: string
}

export const EventDetailWebMeetingPanel: FC = memo(() => {
  const { startRecord, endRecord, joinMeetingFromWaiting } =
    useEventDetailWebMeetingPanel()
  const audioVideo = useAudioVideo()
  const { toggleVideo, isVideoEnabled } = useLocalVideo()
  const toast = useToast()
  const [
    isWebMeetingSettingDialogOpen,
    openWebMeetingSettingDialog,
    closeWebMeetingSettingDialog,
  ] = useModal()

  // ストレージ使用量の超過チェックAPI
  const [lazyCheckStorageCapacityExceededQuery] =
    useLazyCheckStorageCapacityExceededQuery()

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const joinState = useAppSelector((state) => state.webMeeting.joinState)

  const { localAttendeeUser, attendeeUsers } = useCrewAttendees()

  const meetingManager = useMeetingManager()

  const { isLocalUserSharing } = useContentShareState()

  // 画面共有状態に応じて表示切替を行うため画面共有しているAttendeeIdを取得する
  const { sharingAttendeeId } = useContentShareState()

  const { t } = useTranslation()

  const targetRef = useRef(null)
  const [show, setShow] = useToggle(false)
  const [recorderId, setRecorderId] = useState('')

  const dispatch = useAppDispatch()

  const isHost = useAppSelector((state) => state.webMeeting.isHost)

  const mediaPipeline = useAppSelector(
    (state) => state.webMeeting.mediaPipeline
  )

  // 録画の状態（録画中ならmediaPipelineがnullではない）
  const isRecording = isNowRecording(mediaPipeline)

  const [showApiErrors] = useShowApiErrors()

  useEffect(() => {
    if (!audioVideo) {
      console.warn('AudioVideo object is not available')
      return
    }

    // =======================================================
    // ===== Subscribe processing when recording started =====
    // =======================================================
    const onRecordingStarted = (dataMessage: DataMessage) => {
      const recorder = dataMessage.json()
      setRecorderId(recorder.userId)
      if (meetingManager.meetingId === recorder.meetingId) {
        if (loggedInUser?.id !== recorder.userId) {
          // 主催者が録画を開始したら、自分を除く全参加者画面に下記トーストを表示する。
          toast.info(
            t('message.meetingRecording.meetingRecordingStarted', {
              userName: recorder.displayName,
            })
          )
        }
      }
    }

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      TopicRecordingStarted,
      onRecordingStarted
    )

    // =======================================================
    // ===== Subscribe processing when recording stopped =====
    // =======================================================
    const onRecordingStopped = (dataMessage: DataMessage) => {
      const recorder = dataMessage.json()
      setRecorderId('')
      if (meetingManager.meetingId === recorder.meetingId) {
        if (loggedInUser?.id !== recorder.userId) {
          // 主催者が録画を終了したら、自分を除く全参加者画面に下記トーストを表示する。
          toast.info(
            t('message.meetingRecording.meetingRecordingStopped', {
              userName: recorder.displayName,
            })
          )
        }
      }
    }
    audioVideo.realtimeSubscribeToReceiveDataMessage(
      TopicRecordingStopped,
      onRecordingStopped
    )

    // Subscribe processing when audio/video off
    const onAudioVideoOff = (dataMessage: DataMessage) => {
      const { userId, target } = dataMessage.json()
      if (userId === loggedInUser?.id) {
        if (target === 'audio') {
          audioVideo?.realtimeMuteLocalAudio()
          toast.info(t('message.meeting.hostMutedYou'))
        }

        if (target === 'video') {
          toggleVideo()
          toast.info(t('message.meeting.hostStoppedYourVideo'))
        }
      }
    }

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      TOPIC_MEETING_OFF_AUDIO_VIDEO,
      onAudioVideoOff
    )

    // Subscribe processing when host permit user to join meeting
    const onPermitUser = async (dataMessage: DataMessage) => {
      const { meetingId, userId } = dataMessage.json()

      // If the user is not the target user, do not process
      if (userId !== localAttendeeUser?.chimeAttendeeId) return

      try {
        // Join meeting
        await joinMeetingFromWaiting(meetingId, userId)

        // https://docs.aws.amazon.com/chime-sdk/latest/APIReference/API_meeting-chime_UpdateAttendeeCapabilities.html
        // When you change a video or content capability from None or Receive to Send or SendReceive,
        // and if the attendee turned on their video or content streams, remote attendees can receive those streams,
        // but only after media renegotiation between the client and the Amazon Chime back-end server.
        // To avoid this, we need to start the local video tile after joining the meeting if the video is enabled.
        if (isVideoEnabled) {
          audioVideo?.startLocalVideoTile()
        }

        // Send realtime message that the meeting attendees has changed
        audioVideo.realtimeSendDataMessage(
          TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
          undefined, // We don't need display toast message, so we don't need to set message
          LIFE_TIME_MS
        )
      } catch (error) {
        showApiErrors(error)
      }
    }

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      TOPIC_MEETING_PERMIT_USER,
      onPermitUser
    )

    return () => {
      if (audioVideo) {
        // Unsubscribe process related to recording meeting
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
          TopicRecordingStarted
        )
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
          TopicRecordingStopped
        )

        // Unsubscribe process related to audio/video off
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
          TOPIC_MEETING_OFF_AUDIO_VIDEO
        )

        // Unsubscribe process related to host permit user to join meeting
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
          TOPIC_MEETING_PERMIT_USER
        )
      }
    }
  }, [
    audioVideo,
    dispatch,
    isVideoEnabled,
    joinMeetingFromWaiting,
    localAttendeeUser?.chimeAttendeeId,
    loggedInUser?.id,
    meetingManager.meetingId,
    showApiErrors,
    t,
    toast,
    toggleVideo,
  ])

  // 全画面表示終了（ESCボタン押下時）
  const handleExitFullScreen = useCallback(() => {
    setShow(false)
  }, [setShow])

  const isFullScreen = useFullscreen(targetRef, show, {
    onClose: handleExitFullScreen,
  })

  // 全画面表示ボタン押下
  const handleFullScreenButtonClick = useCallback(() => {
    // ビューを右側に表示
    setSelectedAttendeeView(AttendeeViewStyles.displayOnTheRight.value)
    // 全画面表示
    setShow(true)
  }, [setShow])

  // 全画面表示終了ボタン押下
  const handleExitFullScreenButtonClick = useCallback(() => {
    handleExitFullScreen()
  }, [handleExitFullScreen])

  // 会議終了・退室ボタン押下
  const handleLeaveMeetingButtonClick = useCallback(async () => {
    if (!audioVideo) return

    // clear media pipeline
    dispatch(setMediaPipeline(null))

    // 他に参加者が残っていない場合は、会議を終了する
    if (attendeeUsers.length === 0) {
      // 会議終了処理
      dispatch(setIsEndMeeting(true))
      return
    }

    // 会議終了処理（デバイス関連）
    await meetingManager.leave()
  }, [attendeeUsers.length, audioVideo, dispatch, meetingManager])

  // 録画開始・録画終了ボタンクリック
  const handleRecordButtonClick = useCallback(async () => {
    // 録画にはmeetingIdが必要なため、設定されていない場合は処理しない
    if (!meetingManager.meetingId) {
      return
    }

    if (!audioVideo) {
      console.warn('AudioVideo object is not available')
      return
    }

    try {
      // 録画中の場合は録画終了処理を行い、録画中でない場合は録画開始処理を行う
      if (isRecording) {
        // Stop recording
        await endRecord(
          meetingManager.meetingId,
          mediaPipeline.mediaPipelineId,
          mediaPipeline.mediaPipelineArn
        )

        const recorder: RecorderInfo = {
          meetingId: meetingManager.meetingId,
          userId: loggedInUser?.id ?? '',
          displayName: loggedInUser?.displayName ?? '',
        }

        // Send realtime message that recording has ended
        audioVideo.realtimeSendDataMessage(
          TopicRecordingStopped,
          JSON.stringify(recorder),
          LIFE_TIME_MS
        )

        setRecorderId('')
      } else {
        // ストレージ使用量が上限超過している場合は、録画を開始しない
        const response = await lazyCheckStorageCapacityExceededQuery().unwrap()
        if (response.storageUsageExceeded) {
          toast.error(
            t('message.meetingRecording.storageCapacityExceededError')
          )
          return
        }

        // Start recording
        await startRecord(meetingManager.meetingId)

        const recorder: RecorderInfo = {
          meetingId: meetingManager.meetingId,
          userId: loggedInUser?.id ?? '',
          displayName: loggedInUser?.displayName ?? '',
        }

        // Send realtime message that recording has started
        audioVideo.realtimeSendDataMessage(
          TopicRecordingStarted,
          JSON.stringify(recorder),
          LIFE_TIME_MS
        )

        setRecorderId(loggedInUser?.id ?? '')
      }
    } catch (error) {
      console.log('error', error)
      showApiErrors(error)
    }
  }, [
    isRecording,
    meetingManager.meetingId,
    audioVideo,
    mediaPipeline,
    endRecord,
    loggedInUser?.id,
    loggedInUser?.displayName,
    lazyCheckStorageCapacityExceededQuery,
    startRecord,
    toast,
    t,
    showApiErrors,
  ])

  // 設定ボタン押下
  const handleWebMeetingSettingButtonClick = useCallback(() => {
    // 全画面表示中に設定ボタンを押下した場合は全画面表示を終了する
    isFullScreen && setShow(false)

    openWebMeetingSettingDialog()
  }, [isFullScreen, openWebMeetingSettingDialog, setShow])

  // 会議参加者数を画面レイアウト調整に使うためrosterから算出
  const { roster } = useRosterState()

  // check roster attendees has aws chime bot media pipeline
  // https://aws.amazon.com/vi/blogs/business-productivity/capture-amazon-chime-sdk-meetings-using-media-capture-pipelines/
  const hasMediaCapturePipelineBot = useMemo(
    () =>
      Object.values(roster).some((attendee) =>
        attendee.externalUserId?.includes(AWS_MEDIA_PIPELINE_PREFIX)
      ),
    [roster]
  )

  // 画面共有の状態（画面共有されていればsharingAttendeeIdがnullではない）
  const isSharing = sharingAttendeeId !== null

  const [selectedAttendeeView, setSelectedAttendeeView] = useState<string>(
    AttendeeViewStyles.displayOnTheBottom.value
  )

  // Get list attendee view style from enum
  const attendeeViewStyles = useMemo(() => {
    return Object.values(AttendeeViewStyles).map((item) => {
      return {
        value: t(item.text),
        key: item.value,
      }
    })
  }, [t])

  // Handle change attendee view style
  const handleAttendeeViewStyleChange = useCallback(
    (itemData: { key: string; value: string }) => {
      setSelectedAttendeeView(itemData.key)
    },
    []
  )

  const hasWaitingUsers = useMemo(
    () =>
      attendeeUsers.some(
        (user) => user.joinState === MeetingAttendeeJoinState.Waiting
      ),
    [attendeeUsers]
  )

  const [isShowPanelAttendees, setIsShowPanelAttendees] = useState(false)
  // Handle click attendee button
  const handleAttendeeButtonClick = useCallback(() => {
    setIsShowPanelAttendees(!isShowPanelAttendees)
  }, [isShowPanelAttendees])

  // Show meeting attendees when there are waiting users
  useValueChangeEffect(
    () => {
      if (hasWaitingUsers) {
        setIsShowPanelAttendees(true)
      }
    },
    [hasWaitingUsers],
    hasWaitingUsers
  )

  // Generate label for Recording button
  const buttonRecordingLabel = useMemo(() => {
    // 録画していない場合、「録画開始」を表示する。
    // 録画しているかどうか確認する。
    // (1) 録画した人のID（recorderId）が存在しない場合
    // (2) 会議の参加者が録画している場合、recorderIdを取得できないため、hasMediaCapturePipelineBotを使用し、確認する。
    // *** ログインユーザが会議の開催者の場合、「録画終了」ボタンを押すとrecorderIdがすぐにリセットされるが、
    //     hasMediaCapturePipelineBotの場合、その値はChimeから返される参加者一覧に依存するため、すぐにリセットされない。そのため、 (2)では、「開催者であるかどうか」という条件を加える必要がある。
    if (
      (!recorderId && isHost) ||
      (!recorderId && !hasMediaCapturePipelineBot)
    ) {
      return t('label.startRecording')
    }

    // 録画中の場合
    // ・ログインユーザが録画を開始した人の場合、「録画終了」を表示する
    // ・ログインユーザが録画を開始した人ではない場合、「録画中」を表示する
    if (recorderId === loggedInUser?.id) {
      return t('label.endRecording')
    } else {
      return t('label.recordingInprogress')
    }
  }, [hasMediaCapturePipelineBot, isHost, loggedInUser?.id, recorderId, t])

  const renderItemAttendeeView = useCallback(
    (item: ItemDropDownAttendeeView) => {
      return (
        <div className="flex flex-row items-center gap-2.5">
          <div className="w-5">
            {selectedAttendeeView === item.key && (
              <Check width={20} height={20} className="text-crew-blue-600" />
            )}
          </div>

          <span>{item.value}</span>
        </div>
      )
    },
    [selectedAttendeeView]
  )

  // 待機中、操作系のボタン（「マイク」～「設定」）はすべて使用不可
  const disableAction = useMemo(
    () => joinState === MeetingAttendeeJoinState.Waiting,
    [joinState]
  )

  const meetingDropdownItems: DropDownItem[] = useMemo(() => {
    return [
      {
        text: t('action.endMeeting'),
        value: 'endMeeting',
        icon: <CloseCircle width={24} height={24} />,
      },
    ]
  }, [t])

  // Handle drop down item click
  const handleDropDownItemClick = useCallback(
    (item: DropDownItem) => {
      if (item.value === 'endMeeting') {
        // clear media pipeline
        dispatch(setMediaPipeline(null))

        // NOTE: イベント詳細パネル側で検知して会議終了処理を実行
        dispatch(setIsEndMeeting(true))
      }
    },
    [dispatch]
  )

  // leave meeting button
  const renderLeaveButton = useCallback(() => {
    if (localAttendeeUser?.isHost) {
      // 主催者の場合：「会議を退室」「会議を終了」ボタンを表示
      return (
        <CrewDropDownButton
          text={t('action.leaveMeeting')}
          items={meetingDropdownItems}
          type="danger"
          splitButton
          keyExpr="value"
          displayExpr="text"
          icon={<SensorDoor width={24} height={24} />}
          onButtonClick={handleLeaveMeetingButtonClick}
          onItemClick={handleDropDownItemClick}
        />
      )
    } else {
      // 主催者ではない場合：「会議を退室」ボタンを表示
      return (
        <CrewButton
          icon={<SensorDoor width={24} height={24} />}
          text={isHost ? t('action.endMeeting') : t('action.leaveMeeting')}
          type="danger"
          onClick={handleLeaveMeetingButtonClick}
        />
      )
    }
  }, [
    handleDropDownItemClick,
    handleLeaveMeetingButtonClick,
    isHost,
    localAttendeeUser?.isHost,
    meetingDropdownItems,
    t,
  ])

  return (
    <>
      <div
        className={classNames(
          'flex flex-col gap-2 h-full w-full @container',
          // NOTE: 全画面表示時は親の背景スタイルが継承されないため別途テーマ背景色を設定
          isFullScreen && 'crew-bg-default relative'
        )}
        ref={targetRef}
      >
        <div className="flex flex-row flex-1 h-full w-full overflow-auto">
          {joinState === MeetingAttendeeJoinState.Waiting ? (
            <EventDetailWebMeetingWaitingRoom />
          ) : (
            <>
              <div
                className={classNames(
                  'flex h-full flex-1 gap-2.5 max-w-full overflow-auto',
                  {
                    'flex-row':
                      selectedAttendeeView ===
                      AttendeeViewStyles.displayOnTheRight.value,
                    'flex-col':
                      selectedAttendeeView ===
                      AttendeeViewStyles.displayOnTheBottom.value,
                  }
                )}
              >
                {/* 画面共有表示枠 */}
                {isSharing && (
                  <div className="flex-1 flex-shrink flex flex-col justify-center min-h-0">
                    <div className="justify-center h-full w-full">
                      <CrewContentShare className="h-full m-auto" />
                    </div>
                  </div>
                )}
                {/* 参加者一覧（通常時は列数を固定する。画面共有時は列数を固定せず全て横に並べる） */}
                {selectedAttendeeView !==
                  AttendeeViewStyles.doNotDisplay.value && (
                  <CrewAttendees
                    className={classNames({
                      'grid grid-cols-1 gap-5 overflow-y-auto': !isSharing,
                      '@xl:grid-cols-3 @3xl:grid-cols-5 @7xl:grid-cols-7':
                        !isSharing && !isShowPanelAttendees,
                      '@xl:grid-cols-2 @3xl:grid-cols-3 @7xl:grid-cols-5':
                        !isSharing && isShowPanelAttendees,
                      'flex flex-col overflow-y-auto gap-2.5':
                        selectedAttendeeView ===
                          AttendeeViewStyles.displayOnTheRight.value &&
                        isSharing,
                      'flex h-16 overflow-x-auto items-center gap-2.5':
                        selectedAttendeeView ===
                          AttendeeViewStyles.displayOnTheBottom.value &&
                        isSharing,
                    })}
                    isSharing={isSharing}
                    attendeeUsers={attendeeUsers}
                  />
                )}
              </div>
              {/* If isShowPanelAttendees === true, will add 1 column to the right */}
              {isShowPanelAttendees && (
                <EventDetailWebMeetingAttendeeList
                  isFullScreen={isFullScreen}
                  fullScreenTargetRef={targetRef}
                  attendeeUsers={attendeeUsers}
                  localAttendeeUser={localAttendeeUser}
                />
              )}
            </>
          )}
        </div>

        {/* ボタン群 */}
        <div
          className={classNames(
            'flex gap-x-2.5 items-center overflow-x-auto',
            isFullScreen && 'p-2'
          )}
        >
          <CrewMicrophoneMuteToggleButton disabled={disableAction} />
          <CrewCameraToggleButton disabled={disableAction} />
          <CrewContentShareButton disabled={disableAction} />
          {/* 全画面表示ボタン（全画面表示中は全画面表示終了ボタン） */}
          {isFullScreen ? (
            // 全画面表示終了
            <CrewButton
              icon={<BaselineFullscreen width={24} height={24} />}
              text={t('label.exitFullScreen')}
              type="danger"
              onClick={handleExitFullScreenButtonClick}
            />
          ) : (
            // 全画面表示
            <CrewButton
              icon={<BaselineFullscreen width={24} height={24} />}
              text={t('label.fullScreen')}
              type="normal"
              onClick={handleFullScreenButtonClick}
              stylingMode="outlined"
              disabled={disableAction}
            />
          )}

          {/* Update attendee view style */}
          <CrewDropDownButton
            text={t('label.view')}
            icon={<ViewSidebarOutlineSharp width={24} height={24} />}
            items={attendeeViewStyles}
            keyExpr="key"
            displayExpr="value"
            onItemClick={handleAttendeeViewStyleChange}
            selectedItemKey={selectedAttendeeView}
            showArrowIcon={false}
            showDivider={false}
            highlightSelected={false}
            disabled={disableAction || (!isLocalUserSharing && !isSharing)}
            itemRender={renderItemAttendeeView}
            container={isFullScreen ? targetRef : undefined}
            stylingMode="outlined"
            itemStylingMode="text"
          />

          {/* 録画ボタン */}
          <CrewButton
            // prevent click if isHost false
            disabled={!isHost || disableAction}
            icon={<FiberManualRecord width={24} height={24} />}
            text={buttonRecordingLabel}
            type={isRecording ? 'danger' : 'normal'}
            stylingMode={isRecording ? 'contained' : 'outlined'}
            onClick={handleRecordButtonClick}
          />

          {/* 参加者 */}
          <CrewButton
            icon={<GroupOutline width={24} height={24} />}
            text={t('label.attendee')}
            type="normal"
            stylingMode="outlined"
            onClick={handleAttendeeButtonClick}
            disabled={disableAction}
          />

          {/* 設定ボタン */}
          <CrewButton
            icon={<OutlineSettings width={24} height={24} />}
            text={t('label.settings')}
            type="normal"
            stylingMode="outlined"
            onClick={handleWebMeetingSettingButtonClick}
            disabled={disableAction}
          />

          {/* Display leave button when open full screen mode */}
          {isFullScreen && <div className="ml-auto">{renderLeaveButton()}</div>}
        </div>
      </div>
      {/* 会議設定ダイアログ */}
      <WebMeetingSettingDialog
        isOpen={isWebMeetingSettingDialogOpen}
        onClose={closeWebMeetingSettingDialog}
      />
    </>
  )
})
