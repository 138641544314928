import { FC, memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

type Event = {
  id: string | null
  subject: string | null
  startDatetime: string
  endDatetime: string
  isAllDay: boolean
}

export type AppointmentProps = {
  data: { appointmentData: Event }
}

export const Appointment: FC<AppointmentProps> = memo((props) => {
  const { t } = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  return (
    <div className="text-crew-gray-900 text-sm">
      <div className="flex items-center gap-1 justify-between">
        <p className="truncate">
          {props.data.appointmentData.id
            ? props.data.appointmentData.subject
            : t('label.planed')}
        </p>

        {/* Dont show startTime-endTime when event isAllDay */}
        {!props.data.appointmentData.isAllDay && (
          <div className="flex items-center gap-0.5">
            {/* start time */}
            <span>
              {t('format.shorttime', {
                value: props.data.appointmentData.startDatetime,
                timeZone: defaultUserProfileRegion,
              })}
            </span>
            <span>-</span>
            {/* end time */}
            <span>
              {t('format.shorttime', {
                value: props.data.appointmentData.endDatetime,
                timeZone: defaultUserProfileRegion,
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  )
})
