import { UpdatePresenceStateRequest } from '@crew/apis/presenceState/models/updatePresenceState/request'
import { InsertPresenceStateRequest } from '@crew/apis/presenceState/models/insertPresenceState/request'
import {
  useInsertPresenceStateMutation,
  useUpdatePresenceStateMutation,
} from '@crew/apis/presenceState/presenceStateApis'
import { PresenceStateType } from '@crew/enums/domain'
import { PresenceState } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { NotifyEventType } from 'enums/app'
import {
  notifyPresenceEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'states/hooks'

export type FormValues = {
  id: string | null
  name: string
  version: number | null
  presenceStateType: PresenceStateType | null
}

// ダイアログ初期値
const formInitialValues: FormValues = {
  id: null,
  name: '',
  version: null,
  presenceStateType: null,
}

export const usePresenceStatusEntryForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // presenceStateのinsert/update mutation
  const [insertPresenceState, { isLoading: isLoadingInsertPresence }] =
    useInsertPresenceStateMutation()
  const [updatePresenceState, { isLoading: isLoadingUpdatePresence }] =
    useUpdatePresenceStateMutation()

  // react-hook-formの各種データを取得
  const { control, reset, formState, handleSubmit, clearErrors, setError } =
    useForm<FormValues>({
      criteriaMode: 'all',
      defaultValues: formInitialValues,
    })

  // insert presence status
  const insertPresenceStatus = useCallback(
    async (data: FormValues) => {
      const request: InsertPresenceStateRequest = {
        presenceState: {
          name: data.name,
          presenceStateType: data.presenceStateType!,
        },
      }
      const result = await insertPresenceState(request).unwrap()

      const objectEventMessage: ObjectEventMessage<PresenceState> = {
        eventType: NotifyEventType.Inserted,
        id: result.presenceState?.id as string,
        object: result.presenceState ?? undefined,
      }

      dispatch(notifyPresenceEvent(objectEventMessage))
    },
    [dispatch, insertPresenceState]
  )

  // update presence status
  const updatePresenceStatus = useCallback(
    async (data: FormValues) => {
      const request: UpdatePresenceStateRequest = {
        presenceState: {
          id: data.id as string,
          name: data.name,
          presenceStateType: data.presenceStateType as PresenceStateType,
          version: data.version as number,
        },
      }
      const result = await updatePresenceState(request).unwrap()

      const objectEventMessage: ObjectEventMessage<PresenceState> = {
        eventType: NotifyEventType.Updated,
        id: result.presenceState?.id as string,
        object: result.presenceState ?? undefined,
      }

      dispatch(notifyPresenceEvent(objectEventMessage))
    },
    [dispatch, updatePresenceState]
  )

  // Validation rules
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      name: {
        required: t('message.general.isRequired', {
          name: t('label.name'),
        }),
        maxLength: {
          value: 100,
          message: t('message.general.maxLength', {
            name: t('label.name'),
            value: 100,
          }),
        },
      },
      presenceStateType: {
        required: t('message.general.isRequired', {
          name: t('label.type'),
        }),
      },
      // not validate below
      id: {},
      version: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,
    validateRules,
    insertPresenceStatus,
    updatePresenceStatus,
    isLoadingInsertPresence,
    isLoadingUpdatePresence,
  }
}
