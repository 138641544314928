import { memo } from 'react'
import { useAppSelector } from 'states/hooks'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
export const AppVerifyEmail = memo(() => {
  const { t } = useTranslation()

  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  return (
    <div className="flex flex-row h-[100dvh] pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm">
        <div className="flex flex-row items-center sm:mb-6">
          {/* ロゴ */}
          <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-4 font-bold text-xl">
            {t('message.signup.verifyYourEmailAddress')}
          </div>
          <p className="text-center">{t('message.signup.messageCheckMail')}</p>
        </div>
      </div>
    </div>
  )
})
