import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, memo, useCallback, useState } from 'react'

import { useMarkAllMessageAsReadMutation } from '@crew/apis/chat/chatApis'
import { EntityType, UnreadInfoTarget } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useCrewFavorite } from 'components/elements/crewFavorite/useCrewFavorite'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import MoreHoriz from '~icons/material-symbols/more-horiz'
import { useHasHover } from 'hooks/useHasHover'

export type LeftSideBarContextMenuProps = {
  target: UnreadInfoTarget
  chatRoomId: string | null
  projectId: string | null
  hover: boolean
  isActive: boolean
  isFavorite: boolean
  enableMarkAllRead: boolean
}

export const LeftSideBarContextMenu = memo(
  (props: LeftSideBarContextMenuProps) => {
    const { t } = useTranslation()
    const [showApiErrors] = useShowApiErrors()
    const { error } = useToast()

    const [markAllMessageAsReadMutation] = useMarkAllMessageAsReadMutation()
    const { subscribeFavorite, unsubscribeFavorite } = useCrewFavorite()

    const hasHover = useHasHover()

    // Event handler when mark all read button is clicked
    const handleMarkAllMessageAsReadButtonClicked = useCallback(
      async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.preventDefault()
        try {
          await markAllMessageAsReadMutation({
            target: props.target,
            chatRoomId: props.chatRoomId,
          }).unwrap()

          return false
        } catch (err) {
          showApiErrors(err)
        }
      },
      [
        markAllMessageAsReadMutation,
        props.chatRoomId,
        props.target,
        showApiErrors,
      ]
    )

    const handleSubscribeFromFavoritesButtonClick = useCallback(async () => {
      try {
        // お気に入り削除を実行
        props.projectId &&
          (await subscribeFavorite(EntityType.Project, props.projectId))
      } catch (err) {
        error(t('message.favorite.failedToFavorite'))
      }
    }, [props.projectId, subscribeFavorite, error, t])

    const handleUnsubscribeFromFavoritesButtonClick = useCallback(
      async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        try {
          e.preventDefault()
          // お気に入り削除を実行
          props.projectId &&
            (await unsubscribeFavorite(EntityType.Project, props.projectId))
        } catch (err) {
          error(t('message.favorite.failedToUnFavorite'))
        }
      },
      [props.projectId, unsubscribeFavorite, error, t]
    )

    const [isOpened, setIsOpened] = useState(false)

    const menuRender = useCallback(
      ({ open }: { open: boolean }) => {
        setIsOpened(open)
        return (
          <>
            <div>
              <Menu.Button className="flex items-center rounded-md">
                <CrewButton
                  icon={<MoreHoriz width={20} height={20} />}
                  type="normal"
                  stylingMode="text"
                  className="hover:bg-transparent focus:bg-transparent crew-mark-all-read-button"
                  size="sm"
                ></CrewButton>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {/* メニューの位置を固定し、一番上に表示する必要があるため、absoluteとz-10を指定している。 */}
              <Menu.Items
                static
                className="absolute font-normal right-0 z-10 w-36 origin-top-right rounded-md crew-bg-default shadow-lg ring-1 ring-crew-gray-200 dark:ring-crew-gray-700 focus:outline-none"
              >
                <div className="py-1">
                  {props.enableMarkAllRead && (
                    <Menu.Item>
                      {/* すべて既読にする */}
                      {({ active }) => (
                        <span
                          className={classNames(
                            { 'crew-bg-gray-2': active },
                            'block px-4 py-2 text-sm',
                            'cursor-pointer'
                          )}
                          onClick={handleMarkAllMessageAsReadButtonClicked}
                        >
                          {t('action.markAllRead')}
                        </span>
                      )}
                    </Menu.Item>
                  )}
                  {props.isFavorite ? (
                    <Menu.Item>
                      {/* お気に入り解除 */}
                      {({ active }) => (
                        <span
                          className={classNames(
                            { 'crew-bg-gray-2': active },
                            'block px-4 py-2 text-sm',
                            'cursor-pointer'
                          )}
                          onClick={handleUnsubscribeFromFavoritesButtonClick}
                        >
                          {t('label.removeFavorite')}
                        </span>
                      )}
                    </Menu.Item>
                  ) : (
                    <Menu.Item>
                      {/* お気に入り追加 */}
                      {({ active }) => (
                        <span
                          className={classNames(
                            { 'crew-bg-gray-2': active },
                            'block px-4 py-2 text-sm',
                            'cursor-pointer'
                          )}
                          onClick={handleSubscribeFromFavoritesButtonClick}
                        >
                          {t('label.addFavorite')}
                        </span>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )
      },
      [
        handleMarkAllMessageAsReadButtonClicked,
        handleSubscribeFromFavoritesButtonClick,
        handleUnsubscribeFromFavoritesButtonClick,
        props.enableMarkAllRead,
        props.isFavorite,
        t,
      ]
    )

    if (
      (props.hover && hasHover) || // Hover時は表示。このとき、デバイスがhoverに対応しているかどうかを考慮する
      isOpened || // メニュー開の時は表示
      props.isActive // アクティブ状態の場合は表示
    ) {
      return (
        /* mark all read button */
        /* TODO: CrewButtonにMDIが適用できないので暫定対応でbootstrapで代用
                アイコン付きボタンの実装についてはCREW-7493で対応
                https://break-tmc.atlassian.net/browse/CREW-7493 */
        <div className="flex flex-row items-center absolute right-0 h-full">
          <Menu as="div" className="relative inline-block text-left">
            {menuRender}
          </Menu>
        </div>
      )
    }

    return null
  }
)
