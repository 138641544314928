import { useAppSelector } from 'states/hooks'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { useMemo, memo } from 'react'
import { useTranslation } from '@crew/modules/i18n'

export const TenantNotExist = memo(() => {
  const themeMode = useAppSelector((state) => state.app.currentTheme)
  const { t } = useTranslation()
  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  return (
    <div className="flex flex-col items-center h-[100dvh] justify-center crew-bg-gray-1">
      <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto mb-8" />
      <p className="text-center text-2xl">
        {t('message.auth.pageDoesNotExist')}
      </p>
    </div>
  )
})
