import {
  CrewAvatarShapeType,
  CrewAvatarSize,
  CrewAvatar,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewProgressBarItem } from 'components/elements/crewProgressBar/crewProgressBarItem'
import { EntityType, ProjectType } from '@crew/enums/domain'
import { memo, useMemo } from 'react'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { ProjectListTabs, ProjectScope } from 'enums/app'
import { CrewFavorite } from 'components/elements/crewFavorite/crewFavorite'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { getDefaultTabValue } from '@crew/utils/enum'
import { CrewGroupAvatar } from 'components/elements/crewGroupAvatar/crewGroupAvatar'
import { useAppSelector } from 'states/hooks'
import { ProjectGroupRef, UserRef } from '@crew/models/refs'
import { Favorite } from '@crew/models/domain'

type Project = {
  id: string
  subject: string
  description: string | null
  scope: string
  projectGroupId: string | null
  ownerUser: UserRef
  projectMembers: UserRef[]
  projectGroup: ProjectGroupRef | null
  projectFavorite: Favorite | null
  projectType: ProjectType
  version: number
}

export type ProjectItemProps = {
  item: Project
}

export const ProjectItem = memo((props: ProjectItemProps) => {
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const isJoinedProject = useMemo(() => {
    // // Check if loggedInUser and props.item exist
    if (loggedInUser && props.item) {
      // Check if loggedInUser is present in the projectMembers list of props.item
      return props.item.projectMembers.some(
        (projectMember) => loggedInUser.id === projectMember.id
      )
    } else {
      // Return false if loggedInUser or props.item does not exist
      return false
    }
  }, [loggedInUser, props.item])

  return (
    <div className="flex flex-col gap-2.5 p-2.5 break-inside-avoid-column rounded-md ring-1 crew-ring-gray shadow-md crew-shadow-gray">
      <div className="flex flex-row items-start gap-3">
        <div className="flex gap-x-2.5 w-full items-start">
          <CrewAvatar
            displayName={props.item.subject}
            shape={CrewAvatarShapeType.Square}
            size={CrewAvatarSize.sm}
            isPrivate={props.item.scope === ProjectScope.Private.key}
            imageURL={generateImageAvatarUrl(EntityType.Project, props.item.id)}
            cacheValue={props.item.id + props.item.version}
          />

          <div className="flex flex-col min-w-0 flex-1">
            <CrewLink
              to={`/projects/${props.item.id}/${getDefaultTabValue(
                ProjectListTabs
              )}`}
              className="font-bold text-lg break-words"
              title={props.item.subject}
            >
              {props.item.subject}
            </CrewLink>
            <div className="flex flex-row items-center justify-end gap-x-2">
              <div className="flex-1 truncate text-sm crew-text-gray-5">
                {props.item.projectGroup?.name}
              </div>
              {isJoinedProject && (
                <>
                  {/* お気に入りアイコン  */}
                  <CrewFavorite
                    entityType={EntityType.Project}
                    entityRecordId={props.item.id}
                    isFavorite={props.item.projectFavorite ? true : false}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-lg break-words">{props.item.description}</div>
      <CrewProgressBarItem projectId={props.item.id} />
      <div className="flex justify-between mt-2.5">
        {/* プロジェクトメンバー */}
        <CrewGroupAvatar groupAvatar={props.item.projectMembers} />
        {/* Project owner */}
        <div className="flex gap-x-1.5 items-center">
          <CrewAvatar
            displayName={props.item.ownerUser.displayName}
            imageURL={generateImageAvatarUrl(
              EntityType.User,
              props.item.ownerUser.id
            )}
            size={CrewAvatarSize.sm}
            cacheValue={props.item.ownerUser.id + props.item.ownerUser.version}
          />
          <span className="text-lg">{props.item.ownerUser?.displayName}</span>
        </div>
      </div>
    </div>
  )
})
