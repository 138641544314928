import { useCallback, useMemo } from 'react'

import { useUpdateChatMessageMutation } from '@crew/apis/chat/chatApis'
import { useChatMessage } from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'

export const useFeedMessageListItem = (chatMessageId: string) => {
  const dispatch = useAppDispatch()
  const { message, isError } = useChatMessage(
    chatMessageId,
    dispatch,
    useAppSelector
  )

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const [updateChatMessageMutation] = useUpdateChatMessageMutation()

  // 自分が投稿したメッセージ判定（自分のメッセージのみ、権限があれば添付ファイル削除が可能とする）
  const isMyMessage = useMemo(
    () => message?.createdById === loggedInUser?.id,
    [loggedInUser?.id, message?.createdById]
  )

  // 添付ファイル削除後にメッセージ更新APIを実行してwebsocketによる表示の更新を行う
  const updateChatMessage = useCallback(async () => {
    if (!message) {
      return
    }

    const mentionUserIds: string[] = message.mentions
      ? Object.values(message.mentions).map((item) => {
          return item.userId
        })
      : []

    try {
      await updateChatMessageMutation({
        chatMessage: {
          chatMessageId: message?.id,
          text: message?.text,
          version: message?.version,
        },
        mentionUserIds: mentionUserIds.length === 0 ? null : mentionUserIds,
      })
    } catch (err) {
      // 成功可否によるトースト表示などはないのでエラー時はエラーログを出力
      console.error(err)
    }
  }, [message, updateChatMessageMutation])

  return {
    message,
    isError,
    isMyMessage,
    updateChatMessage,
  }
}
