import { FC, memo } from 'react'

type MediaCardProps = {
  url: string
}

export const MediaCard: FC<MediaCardProps> = memo((props) => {
  return (
    <div className="w-full aspect-video drop-shadow-xl">
      <iframe
        width="100%"
        height="100%"
        src={props.url}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  )
})
