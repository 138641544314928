import { FC, memo, useCallback } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { formatByteSize } from '@crew/utils/number'
import { CrewCheckBox } from 'components/devextreme/crewCheckBox'
import { File } from '@crew/apis/file/models/getFiles/response'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { FileThumbnailItem } from './components/fileThumbnailItem/fileThumbnailItem'
import { CrewLink } from '../crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import { FileDetailListTabs } from 'enums/app'
import { isValidThumbnailFile } from '@crew/utils/file'
import { FileIconItem } from './components/fileIconItem/fileIconItem'
import classNames from 'classnames'

type CrewGridFileItemProps = {
  file: File
  onSelect: (id: string, version: number, entityRecordId: string) => void
}

export const CrewGridFileItem: FC<CrewGridFileItemProps> = memo((props) => {
  const { t } = useTranslation()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // チェックボックスの選択状態変更時
  const handleChangeBoxChange = useCallback(() => {
    props.onSelect(props.file.id, props.file.version, props.file.entityRecordId)
  }, [props])

  return (
    <div className="w-full border crew-border-default">
      <CrewLink
        to={`/files/${props.file.id}/${getDefaultTabValue(FileDetailListTabs)}`}
        className="flex flex-col gap-1.5 w-full grow"
        draggable={false}
      >
        <div
          // 外側にmarginをつけつつ、コンテンツを仕様に沿って表示するためのdiv
          className={classNames(
            // 外側のdivで計算される最大横幅を引き継ぐ
            'max-w-full',
            // aspectを指定することで、横幅に応じた縦幅を計算させる
            'aspect-[4/3]',
            // 角丸その他の設定
            'cursor-pointer'
          )}
        >
          <div className="w-full h-full">
            {isValidThumbnailFile(props.file.fileName) ? (
              <FileThumbnailItem
                id={props.file.id}
                fileName={props.file.name}
                draggable={false}
              />
            ) : (
              <FileIconItem fileName={props.file.name} />
            )}
          </div>
        </div>

        <div className="flex flex-col p-2 gap-1.5 w-full grow">
          {/* filename */}
          <p className="font-bold line-clamp-2 crew-text-default">
            {props.file.name}
          </p>

          <div className="flex flex-col grow justify-end gap-1.5 crew-text-default">
            {/* tag */}
            <div className="flex flex-row items-center flex-wrap gap-1">
              {props.file.tags.map((tag) => {
                return (
                  <div
                    key={tag.id}
                    className="crew-bg-default border w-auto crew-bg-default rounded-full px-2 py-1 text-sm line-clamp-1 max-w-[80px]"
                  >
                    {tag.name}
                  </div>
                )
              })}
            </div>

            <div className="flex flex-row items-center justify-between text-sm">
              {/* version */}
              <p>
                {t('label.version')}: {props.file.revision}
              </p>

              {/* file size */}
              <p>{formatByteSize(props.file.size)}</p>
            </div>
          </div>
        </div>
      </CrewLink>

      <div className="flex flex-row items-center justify-between text-sm px-2 pb-2">
        {/* File last updated date */}
        <p>
          {t('format.timestamp', {
            value: props.file.lastUpdatedAttachmentAt,
            timezone: defaultUserProfileRegion,
          })}
        </p>

        <CrewCheckBox onValueChange={handleChangeBoxChange} />
      </div>
    </div>
  )
})
