import { useTranslation } from '@crew/modules/i18n'
import { Modal } from 'components/layouts/modal/modal'
import { memo } from 'react'
import { EventDetailAttendeesEntryForm } from './components/eventDetailAttendeesEntryForm/eventDetailAttendeesEntryForm'
import { EventType } from '@crew/enums/domain'

type EventDetailAttendeeEntryDialogProps = {
  title: string
  isOpen: boolean
  eventType: EventType
  onClose: () => void
}

export const EventDetailAttendeesEntryDialog = memo(
  (props: EventDetailAttendeeEntryDialogProps) => {
    const { t } = useTranslation()
    return (
      <Modal isOpen={props.isOpen} title={props.title} onClose={props.onClose}>
        {/* モーダルの最小幅を制限し、画面の各入力項目が正しく表示されるようにする */}
        <div className="flex flex-col gap-y-5 min-w-[600px]">
          <p className="text-sm">{t('label.addAttendeeDescription')}</p>

          <EventDetailAttendeesEntryForm
            onClose={props.onClose}
            eventType={props.eventType}
          />
        </div>
      </Modal>
    )
  }
)
