import { useCallback, useMemo, useState } from 'react'

import { useUpdateChatMessageMutation } from '@crew/apis/chat/chatApis'
import { useChatMessage } from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'

export const useChatThreadMessageListItem = (chatMessageId: string) => {
  // 処理対象のスレッドをViewModelから取得
  const currentChatThread = useAppSelector(
    (state) => state.message.chat.current.chatThread
  )
  // この処理が流れる際、ViewModelには必ずスレッドが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatThread) {
    throw new Error('currentChatThread is undefined')
  }

  const dispatch = useAppDispatch()
  const { message, isError } = useChatMessage(
    chatMessageId,
    dispatch,
    useAppSelector
  )

  // 編集モードかどうか
  const [isEditMode, setIsEditMode] = useState(false)

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const [updateChatMessageMutation] = useUpdateChatMessageMutation()

  // 自分が投稿したメッセージ判定（自分のメッセージのみ編集可能とするフラグ）
  const isMyMessage = useMemo(
    () => message?.createdById === loggedInUser?.id,
    [loggedInUser?.id, message?.createdById]
  )

  // 「編集」押下時に編集モードを切り替える
  const toggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode)
  }, [isEditMode])

  // 編集モードの登録 / キャンセル時に編集モードをoffにする
  const cancelEditMode = useCallback(() => {
    setIsEditMode(false)
  }, [])

  // 添付ファイル削除後にメッセージ更新APIを実行してwebsocketによる表示の更新を行う
  const updateChatMessage = useCallback(async () => {
    if (!message) {
      return
    }

    const mentionUserIds: string[] = message.mentions
      ? Object.values(message.mentions).map((item) => {
          return item.userId
        })
      : []

    try {
      await updateChatMessageMutation({
        chatMessage: {
          chatMessageId: message?.id,
          text: message?.text,
          version: message?.version,
        },
        mentionUserIds: mentionUserIds.length === 0 ? null : mentionUserIds,
      })
    } catch (err) {
      // 成功可否によるトースト表示などはないのでエラー時はエラーログを出力
      console.error(err)
    }
  }, [message, updateChatMessageMutation])

  return {
    currentChatThread,
    message,
    isError,
    toggleEditMode,
    cancelEditMode,
    updateChatMessage,
    isMyMessage,
    isEditMode,
  }
}
