import { useCallback } from 'react'

// Extend the HTMLAudioElement interface
type CrewHTMLAudioElement = HTMLAudioElement & {
  setSinkId(sinkId: string): Promise<void>
}

export const useCrewSpeakerTestButton = () => {
  const checkAudioOutput = useCallback(
    async (deviceId: string, audioElementId: string): Promise<void> => {
      const audioElement = document.getElementById(
        audioElementId
      ) as CrewHTMLAudioElement

      if (typeof audioElement.setSinkId === 'function') {
        try {
          await audioElement.setSinkId(deviceId)
        } catch (error) {
          console.error('Error setting audio output device:', error)
          return
        }
      } else {
        console.warn('setSinkId() is not supported in this browser.')
      }

      try {
        await audioElement.play()
      } catch (error) {
        console.error('Error playing audio:', error)
      }
    },
    []
  )

  return { checkAudioOutput }
}
