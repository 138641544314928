import { useCallback, useMemo, useState } from 'react'

import { useUpdateChatMessageMutation } from '@crew/apis/chat/chatApis'
import {
  useChatCurrentService,
  useChatMessage,
  useChatThreadListService,
} from '@crew/states'

import { toggleRightSideBar } from 'features/app/states/appSlice'
import { useAppDispatch, useAppSelector } from 'states/hooks'

export const useChatThreadListMessageListItem = (chatMessageId: string) => {
  // 処理対象のチャットルームをViewModelから取得
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )
  // この処理が流れる際、ViewModelには必ずチャットルームが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatRoom) {
    throw new Error('currentChatRoom is undefined')
  }

  const dispatch = useAppDispatch()
  const { message, isError } = useChatMessage(
    chatMessageId,
    dispatch,
    useAppSelector
  )

  // 編集モードかどうか
  const [isEditMode, setIsEditMode] = useState(false)

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  // 右パネルが開いているかどうか
  // 返信ボタンクリック時に、右パネルが開いていなければ開くために使用
  const rightSideBarOpened = useAppSelector(
    (state) => state.app.rightSideBarOpened
  )

  const [updateChatMessageMutation] = useUpdateChatMessageMutation()

  // 自分が投稿したメッセージ判定（自分のメッセージのみ編集可能とするフラグ）
  const isMyMessage = useMemo(
    () => message?.createdById === loggedInUser?.id,
    [loggedInUser?.id, message?.createdById]
  )

  // Sliceの操作を行うためのServiceを取得
  const chatThreadListService = useChatThreadListService(dispatch)
  const chatCurrentService = useChatCurrentService(dispatch)

  // 返信のクリック時に対象スレッドを開く
  const openTargetThread = useCallback(() => {
    // メッセージがクリックされたときにスレッド表示に遷移するためtopicIdをセットする
    // ThreadListの場合は表示されているアイテムすべてがトピックなのでchatMessageIdをそのまま設定すれば良い
    chatThreadListService.setThreadListTopicId({
      chatRoomId: currentChatRoom.id,
      chatMessageId,
    })

    // トピックが存在する場合、現在のチャットスレッドを更新する
    if (message) {
      chatCurrentService.setCurrentChatThread({
        topicId: message.id,
        chatRoomId: message.chatRoomId,
        rootEntityType: message.chatRoom.rootEntityType,
        rootEntityRecordId: message.chatRoom.rootEntityRecordId,
        entityType: message.chatRoom.entityType,
        entityRecordId: message.chatRoom.entityRecordId,
      })
    }

    // 選択されるメッセージはすべてトピックメッセージのため、選択したメッセージにスクロールする処理は発生しない

    // 右サイドバーを開く
    !rightSideBarOpened && dispatch(toggleRightSideBar())
  }, [
    chatThreadListService,
    currentChatRoom.id,
    chatMessageId,
    message,
    rightSideBarOpened,
    dispatch,
    chatCurrentService,
  ])

  // 「編集」押下時に編集モードを切り替える
  const toggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode)
  }, [isEditMode])
  // 編集モードの登録 / キャンセル時に編集モードをoffにする
  const cancelEditMode = useCallback(() => {
    setIsEditMode(false)
  }, [])

  // 添付ファイル削除後にメッセージ更新APIを実行してwebsocketによる表示の更新を行う
  const updateChatMessage = useCallback(async () => {
    if (!message) {
      return
    }

    const mentionUserIds: string[] = message.mentions
      ? Object.values(message.mentions).map((item) => {
          return item.userId
        })
      : []

    try {
      await updateChatMessageMutation({
        chatMessage: {
          chatMessageId: message?.id,
          text: message?.text,
          version: message?.version,
        },
        mentionUserIds: mentionUserIds.length === 0 ? null : mentionUserIds,
      })
    } catch (err) {
      // 成功可否によるトースト表示などはないのでエラー時はエラーログを出力
      console.error(err)
    }
  }, [message, updateChatMessageMutation])

  return {
    currentChatRoom,
    message,
    isError,
    toggleEditMode,
    cancelEditMode,
    openTargetThread,
    updateChatMessage,
    isMyMessage,
    isEditMode,
  }
}
