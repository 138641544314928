import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ValidateRules } from '@crew/utils/form'
import { useResetPasswordMutation } from '@crew/apis/app/appApis'
import { trimSpace } from '@crew/utils'

export type FormValues = {
  loginIdOrEmailAddress: string
}

export const useAppForgotPasswordPage = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  // Get function for reset password
  const [resetPasswordMutation, { isLoading: isLoadingResetPassword }] =
    useResetPasswordMutation()

  // Reset password process
  const resetPassword = useCallback(
    async (loginIdOrEmailAddress: string) => {
      await resetPasswordMutation({
        destination: trimSpace(loginIdOrEmailAddress),
      }).unwrap()
    },
    [resetPasswordMutation]
  )

  // 以下、入力コンポーネントのルールとレンダリングを定義
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      loginIdOrEmailAddress: {
        required: t('message.general.isRequired', {
          name: t('label.username'),
        }),
        maxLength: {
          value: 500,
          message: t('message.general.maxLength', {
            name: t('label.username'),
            value: 500,
          }),
        },
      },
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    formState,
    reset,
    validateRules,
    resetPassword,
    isLoadingResetPassword,
  }
}
