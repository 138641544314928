import { useTranslation } from '@crew/modules/i18n'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  CrewNavTabProps,
  BadgeColor,
} from 'components/elements/crewNavTabs/components/crewNavTab'
import { CrewNavTabs } from 'components/elements/crewNavTabs/crewNavTabs'
import { SearchTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { memo, useMemo } from 'react'
import { GetRecordsCountRequest } from '@crew/apis/crossSearch/models/getRecordsCount/request'
import { useGetRecordsCountQuery } from '@crew/apis/crossSearch/crossSearchApis'
import { useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { getParamAsString, getParamsOperator } from 'utils'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import {
  chatMessageCountUpdated,
  eventCountUpdated,
  fileCountUpdated,
  projectCountUpdated,
  taskCountUpdated,
} from '../../states/searchPageSlice'
import { useValueChangeEffect } from '@crew/hooks'

export const SearchTab = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { navigate } = useCrewNavigate()

  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())

  const badgeCount = useAppSelector((state) => state.searchPage.badgeCount)

  // Keyword params
  const keyword = useMemo(() => getParamAsString('keyword', params), [params])
  const operation = useMemo(
    () => getParamsOperator('operation', params),
    [params]
  )

  // Get records count of cross search
  const getRecordsCountParams: GetRecordsCountRequest | undefined =
    keyword && operation
      ? {
          keyword,
          keywordFilterCondition: operation,
          projectId: undefined, // TODO: CREW-18294で実装
        }
      : undefined
  const { data: getRecordsCountResult } = useGetRecordsCountQuery(
    getRecordsCountParams ?? skipToken
  )

  useValueChangeEffect(
    () => {
      // Get record count & save to redux
      if (getRecordsCountResult) {
        dispatch(
          chatMessageCountUpdated(getRecordsCountResult.chatMessagesCount)
        )
        dispatch(projectCountUpdated(getRecordsCountResult.projectsCount))
        dispatch(taskCountUpdated(getRecordsCountResult.tasksCount))
        dispatch(fileCountUpdated(getRecordsCountResult.filesCount))
        dispatch(eventCountUpdated(getRecordsCountResult.eventsCount))
      }
    },
    [dispatch, getRecordsCountResult],
    getRecordsCountResult
  )

  // タブアイテム
  const displayTabItems: CrewNavTabProps[] = useMemo(() => {
    let items = Object.values(SearchTabs).map((item) => {
      // Check to get record count result
      let badgeCountResult = undefined
      switch (item.value) {
        case SearchTabs.Chat.value:
          badgeCountResult = badgeCount.chatMessageCount
          break
        case SearchTabs.Project.value:
          badgeCountResult = badgeCount.projectCount
          break
        case SearchTabs.Task.value:
          badgeCountResult = badgeCount.taskCount
          break
        case SearchTabs.File.value:
          badgeCountResult = badgeCount.fileCount
          break
        case SearchTabs.Event.value:
          badgeCountResult = badgeCount.eventCount
          break
      }

      return {
        onClick: () => {
          // タブに対応するURLへ遷移（相対パス指定）
          navigate(item.value, { keyword, operation })
        },
        to: item.value,
        text: t(item.text),
        icon: item.icon,
        badgeCount: badgeCountResult,
        badgeColor: 'Blue' as BadgeColor,
      }
    })

    return items
  }, [
    badgeCount.chatMessageCount,
    badgeCount.eventCount,
    badgeCount.fileCount,
    badgeCount.projectCount,
    badgeCount.taskCount,
    keyword,
    navigate,
    operation,
    t,
  ])

  return <CrewNavTabs tabItems={displayTabItems} />
})
