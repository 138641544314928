import classNames from 'classnames'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { UnreadInfoTarget } from '@crew/enums/domain'
import { getDefaultTabValue } from '@crew/utils/enum'

import { DirectMessageTabs } from 'enums/app'

import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewChatRoomUnreadBadge } from 'components/elements/crewChatRoomUnreadBadge/crewChatRoomUnreadBadge'
import { ActiveChatRoomInfo } from '@crew/states'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'

import { LeftSideBarContextMenu } from '../../../../../leftSideBarContextMenu/leftSideBarContextMenu'
import { GetProjectForDirectChannelRequest } from '@crew/apis/project/models/getProjectForDirectChannel/request'
import { useGetProjectForDirectChannelQuery } from '@crew/apis/project/projectApis'

export type DirectMessageUserListItemProps = {
  chatRoom: ActiveChatRoomInfo
}

// メニューの基本スタイル
const baseClassNames =
  'flex flex-row h-full w-full items-center p-2 gap-2 hover:font-bold [&:has(.has-unread)]:font-bold group-hover:font-bold'

// 選択メニューのアクティブ状態スタイル（ハイライト）
const activeClassNames = 'font-bold crew-text-gray-1 crew-bg-gray-2'

export const DirectMessageUserListItem: FC<DirectMessageUserListItemProps> =
  memo((props) => {
    const [hover, setHover] = useState(false)
    const location = useLocation()
    // ホバーメニューを表示
    const handleMouseEnter = useCallback(() => {
      setHover(true)
    }, [])

    // ホバーメニューを非表示
    const handleMouseLeave = useCallback(() => {
      setHover(false)
    }, [])

    // Get direct channel
    const getProjectForDirectChannelRequest: GetProjectForDirectChannelRequest =
      {
        projectId: props.chatRoom.projectId,
      }
    const { data: getDirectChannelResult } = useGetProjectForDirectChannelQuery(
      getProjectForDirectChannelRequest
    )

    // URLの変更に応じてハイライトさせるかどうかをハンドリングする
    const isActive = useMemo(
      () =>
        // eg: location.pathname = /direct-channels/{projectId}/xxx
        // projectIdのみで判定する。projectIdで一意に識別出来るので、他の条件は不要
        location.pathname.split('/')[2] === props.chatRoom.projectId,
      [location, props.chatRoom.projectId]
    )

    // Do not show content if the direct channel does not existed
    if (!getDirectChannelResult?.directChannel) {
      return null
    }

    // Get the partner of the logged in user in the current channel
    const partner = getDirectChannelResult?.directChannel?.user2

    return (
      <li
        // Warning表示回避のkey指定
        key={props.chatRoom.projectId}
        className="flex flex-row group relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NavLink
          // ユーザー詳細画面に遷移する
          to={`direct-channels/${props.chatRoom.projectId}/${getDefaultTabValue(
            DirectMessageTabs
          )}`}
          className={classNames(baseClassNames, isActive && activeClassNames)}
        >
          <CrewUserAvatar
            userId={partner?.id}
            displayName={partner?.displayName}
            size={CrewAvatarSize.xs}
            cacheValue={
              props.chatRoom.projectId + props.chatRoom.projectVersion
            }
            showPresenceState={true}
          />
          {/* 選択中 または 未読ありの場合、太字で表示 */}
          <span className="line-clamp-2">{partner?.displayName}</span>
          <CrewChatRoomUnreadBadge chatRoomId={props.chatRoom.chatRoomId} />
        </NavLink>

        <LeftSideBarContextMenu
          target={UnreadInfoTarget.ChatRoom}
          chatRoomId={props.chatRoom.chatRoomId}
          projectId={props.chatRoom.projectId}
          hover={hover}
          isActive={isActive}
          isFavorite={props.chatRoom.isFavorite}
          enableMarkAllRead={false} // 「ダイレクトメッセージ」では「すべて既読にする」は非表示
        />
      </li>
    )
  })
