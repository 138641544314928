import { Region, SettingKeyType } from '@crew/enums/app'
import { EntityType } from '@crew/enums/domain'
import { useProjectPermissions } from '@crew/hooks'
import { EventKindRef, UserRef, ProjectRef } from '@crew/models/refs'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { getDefaultTabValue } from '@crew/utils/enum'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useModal } from 'components/layouts/modal/useModal'
import { EventDetailTabs, ProjectListTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { FC, memo, useCallback, useMemo } from 'react'
import { CrewBadge } from '../crewBadge/crewBadge'
import { CrewHtmlContent } from '../crewHtmlContent/crewHtmlContent'
import classNames from 'classnames'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import { CrewGroupAvatar } from '../crewGroupAvatar/crewGroupAvatar'
import { CrewAvatarSize } from '../crewAvatar/crewAvatar'
import { CrewLink, LinkColor } from '../crewLink/crewLink'
import { GetMyRoleInEventRequest } from '@crew/apis/project/models/getMyRoleInEvent/request'
import { useGetMyRoleInEventQuery } from '@crew/apis/project/projectApis'
import { JsonDateFormat } from '@crew/enums/system'
import dayjs from '@crew/modules'
import { skipToken } from '@reduxjs/toolkit/query'
import { EventScheduleEntryDialog } from 'features/event/components/eventScheduleEntryDialog/eventScheduleEntryDialog'
import { ProjectType } from '@crew/enums/domain'

export type EventPopover = {
  id: string | null
  subject: string | null
  entityRecordId: string | null
  description: string | null
  startDatetime: string | null
  endDatetime: string | null
  attendees: UserRef[]
  eventKind: EventKindRef | null
  project: ProjectRef
  isAllDay: boolean
}

type CrewEventPopoverProps = {
  data: EventPopover
  onCloseTooltip?: () => void
}

export const CrewEventPopover: FC<CrewEventPopoverProps> = memo((props) => {
  const { hasPrjEventEditPermission } = useProjectPermissions(
    EntityType.Event,
    props.data.id ?? ''
  )

  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  // Get my role in current Event
  const getMyRoleInEventParams: GetMyRoleInEventRequest | undefined = props.data
    .id
    ? {
        eventId: props.data.id,
      }
    : undefined

  const { data: myRoleInEvent } = useGetMyRoleInEventQuery(
    getMyRoleInEventParams ?? skipToken
  )

  // イベント参加者orプロジェクトメンバーの場合は「詳細」「編集」ボタンを表示する
  // ただし、「編集」ボタンは上記を満たしたうえでhasPrjEventEditPermissionも考慮すること
  // Check if logged in user can view current event
  const canViewEvent = useMemo(() => {
    return myRoleInEvent?.isProjectMember
  }, [myRoleInEvent?.isProjectMember])

  // flag to show project name
  const displayProjectName = useMemo(() => {
    // Only show project name when it is a project
    return props.data.project.projectType === ProjectType.Project
  }, [props.data.project])

  // Check if logged in user can edit current event
  const canEditEvent = useMemo(() => {
    return myRoleInEvent?.isProjectMember && hasPrjEventEditPermission
  }, [hasPrjEventEditPermission, myRoleInEvent?.isProjectMember])

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  const [isEventEntryDialogOpen, openEventEntryDialog, closeEventEntryDialog] =
    useModal()

  // Click the detail button to navigate to event detail
  const handleDetailButtonClick = useCallback(() => {
    navigate(`/events/${props.data.id}/${getDefaultTabValue(EventDetailTabs)}`)
  }, [navigate, props.data.id])

  // Update event finish
  const handleEventUpdated = useCallback(() => {
    closeEventEntryDialog()
  }, [closeEventEntryDialog])

  // check startDatetime and endDatetime is same day
  const isSameDay = useMemo(
    () =>
      dayjs(props.data.startDatetime).format(JsonDateFormat.YYYYMMDD) ===
      dayjs(props.data.endDatetime).format(JsonDateFormat.YYYYMMDD),
    [props.data.endDatetime, props.data.startDatetime]
  )

  // https://supportcenter.devexpress.com/ticket/details/t748663/dxscheduler-remove-click-effect-from-tooltips
  // Stop the click event from propagating to the parent element
  const handleTooltipAreaClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
  }, [])

  const handleEditEventButtonClick = useCallback(() => {
    // Close Event Popover
    props.onCloseTooltip?.()

    // Open Event Entry Dialog
    openEventEntryDialog()
  }, [openEventEntryDialog, props])

  const displayDatetimeDuration = useMemo(() => {
    if (isSameDay) {
      if (props.data.isAllDay) {
        return (
          <time>
            {t('format.date', {
              value: props.data.startDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
        )
      }

      // 開始と終了で日付が同じ場合は、終了日の時刻だけを表示する
      return (
        <>
          <time>
            {t('format.datetime', {
              value: props.data.startDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
          <time>-</time>
          <time>
            {t('format.shorttime', {
              value: props.data.endDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
        </>
      )
    } else {
      // isAllDayの場合、時分を表示しない
      let dateFormat = props.data.isAllDay ? 'format.date' : 'format.datetime'

      // 開始と終了で日付が異なる場合は日付と時刻を表示
      return (
        <>
          <time>
            {t(dateFormat, {
              value: props.data.startDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
          <time>-</time>
          <time>
            {t(dateFormat, {
              value: props.data.endDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
        </>
      )
    }
  }, [
    defaultUserProfileRegion,
    isSameDay,
    props.data.endDatetime,
    props.data.isAllDay,
    props.data.startDatetime,
    t,
  ])

  // when the event is private return only the start and end time
  // https://break-tmc.atlassian.net/browse/CREW-17682
  if (!props.data.id) {
    return (
      <div
        className="flex flex-row justify-between items-center crew-bg-default crew-text-default p-2.5"
        onClick={handleTooltipAreaClick}
      >
        <div>{t('label.planed')}</div>
        {/* Display event start-endtime */}
        <div className="flex flex-row gap-0.5 font-bold">
          {displayDatetimeDuration}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        // DevExtremeの仕様でツールチップをクリックしたときに勝手に背景色と文字色が変わるので、暫定対応として「crew-bg-default」「crew-text-default」で上書き
        // TODO: 上記の他にツールチップ内をドラッグするとエラーになるので、これらの調査をCREW-8318で行う
        //       https://break-tmc.atlassian.net/browse/CREW-8318
        className="flex flex-col gap-y-2.5 p-1.5 crew-bg-default crew-text-default"
        onClick={handleTooltipAreaClick}
      >
        <div className="w-full flex gap-x-2.5 items-center justify-between">
          <CrewLink
            to={`/events/${props.data.id}/${getDefaultTabValue(
              EventDetailTabs
            )}`}
            title={props.data.subject ?? ''}
            className={classNames(
              'line-clamp-2 whitespace-normal',
              !canEditEvent && 'pointer-events-none'
            )}
            color={canViewEvent ? LinkColor.Default : LinkColor.Gray}
          >
            {props.data.subject}
          </CrewLink>
          <CrewBadge displayColor={props.data.eventKind?.displayColor ?? ''}>
            {props.data.eventKind?.name ?? ''}
          </CrewBadge>
        </div>

        {/* project subject */}
        {displayProjectName && props.data.project && (
          <div className="w-full flex items-start">
            <CrewLink
              to={`/projects/${props.data.project.id}/${getDefaultTabValue(
                ProjectListTabs
              )}`}
              title={props.data.project.subject}
              color="gray"
              className="line-clamp-2 text-sm"
            >
              {props.data.project.subject}
            </CrewLink>
          </div>
        )}

        {/* Display event start-endtime */}
        <div className="flex flex-row gap-0.5 font-bold">
          {displayDatetimeDuration}
        </div>

        {props.data.description && (
          <div
            className={classNames(
              'line-clamp-2 whitespace-normal text-left',
              HTMLEDITOR_VIEW_STYLE
            )}
          >
            <CrewHtmlContent html={props.data.description} />
          </div>
        )}

        <div className="flex justify-end">
          <CrewGroupAvatar
            groupAvatar={props.data.attendees}
            //  hidden icon add member
            showAddAvatar={false}
            size={CrewAvatarSize.xs}
          />
        </div>

        <div className="flex place-content-between items-center w-full">
          {canViewEvent && (
            <CrewButton
              text={t('action.detail')}
              type="normal"
              onClick={handleDetailButtonClick}
            />
          )}
          {/* show if user have edit event permission */}
          {canEditEvent && (
            <CrewButton
              text={t('action.edit')}
              type="primary"
              onClick={handleEditEventButtonClick}
            />
          )}
        </div>
      </div>

      {/*
        「編集」ボタンクリック時に表示する編集ダイアログ
          CrewEventPopoverのchildrenとして定義してしまうと、
          編集ダイアログ内でもCrewEventPopoverのonClickイベントが走ってしまうため、
          兄弟コンポーネントの位置に配置している。
      */}
      <EventScheduleEntryDialog
        isOpen={isEventEntryDialogOpen}
        isEditMode={true}
        title={t('label.editSchedule')}
        eventId={props.data.id}
        onSubmit={handleEventUpdated}
        onClose={closeEventEntryDialog}
      />
    </>
  )
})
