import { FC, memo } from 'react'
import { useEditTenantUserEntryForm } from './useEditTenantUserEntryForm'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewSwitchField } from 'components/forms/crewSwitchField'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { DatePickerDateFormat } from 'enums/system'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { useTranslation } from '@crew/modules/i18n'
import { useMemo, useCallback, useState, useEffect } from 'react'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { RoleType, UserType } from '@crew/enums/domain'
import { useToast } from 'hooks/useToast'
import { Roles } from '@crew/enums/app'

import dayjs from '@crew/modules'
import { useRoleDataSource } from 'hooks/dataSource/useRoleDataSource'
import { FormValues } from './useEditTenantUserEntryForm'
export type EditTenantUserEntryFormProps = {
  userId?: string
  version?: number
  displayName?: string
  roleId?: string
  enabled?: boolean
  expirationDatetime: string | null
  userType: UserType | null
  onCancel: () => void
}

export const EditTenantUserEntryForm: FC<EditTenantUserEntryFormProps> = memo(
  (props) => {
    const {
      control,
      formState,
      handleSubmit,
      reset,

      validateRules,

      updateUserRole,
      updateUsersRole,
      isLoadingUpdateUserRole,
      isLoadingUpdateUsersRole,

      selectedUsers,
      tempUserType,
    } = useEditTenantUserEntryForm(props.userType)

    const { t } = useTranslation()
    const [showApiErrors] = useShowApiErrors()

    const { success } = useToast()

    const [isEnabled, setIsEnabled] = useState<boolean>(false)

    // check disable role dropdown when selected users have external user or edit single user is external user
    const isDisabledRoleDropdown = useMemo(() => {
      return tempUserType === UserType.External
    }, [tempUserType])

    // check empty role dropdown when selected users have internal user and external user
    const isEmptyRoleDropdown = useMemo(() => {
      return tempUserType === null
    }, [tempUserType])

    // list role code need exclude in role dropdown
    const excludeRoles = useMemo(() => {
      // ignore role external user when selected users have internal user or edit single user is internal user
      if (tempUserType === UserType.Internal) {
        return [Roles.SysTenantAdmin, Roles.SysExternalUser]
      }

      return [Roles.SysTenantAdmin]
    }, [tempUserType])

    // Set data source for role dropdown
    const roleDataSource = useRoleDataSource(
      RoleType.System,
      excludeRoles,
      // Because can not create new system role, so it's not necessary to get user defined role
      false
    )

    // Form initialization
    const initializeForm = useCallback(async () => {
      if (!props.userId) {
        //Need get roleItem from roleDataSource by role code ('sys_admin')
        const roleItems = await roleDataSource.store().load()
        // Form initialization for update bulk users
        if (Array.isArray(roleItems) && !isEmptyRoleDropdown) {
          let defaultRole
          if (tempUserType === UserType.External) {
            // Need get default role from roles by role code ('sys_external_user')
            defaultRole = roleItems.find(
              (role) => role.roleCode === Roles.SysExternalUser.value
            )
          } else if (tempUserType === UserType.Internal) {
            // Need get default role from roles by role code ('sys_admin')
            defaultRole = roleItems.find(
              (role) => role.roleCode === Roles.SysAdmin.value
            )
          }

          if (defaultRole) {
            reset({
              roleId: defaultRole.id,
              isEnabled: true,
            })
          }
        }
      } else {
        // Form initialization for update single user
        reset({
          roleId: props.roleId,
          expirationDatetime: props.expirationDatetime
            ? dayjs(props.expirationDatetime).toDate()
            : null,
          isEnabled: props.enabled,
        })
      }
    }, [
      props.userId,
      props.roleId,
      props.expirationDatetime,
      props.enabled,
      roleDataSource,
      isEmptyRoleDropdown,
      tempUserType,
      reset,
    ])

    // Initial form
    useEffect(() => {
      initializeForm()
    }, [initializeForm])

    // 登録ボタン押下
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        try {
          if (props.userId && props.version) {
            await updateUserRole(props.userId, data, props.version)
          } else {
            // return if no external user and internal user selected
            if (tempUserType === null) {
              props.onCancel()
              return false
            }

            // ignore pending users
            const updateUsers = selectedUsers.filter((user) => user.userType)

            await updateUsersRole(updateUsers, data)
          }

          success(t('message.tenant.userRoleAndStatusUpdated'))

          props.onCancel()
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      props,
      selectedUsers,
      showApiErrors,
      success,
      t,
      tempUserType,
      updateUserRole,
      updateUsersRole,
    ])

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    // update value of `isEnabled`
    const hanleIsEnabledValueChanged = useCallback((value: boolean) => {
      setIsEnabled(value)
    }, [])

    return (
      <form className="flex flex-col gap-5 h-full">
        <CrewScrollView>
          {/* モーダルの最小幅を制限し、役割、状態など画面の各入力項目が正しく表示されるようにする */}
          <div className="overflow-x-auto">
            <div className="py-2 px-1 min-w-[640px]">
              <div className="flex flex-col gap-y-2.5 py-2 px-1 overflow-y-auto">
                <div className="flex flex-col gap-y-2.5">
                  {props.userId ? (
                    <>
                      <CrewFieldLabel text={t('label.user')} />
                      <p>{props.displayName}</p>
                    </>
                  ) : (
                    <p>{t('message.tenant.changeSelectedUsers')}</p>
                  )}
                </div>
                {/* 役割 */}
                <CrewSelectBoxField
                  id="roleId"
                  control={control}
                  name="roleId"
                  dataSource={isEmptyRoleDropdown ? [] : roleDataSource}
                  valueExpr="id"
                  displayExpr="name"
                  label={t('label.role')}
                  minSearchLength={0}
                  required={true}
                  rules={validateRules.roleId}
                  showClearButton={false}
                  disabled={isDisabledRoleDropdown}
                  className="w-48"
                />

                {/* 有効期限 */}
                <CrewDatePickerField
                  id="expirationDatetime"
                  name="expirationDatetime"
                  control={control}
                  label={t('label.expirationDate')}
                  pickerType="calendar"
                  displayFormat={DatePickerDateFormat.YYYYMMDD}
                  rules={validateRules.expirationDatetime}
                  className="w-48"
                  // Expired date is required when the tenant role type is external
                  showClearButton={tempUserType !== UserType.External}
                  // If tenant role type is external we cant using blackspace to clear date
                  acceptCustomValue={tempUserType !== UserType.External}
                />

                {/* 状態 */}
                <div className="flex flex-col gap-1">
                  <CrewFieldLabel
                    text={t('label.memberState')}
                    required={true}
                  />
                  <div className="flex gap-x-2.5 items-center">
                    <CrewSwitchField
                      id="isEnabled"
                      name="isEnabled"
                      control={control}
                      rules={validateRules.isEnabled}
                      showLabel={false}
                      onValueChanged={({ value }) =>
                        hanleIsEnabledValueChanged(value)
                      }
                    />
                    <p>
                      {isEnabled ? t('label.enabled') : t('label.disabled')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CrewScrollView>
        <div className="flex justify-between items-center">
          <div className="ml-auto flex gap-x-2.5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              disabled={
                !canSend ||
                isEmptyRoleDropdown ||
                isLoadingUpdateUserRole ||
                isLoadingUpdateUsersRole
              }
              onClick={handleSubmitButtonClick}
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    )
  }
)
