var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetUserSettingsQuery } from '@crew/apis/setting/settingApis';
import { setTimezoneGetter } from '@crew/apis/apiBase';
import { SettingKeyType } from '@crew/enums/app';
const initialSliceState = { settings: null };
//**************************************************** slice ***********************************************************/
export const userSettingSlice = createSlice({
    name: 'userSetting',
    initialState: initialSliceState,
    reducers: {
        updateUserSettings: (state, action) => {
            state.settings = action.payload;
        },
        resetUserSettings: (state) => {
            state.settings = null;
        },
    },
});
const { updateUserSettings, resetUserSettings } = userSettingSlice.actions;
export { resetUserSettings };
//**************************************************** custom hooks ***********************************************************/
// Use throughout your app instead of plain `useDispatch` and `useSelector`
const useAppDispatch = () => useDispatch();
const useAppSelector = useSelector;
// user settings request params
const userSettingsRequestParams = {
    keys: [
        SettingKeyType.ListDisplayNumber,
        SettingKeyType.TaskHistoryDisplayOrder,
        SettingKeyType.UserProfileLanguage,
        SettingKeyType.AppearanceTheme,
        SettingKeyType.ChatDisplayFormat,
        SettingKeyType.ChatDisplayRange,
        SettingKeyType.NotificationMention,
        SettingKeyType.NotificationReply,
        SettingKeyType.NotificationMessage,
        SettingKeyType.NotificationReaction,
        SettingKeyType.NotificationMeOnlyWhenOffline,
        SettingKeyType.UserProfileLanguage,
        SettingKeyType.UserProfileRegion,
        SettingKeyType.SecurityAuthenticationType,
        SettingKeyType.SecurityTwoFactorAuthDestination,
        SettingKeyType.SecurityTwoFactorAuthCountryCode,
        SettingKeyType.ShowNewArrival,
        SettingKeyType.ShowMySchedule,
        SettingKeyType.ShowMyTask,
        SettingKeyType.ShowStartUpGuide,
        SettingKeyType.ScheduleInitialDisplay,
    ],
};
/**
 * バックエンドからユーザ設定を取得するhook
 * @returns 読み込みトリガ関数
 */
export const useLoadUserSettings = () => {
    const [lazyGetUserSettingsQuery] = useLazyGetUserSettingsQuery();
    const dispatch = useAppDispatch();
    const loadUserSettings = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const getUserSettings = yield lazyGetUserSettingsQuery(userSettingsRequestParams).unwrap();
            if (getUserSettings) {
                const settings = getUserSettings === null || getUserSettings === void 0 ? void 0 : getUserSettings.userSettings.reduce((userSetting, currentValue) => (Object.assign(Object.assign({}, userSetting), { [currentValue.key]: currentValue.value })), {});
                // API呼び出し時にheaderに格納するTZを設定
                setTimezoneGetter(() => settings[SettingKeyType.UserProfileRegion]);
                // update user settings state
                dispatch(updateUserSettings(settings));
            }
        }
        catch (err) {
            console.log(err);
        }
    }), [dispatch, lazyGetUserSettingsQuery]);
    return [loadUserSettings];
};
/**
 * 指定したユーザ設定を取得するhook
 * @param key
 * @param defaultValue
 * @returns
 */
export const useUserSetting = (key, defaultValue = null) => {
    const userSettings = useAppSelector((state) => { var _a; return (_a = state.userSetting.settings) === null || _a === void 0 ? void 0 : _a[key]; });
    return userSettings !== null && userSettings !== void 0 ? userSettings : defaultValue;
};
