import { EntityType } from '@crew/enums/domain'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import AccountCircleOutline from '~icons/material-symbols/account-circle-outline'
import ChatOutline from '~icons/material-symbols/chat-outline'
import {
  CrewAvatar,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { memo, FC, useCallback, useState, useMemo } from 'react'
import { useUserDetailPanel } from './useUserDetailPanel'
import { LoadIndicator } from 'devextreme-react'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import { GetUserRequest } from '@crew/apis/user/models/getUser/request'
import { useGetUserQuery } from '@crew/apis/user/userApis'
import { useTranslation } from '@crew/modules/i18n'
import { useAppSelector } from 'states/hooks'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import {
  CrewUserPresence,
  UserPresenceState,
} from 'components/elements/crewUserPresence/crewUserPresence'
import { CrewPresenceStateIconSize } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'
import { usePresenceState } from '@crew/states'
export const UserDetailPanel: FC = memo(() => {
  const { createDirectChannel } = useUserDetailPanel()

  const { t } = useTranslation()
  const { userId } = useParams()
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const [showApiErrors] = useShowApiErrors()
  const { navigate } = useCrewNavigate()

  // ユーザー詳細を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getUserParam: GetUserRequest | undefined = userId
    ? {
        userId,
      }
    : undefined
  const {
    data: getUserResult,
    isLoading: isLoadingUserDetail,
    isError: isErrorUserDetail,
  } = useGetUserQuery(getUserParam ?? skipToken)

  // ユーザー在席状態
  const [userPresenceState, setUserPresenceState] =
    useState<UserPresenceState>()

  // 在席状態IDから在席状態を取得
  const presenceState = usePresenceState(userPresenceState?.presenceStateId)

  // handle presence state change
  const handleUserPresenceStateChange = useCallback(
    (userPresence: UserPresenceState) => {
      setUserPresenceState(userPresence)
    },
    []
  )

  // text presence state
  const textPresenceState = useMemo(() => {
    // 在席状態名（取得できない場合は「不明」を表示）
    const presenceStateName = presenceState?.name ?? t('label.unknown')

    return !userPresenceState?.presenceStateMessage
      ? presenceStateName // メッセージがない場合：在席状態のみ表示
      : `${presenceStateName} / ${userPresenceState.presenceStateMessage}` // メッセージがある場合：「在席状態 / メッセージ」の形式で表示
  }, [presenceState?.name, t, userPresenceState?.presenceStateMessage])

  // Event when click send direct message link
  const handleCreateDirectChannel = useCallback(async () => {
    if (userId) {
      try {
        const response = await createDirectChannel(userId)

        // Go to direct channel page
        navigate(`/direct-channels/${response.directChannel?.id}/chat`)
      } catch (err) {
        showApiErrors(err)
      }
    }
  }, [createDirectChannel, navigate, showApiErrors, userId])

  if (isErrorUserDetail) {
    return null
  }

  if (isLoadingUserDetail) {
    return <LoadIndicator height="1em" width="1em" />
  }

  return (
    <div className="p-2">
      {/* ページタイトル */}
      <div className="flex gap-2 items-center h-8 text-crew-gray-3-light dark:text-crew-gray-2-dark">
        <AccountCircleOutline width={24} height={24} />
        <p className="font-bold">{t('label.user')}</p>
      </div>
      <div className="flex flex-col gap-2 justify-between items-center">
        {getUserResult?.user &&
          (getUserResult.user.enabled ? (
            <>
              <CrewAvatar
                displayName={getUserResult.user.displayName}
                size={CrewAvatarSize['2xl']}
                imageURL={generateImageAvatarUrl(
                  EntityType.User,
                  getUserResult.user.id
                )}
                cacheValue={getUserResult.user.id + getUserResult.user.version}
              />

              {/* 表示名 */}
              <p className="text-xl">{getUserResult.user.displayName}</p>

              {/* 別名 */}
              <p className="crew-text-gray-4">{getUserResult.user.alias}</p>

              {/* 検索キー */}
              <p>{getUserResult.user.searchKey}</p>

              {/* crew user presence */}
              <div className="flex flex-row items-center gap-1">
                <CrewUserPresence
                  userId={getUserResult.user.id}
                  onChange={handleUserPresenceStateChange}
                  size={CrewPresenceStateIconSize.base}
                />
                <p>{textPresenceState}</p>
              </div>

              {/* 状態 */}
              {/* 今回はスコープ外 */}
              {/* https://www.figma.com/file/nwEm5niOyafuDkr3UT3q0e?node-id=48486:24024&mode=dev#512461548 */}
              {/* <p>気軽に声かけてください</p> */}

              {/*
                ユーザー詳細画面はダイレクトメッセージ未登録時のみ表示するため、
                ダイレクトメッセージ送信テキストリンクは常に表示する
              */}
              {/*
                TODO: 現在、自分ひとりのダイレクトチャンネルを作成する機能は対応されていない。
                      この機能はCREW-11451で実装されるため、その際は以下のif条件を除外する。
                      https://break-tmc.atlassian.net/browse/CREW-11451
              */}
              {loggedInUser?.id !== userId && (
                <div
                  className="flex gap-1 crew-link"
                  onClick={handleCreateDirectChannel}
                >
                  <ChatOutline width={24} height={24} />
                  <p>{t('label.sendDirectMessage')}</p>
                </div>
              )}
            </>
          ) : (
            // 状態が無効の場合
            <p>{t('message.user.userIsInvalid')}</p>
          ))}
      </div>
    </div>
  )
})
