import { FC, memo, useCallback } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { useTranslation } from '@crew/modules/i18n'
import { WebMeetingJoinForm } from 'features/webMeeting/components/webMeetingJoinDialog/components/webMeetingJoinForm/webMeetingJoinForm'
import { useWebMeetingJoinDialog } from './useWebMeetingJoinDialog'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import { EndMeetingActionType } from '@crew/enums/domain'

export type WebMeetingJoinDialogProps = {
  eventId: string
  isOpen: boolean
  onClose: () => void
}

export const WebMeetingJoinDialog: FC<WebMeetingJoinDialogProps> = memo(
  (props) => {
    const { endMeeting } = useWebMeetingJoinDialog()
    const { t } = useTranslation()
    const audioVideo = useAudioVideo()

    const [showApiErrors] = useShowApiErrors()
    const meetingManager = useMeetingManager()

    // ミーティング終了処理
    const handleEndMeeting = useCallback(async () => {
      if (meetingManager.meetingId && audioVideo) {
        try {
          // 会議終了API内で開催者の場合に終了、開催者以外の場合は会議から退出の処理を行う
          await endMeeting(
            meetingManager.meetingId,
            EndMeetingActionType.LeaveMeeting
          )

          await meetingManager.leave()
        } catch (err) {
          showApiErrors(err)
        }
      }
      props.onClose()
    }, [audioVideo, endMeeting, meetingManager, props, showApiErrors])

    return (
      <Modal
        title={t('label.startWebMeeting')}
        isOpen={props.isOpen}
        onClose={() => {
          // Webミーティング終了処理を実行
          handleEndMeeting()
        }}
      >
        <div className="flex flex-col gap-y-5 h-full">
          <WebMeetingJoinForm
            eventId={props.eventId}
            onClose={props.onClose}
            onCancelMeeting={handleEndMeeting}
          />
        </div>
      </Modal>
    )
  }
)
