import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { FC, memo, useCallback, useId } from 'react'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { getDeviceId } from 'modules/amazon-chime-sdk-component-library-devextreme/utils/device-utils'
import { useCrewSpeakerTestButton } from './useCrewSpeakerTestButton'
import TestSound from 'assets/audio/speakerTest.mp3'

//スピーカーのテストボタン
export const CrewSpeakerTestButton: FC = memo(() => {
  const { checkAudioOutput } = useCrewSpeakerTestButton()
  const { t } = useTranslation()

  const speakerTestId = useId() // Generates unique ID per instance

  const meetingManager = useMeetingManager()

  const handleTestButtonClick = useCallback(async () => {
    const deviceId = await getDeviceId(meetingManager.selectedAudioOutputDevice)

    // 5秒間ブザー音を慣らす
    await checkAudioOutput(deviceId, speakerTestId)
  }, [
    checkAudioOutput,
    meetingManager.selectedAudioOutputDevice,
    speakerTestId,
  ])

  return (
    <>
      <CrewButton
        stylingMode="outlined"
        text={t('label.test')}
        onClick={handleTestButtonClick}
      />

      <audio id={speakerTestId} className="hidden" src={TestSound} />
    </>
  )
})
