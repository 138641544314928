import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { DateFormat, JsonDateFormat } from '@crew/enums/system'
import dayjs from '@crew/modules'
import { useTranslation } from '@crew/modules/i18n'
import { ValidateRules } from '@crew/utils/form'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import { DisabledDate } from 'devextreme/ui/date_box'
import { useSystemPermissions } from '@crew/hooks'
import { InvitationRoleType, ContractPlan } from '@crew/enums/app'
import {
  NewUserType,
  RoleSelectType,
} from 'features/project/components/projectMemberEntryDialog/useProjectMemberEntryDialog'
import { useVerifyProjectMembersPendingMutation } from '@crew/apis/project/projectApis'
import { useAppSelector } from 'states/hooks'
import { trimSpace } from '@crew/utils'
export type UserTagBoxType = {
  value: string
}

export type FormValues = {
  tenantRoleType: string
  projectRoleId: string | null
  newUsers: string[]
  expirationDatetime: Date | null
}

const formInitialValues: FormValues = {
  tenantRoleType: InvitationRoleType.Internal.key,
  projectRoleId: null,
  newUsers: [],
  expirationDatetime: null,
}

export const useInviteNewUsersToolbar = () => {
  const { t } = useTranslation()
  const currentPlan = useAppSelector((state) => state.app.currentPlan)
  const {
    hasSysExternalUserInvitePermission,
    hasSysInternalUserInvitePermission,
  } = useSystemPermissions()

  const [
    verifyProjectMembersPendingMutation,
    { isLoading: isLoadingVerifyProjectMembersPending },
  ] = useVerifyProjectMembersPendingMutation()

  // react-hook-formの各種データを取得
  const {
    control,
    handleSubmit,
    reset,
    formState,
    getValues,
    clearErrors,
    trigger,
    setValue,
    setError,
  } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // set data source for tenant role dropdown
  const tenantRoleDataSource = useMemo(
    () =>
      Object.values(InvitationRoleType)

        .map((roleType) => {
          return {
            id: roleType.key,
            name: t(roleType.label),
          }
        })
        // ignore external role when subscription plan is free and Admin role is not allowed to invite
        .filter((roleType) => {
          if (
            roleType.id === InvitationRoleType.Admin.key ||
            (currentPlan === ContractPlan.Free &&
              roleType.id === InvitationRoleType.External.key)
          ) {
            return false
          }
          return true
        }),
    [currentPlan, t]
  )

  //set data source for new users tagbox
  const newUserDataSource = useDataSource(
    () => ({
      key: 'value',
      load: async () => {
        //User tagbox always add new user
        //Initial no need get data from API
        //Just return empty array
        return []
      },
      insert: async (value: UserTagBoxType) => {
        return value
      },
    }),
    []
  )

  // 日付の必須チェック
  // 外部ユーザーの場合は必須入力にする
  const validateRequiredDate = useCallback(() => {
    const tenantRoleType = getValues('tenantRoleType')
    const expirationDatetime = getValues('expirationDatetime')
    let isValid = true

    // If tenant role type is external, expiration date time is required
    if (tenantRoleType === InvitationRoleType.External.key) {
      if (!expirationDatetime) {
        isValid = false
      }
    }

    if (isValid) {
      clearErrors('expirationDatetime')
    }
    return isValid
  }, [clearErrors, getValues])

  // Check expiration date time must be greater than or equal to the current date
  const validateDate = useCallback(
    (value: Date | null) => {
      const expirationDatetime = dayjs(value).format(JsonDateFormat.YYYYMMDD)
      const currentDatetime = dayjs(new Date()).format(JsonDateFormat.YYYYMMDD)
      if (!value || currentDatetime <= expirationDatetime) {
        clearErrors('expirationDatetime')
        return true
      }
      return false
    },
    [clearErrors]
  )

  //check is date when user enter in input datepicker
  const validateIsDate = useCallback((value: Date | null) => {
    //when date enter invalid date, datepicker response undefined
    if (typeof value === 'undefined') {
      return false
    }

    return true
  }, [])

  // validate user has permission invite internal user or external user
  const validateInviteInternalOrExternalUser = useCallback(() => {
    let isValid = true
    const tenantRoleType = getValues('tenantRoleType')

    // If tenant role type is internal and user has no permission to invite internal user, return false
    if (
      tenantRoleType === InvitationRoleType.Internal.key &&
      !hasSysInternalUserInvitePermission
    ) {
      isValid = false
    }

    // If tenant role type is external and user has no permission to invite external user, return false
    if (
      tenantRoleType === InvitationRoleType.External.key &&
      !hasSysExternalUserInvitePermission
    ) {
      isValid = false
    }

    return isValid
  }, [
    getValues,
    hasSysExternalUserInvitePermission,
    hasSysInternalUserInvitePermission,
  ])

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      newUsers: {
        required: t('message.general.required'),
        validate: {
          always: () => {
            trigger('expirationDatetime') //trigger validate expiration date when new users change
            return true
          },
          validateInvitelUser: () =>
            validateInviteInternalOrExternalUser() ||
            t('message.project.noInviteUserPermission'),
        },
      },
      tenantRoleType: {
        required: t('message.general.required'),
      },
      projectRoleId: {
        required: t('message.general.required'),
      },
      expirationDatetime: {
        validate: {
          always: () => validateRequiredDate() || t('message.general.required'),
          checkDate: () =>
            validateIsDate(getValues('expirationDatetime')) ||
            t('message.general.invalidDate'),
          validateDate: () =>
            validateDate(getValues('expirationDatetime')) ||
            t('message.project.expirationDateMustBeCorrect'),
        },
      },
    }),
    [
      getValues,
      t,
      trigger,
      validateDate,
      validateInviteInternalOrExternalUser,
      validateIsDate,
      validateRequiredDate,
    ]
  )

  // 「現在日以降」しか指定できないようバリデーションを追加する。
  const isDisableDates = useCallback(({ date }: DisabledDate) => {
    return (
      dayjs(new Date()).format(JsonDateFormat.YYYYMMDD) >
      dayjs(date).format(JsonDateFormat.YYYYMMDD)
    )
  }, [])

  // Verify member list before submit
  const verifyProjectMembersPending = useCallback(
    async (tenantRoleType: string, destinations: string[]) => {
      const emailOrLoginIds = destinations.map((s) => trimSpace(s))

      const result = await verifyProjectMembersPendingMutation({
        tenantRoleType,
        destinations: emailOrLoginIds,
      }).unwrap()

      return result
    },
    [verifyProjectMembersPendingMutation]
  )

  // Add new users to selected new users
  const addUsers = useCallback(
    async (
      data: FormValues,
      role: RoleSelectType,
      selectedNewUsers: NewUserType[]
    ) => {
      //map form values to new user type
      const payload: NewUserType[] = data.newUsers.map((user) => {
        let expirationDatetime: string | null = data.expirationDatetime
          ? dayjs(data.expirationDatetime).format(DateFormat.YYYYMMDD)
          : null

        //trim space in email or login id
        const emailOrLoginID = trimSpace(user)

        return {
          emailOrLoginID,
          expirationDatetime,
          projectRole: role,
          tenantRoleType: data.tenantRoleType,
        }
      })

      //replace same users in selected new users
      const filteredSelectedNewUsers = selectedNewUsers.filter((newUser) => {
        return !payload.some(
          (user) => user.emailOrLoginID === newUser.emailOrLoginID
        )
      })

      return [...filteredSelectedNewUsers, ...payload]
    },
    []
  )

  return {
    control,
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
    clearErrors,
    setError,

    tenantRoleDataSource,
    newUserDataSource,

    validateRules,
    isDisableDates,

    addUsers,
    verifyProjectMembersPending,
    isLoadingVerifyProjectMembersPending,
  }
}
