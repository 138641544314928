import { useTranslation } from '@crew/modules/i18n'
import { FC, memo } from 'react'

type DateCellProps = {
  date: Date
}

export const DateCell: FC<DateCellProps> = memo((props) => {
  const { t } = useTranslation()
  return (
    <div className={'w-full h-full flex items-center justify-center'}>
      {t('format.dayOfWeekShort', {
        value: props.date,
      })}
    </div>
  )
})
