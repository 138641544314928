import { EntityType, UnreadInfoTarget } from '@crew/enums/domain'
import { ActiveChatRoomInfo } from '@crew/states'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import classNames from 'classnames'
import {
  CrewAvatar,
  CrewAvatarShapeType,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewChatRoomUnreadBadge } from 'components/elements/crewChatRoomUnreadBadge/crewChatRoomUnreadBadge'
import { ProjectScope } from 'enums/app'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { LeftSideBarContextMenu } from '../../../../../leftSideBarContextMenu/leftSideBarContextMenu'

export type FavoriteProjectItemProps = {
  chatRoom: ActiveChatRoomInfo
}

// メニューの基本スタイル
const baseClassNames =
  'flex flex-row h-full w-full items-center p-2 gap-2 hover:font-bold [&:has(.has-unread)]:font-bold group-hover:font-bold'

// 選択メニューのアクティブ状態スタイル（ハイライト）
const activeClassNames = 'font-bold crew-text-gray-1 crew-bg-gray-2'

export const FavoriteProjectItem: FC<FavoriteProjectItemProps> = memo(
  (props) => {
    const location = useLocation()

    const [hover, setHover] = useState(false)

    // ホバーメニューを表示
    const handleMouseEnter = useCallback(() => {
      setHover(true)
    }, [])

    // ホバーメニューを非表示
    const handleMouseLeave = useCallback(() => {
      setHover(false)
    }, [])

    // URLの変更に応じてハイライトさせるかどうかをハンドリングする
    const isActive = useMemo(
      () =>
        // eg: location.pathname = /direct-channels/{projectId}/xxx
        // projectIdのみで判定する。projectIdで一意に識別出来るので、他の条件は不要
        location.pathname.split('/')[2] === props.chatRoom.projectId,
      [location, props.chatRoom.projectId]
    )

    return (
      <li
        key={props.chatRoom.projectId}
        className="flex flex-row group relative justify-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NavLink
          to={`/projects/${props.chatRoom.projectId}/chat`}
          className={classNames(baseClassNames, isActive && activeClassNames)}
        >
          <div className="flex">
            <div className="flex w-full gap-2 items-center">
              <CrewAvatar
                displayName={props.chatRoom.name}
                size={CrewAvatarSize.xs}
                shape={CrewAvatarShapeType.Square}
                imageURL={generateImageAvatarUrl(
                  EntityType.Project,
                  props.chatRoom.projectId
                )}
                isPrivate={props.chatRoom.scope === ProjectScope.Private.key}
                cacheValue={
                  props.chatRoom.projectId + props.chatRoom.projectVersion
                }
              />
              {/* 選択中 または 未読ありの場合、太字で表示 */}
              <span className="line-clamp-2 break-all">
                {props.chatRoom.name}
              </span>
              <CrewChatRoomUnreadBadge chatRoomId={props.chatRoom.chatRoomId} />
            </div>

            <div className="flex w-4 items-center">
              <LeftSideBarContextMenu
                target={UnreadInfoTarget.ChatRoom}
                chatRoomId={props.chatRoom.chatRoomId}
                projectId={props.chatRoom.projectId}
                hover={hover}
                isActive={isActive}
                isFavorite={props.chatRoom.isFavorite}
                enableMarkAllRead={false} // 「プロジェクト」では「すべて既読にする」は非表示
              />
            </div>
          </div>
        </NavLink>
      </li>
    )
  }
)
