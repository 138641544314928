import { FC, FormEventHandler, memo, useCallback, useState } from 'react'
import { useChangeMailForm } from './useChangeMailForm'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'
import { FormValues } from './useChangeMailForm'
import { trimSpace } from '@crew/utils'

export type ChangeMailFormProps = {
  onCloseChangeMailForm: () => void
}

type ChangeMailStep = 'input' | 'verify'

export const ChangeMailForm: FC<ChangeMailFormProps> = memo((props) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    validateRules,
    sendConfirmationEmail,
    updateEmailAddress,
    isLoadingSendConfirmEmailToChangeEmail,
    isLoadingUpdateEmail,
  } = useChangeMailForm()

  const { t } = useTranslation()
  const { success } = useToast()

  const [changeMailStep, setChangeMailStep] = useState<ChangeMailStep>('input')
  const [showApiErrors] = useShowApiErrors()

  // Event handle when the Save button is clicked
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      // handle submit email
      if (changeMailStep === 'input') {
        try {
          // Execute send confirmation email process
          await sendConfirmationEmail(trimSpace(data.emailAddress))

          // go step verify code from email
          setChangeMailStep('verify')
        } catch (error) {
          showApiErrors(error)
        }
      } else {
        // handle submit verify code
        try {
          // Execute update email address process
          await updateEmailAddress(data)

          // Display a toast indicating that the email address update was successful
          success(t('message.personalSetting.updateEmailSuccess'))

          // reset form values
          reset()

          //close change email form
          props.onCloseChangeMailForm()
        } catch (error) {
          showApiErrors(error)
        }
      }
    }

    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    changeMailStep,
    sendConfirmationEmail,
    showApiErrors,
    updateEmailAddress,
    success,
    t,
    reset,
    props,
  ])

  // Form内Enterキー押下によるSubmit制御
  // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
  //       https://break-tmc.atlassian.net/browse/CREW-3338
  const handleFormSubmit = useCallback<FormEventHandler>((event) => {
    event.preventDefault()
  }, [])

  return (
    <div className="mt-2.5">
      <form
        // Form内Enterキー押下によるSubmit制御
        // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
        // https://break-tmc.atlassian.net/browse/CREW-3338
        onSubmit={handleFormSubmit}
      >
        {changeMailStep === 'input' && (
          <>
            {/* 新しいメールアドレス */}
            <CrewTextBoxField
              id="emailAddress"
              control={control}
              name="emailAddress"
              label={t('label.newEmailAddress')}
              showClearButton={false}
              className="w-80"
              rules={validateRules.emailAddress}
            />

            <p className="mt-2.5 text-crew-gray-3-light dark:text-crew-gray-text">
              {t('message.personalSetting.sendVerificationCode')}
            </p>

            {/* 送信する */}
            <CrewButton
              type="primary"
              text={t('action.sendVerifyCode')}
              className="mr-auto mt-2.5"
              onClick={handleSubmitButtonClick}
              disabled={isLoadingSendConfirmEmailToChangeEmail}
            />
          </>
        )}

        {changeMailStep === 'verify' && (
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <p className="text-crew-gray-3-light dark:text-crew-gray-text">
                {t('label.newEmailAddress')}
              </p>
              <p>{getValues('emailAddress')}</p>
            </div>
            <p className="text-crew-gray-3-light dark:text-crew-gray-text">
              {t('message.personalSetting.enterVerificationCode')}
            </p>

            <div className="flex gap-x-2.5 items-end">
              {/* 認証コード */}
              <CrewTextBoxField
                id="verificationCode"
                control={control}
                name="verificationCode"
                label={t('label.verificationCode')}
                className="w-52"
                showClearButton={false}
                mode="password"
                rules={validateRules.verificationCode}
              />

              {/* 確認する */}
              <CrewButton
                type="primary"
                text={t('action.confirm')}
                className="mr-auto mt-2.5"
                onClick={handleSubmitButtonClick}
                disabled={isLoadingUpdateEmail}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  )
})
