import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { FC, memo, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useAppSelector } from 'states/hooks'

import { FormValues, usePresenceSettingForm } from './usePresenceSettingForm'
import { useTranslation } from '@crew/modules/i18n'
import { PresenceStateType } from '@crew/enums/domain'
import { GetUserStateRequest } from '@crew/apis/user/models/getUserState/request'
import { useGetUserStateQuery } from '@crew/apis/user/userApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { useValueChangeEffect } from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewRadioGroupField } from 'components/forms/crewRadioGroupField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewPresenceStateIcon } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'

// render content
// renderとして使うのでmemo不可
const PresenceRadioItem: FC<{
  presenceStateType: PresenceStateType
  name: string
}> = (props) => (
  <div className="flex flex-row items-center gap-1.5 my-2">
    <CrewPresenceStateIcon presenceStateType={props.presenceStateType} />

    <div>{props.name}</div>
  </div>
)

type PresenceSettingFormProps = {
  onClose: () => void
}

export const PresenceSettingForm: FC<PresenceSettingFormProps> = memo(
  (props) => {
    const { t } = useTranslation()
    const [showApiErrors] = useShowApiErrors()
    const {
      control,
      reset,
      handleSubmit,
      updateUserPresenceState,
      isLoadingUpdateUserPresenceState,
      validateRules,
    } = usePresenceSettingForm()

    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

    // get user presence state
    const paramsUserState: GetUserStateRequest | undefined = loggedInUser?.id
      ? {
          userId: loggedInUser.id,
        }
      : undefined

    const { data: dataUserPresenceState } = useGetUserStateQuery(
      paramsUserState ?? skipToken
    )

    // initialize form values
    useValueChangeEffect(
      () => {
        reset({
          presenceStateId: dataUserPresenceState?.userState.presenceStateId,
          presenceStateMessage:
            dataUserPresenceState?.userState.presenceStateMessage,
        })
      },
      [
        dataUserPresenceState?.userState.presenceStateId,
        dataUserPresenceState?.userState.presenceStateMessage,
        reset,
      ],
      dataUserPresenceState?.userState.presenceStateId
    )

    const presenceStates = useAppSelector(
      (state) => state.presenceStates.presenceStates
    )

    const presenceStateData = useMemo(
      () =>
        Object.values(presenceStates).map((presenceState) => ({
          id: presenceState.id,
          name: presenceState.name,
          presenceStateType: presenceState.presenceStateType,
        })),
      [presenceStates]
    )

    // handle submit button press
    const handleSubmitButtonPress = async () => {
      const onSubmitForm: SubmitHandler<FormValues> = async (values) => {
        try {
          await updateUserPresenceState(values)

          props.onClose()
        } catch (err) {
          // サーバーエラーの場合はトースト表示
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmitForm)()
    }

    return (
      <form className="flex flex-col h-full w-full overflow-hidden justify-between">
        <div className="flex-shrink px-2.5 overflow-auto">
          {/* presence statuses */}
          <CrewRadioGroupField
            id="presenceStateId"
            control={control}
            name="presenceStateId"
            dataSource={presenceStateData}
            valueExpr="id"
            displayExpr="name"
            layout="vertical"
            itemRender={PresenceRadioItem}
            rules={validateRules.presenceStateId}
            showLabel={false}
          />
        </div>
        <div className="px-2.5">
          {/* メッセージ */}
          <CrewTextBoxField
            id="presenceStateMessage"
            control={control}
            name="presenceStateMessage"
            label={t('label.message')}
            rules={validateRules.presenceStateMessage}
          />
        </div>
        <div className="flex gap-x-5 justify-end pt-2.5">
          {/* 追加 */}
          <CrewButton
            onClick={handleSubmitButtonPress}
            type="primary"
            text={t('action.register')}
            disabled={isLoadingUpdateUserPresenceState}
          />

          {/* キャンセル */}
          <CrewButton
            onClick={props.onClose}
            type="normal"
            text={t('action.cancel')}
          />
        </div>
      </form>
    )
  }
)
