import { useTranslation } from '@crew/modules/i18n'
import { memo, useMemo } from 'react'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { useAppSelector } from 'states/hooks'

export const SignupError = memo(() => {
  const { t } = useTranslation()
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )
  return (
    <div className="flex flex-col items-center h-[100dvh] pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto mb-8" />
      <p className="text-center text-2xl">{t('label.signupErrorInvalid')}</p>
      <p className="text-center text-2xl">{t('label.signupErrorExpired')}</p>
    </div>
  )
})
