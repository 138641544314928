import { GanttTask } from '@crew/apis/task/models/getGanttTasks/response'
import { JsonDateFormat } from '@crew/enums/system'
import { EntityType } from '@crew/enums/domain'
import dayjs from '@crew/modules'
import { useTranslation } from '@crew/modules/i18n'
import { getDefaultTabValue } from '@crew/utils/enum'
import { useEffectOnce, useFullscreen, useToggle } from '@dx-system/react-use'
import EventAvailable from '~icons/material-symbols/event-available'
import AccountTreeOutline from '~icons/material-symbols/account-tree-outline'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineEvent from '~icons/ic/baseline-event'
import ViewAgendaOutlineSharp from '~icons/material-symbols/view-agenda-outline-sharp'
import classNames from 'classnames'
import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { CrewUserItem } from 'components/elements/crewUserItem/crewUserItem'
import { useModal } from 'components/layouts/modal/useModal'
import {
  Column,
  ContextMenu,
  Dependencies,
  Editing,
  Gantt,
  HeaderFilter,
  IContextMenuProps,
  Item,
  Sorting,
  Tasks,
  Toolbar,
  Validation,
} from 'devextreme-react/gantt'
import {
  CustomCommandEvent,
  GanttScaleType,
  ScaleCellPreparedEvent,
  SelectionChangedEvent,
  TaskDeletingEvent,
  TaskEditDialogShowingEvent,
  TaskInsertingEvent,
  TaskUpdatingEvent,
} from 'devextreme/ui/gantt'
import { NotifyEventType, TaskDetailListTabs } from 'enums/app'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { useTaskDependencyDataSource } from 'hooks/dataSource/useTaskDependencyDataSource'
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useProjectDetailTaskListGantt } from './useProjectDetailTaskListGantt'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import {
  adjustTimeAndFormatEndDate,
  adjustTimeAndFormatStartDate,
} from 'utils/date'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { useValueChangeEffect } from '@crew/hooks'
import { useAppSelector } from 'states/hooks'
import { convertMinutesToHHMM } from '@crew/utils'
import { cloneDeep } from 'lodash'
import { compareWBSNumber } from 'utils'
import { CrewGantt } from 'components/devextreme/crewGantt'

const ScaleTypes: GanttScaleType[] = [
  'days',
  'weeks',
  'months',
  'quarters',
  'years',
] as const

// default taskListWidth is 500px display subject, startDate, dueDate
const DEFAULT_TASK_LIST_WIDTH = 500

// default detail task list width is 1330px display all columns
const DETAIL_TASK_LIST_WIDTH = 1330

// ratio of the width of the list with the detail column to the width of the gantt chart
const TASK_LIST_WIDTH_WITH_DETAIL_COLUMN_RATIO = 0.8

// any type is used because the type params of cellRender is any
const dateFormatter = (date: any): React.ReactNode => {
  // check date.value is Invalid Date
  if (!date.value || isNaN(date.value)) {
    return ''
  }

  return dayjs(date.value).format('YYYY/MM/DD')
}

// render assignToUser
const renderAssignToUser = ({ data }: { data: GanttTask }): React.ReactNode => {
  if (!data?.assignToUser) return null

  return (
    <CrewUserItem
      id={data.assignToUser.id}
      displayName={data.assignToUser.displayName}
      version={data.assignToUser.version}
      avatarSize={CrewAvatarSize['3xs']}
    />
  )
}

// render progress rate
const renderProgressRate = ({
  value,
}: {
  value: number | null
}): React.ReactNode => {
  if (!value) return ''

  return `${value}%`
}

// render task state
const renderTaskState = ({ data }: { data: GanttTask }): React.ReactNode => {
  return (
    <div className="w-full text-center">
      <CrewBadge displayColor={data.taskState.displayColor}>
        {data.taskState.name}
      </CrewBadge>
    </div>
  )
}

// calculate assignToUser cell value
const calculateAssignToUserCellValue = (rowData: GanttTask) => {
  return rowData.assignToUser?.displayName ?? ''
}

// calculate task state cell value
const calculateTaskStateCellValue = (rowData: GanttTask) => {
  return rowData.taskState?.name ?? ''
}

// calculate estimatedWorkTimes cell value
const calculateEstimatedWorkTimesCellValue = (rowData: GanttTask) => {
  return typeof rowData.estimatedWorkTimes === 'number'
    ? convertMinutesToHHMM(rowData.estimatedWorkTimes)
    : ''
}

// calculate actualWorkTimes cell value
const calculateActualWorkTimesCellValue = (rowData: GanttTask) => {
  return typeof rowData.actualWorkTimes === 'number'
    ? convertMinutesToHHMM(rowData.actualWorkTimes)
    : ''
}

export const ProjectDetailTaskListGantt: FC = memo(() => {
  const { projectId } = useParams()
  const {
    taskDataSource,
    updateGanttTask,
    deleteTask,
    findRelatedTasks,
    updateRelatedTasksByTargetTask,
    hasChildTask,
  } = useProjectDetailTaskListGantt()

  const { t } = useTranslation()
  const [showApiErrors] = useShowApiErrors()
  const GanttRef = useRef<Gantt>(null)
  const navigate = useNavigate()
  const [isTaskEntryDialogOpen, openTaskEntryDialog, closeTaskEntryDialog] =
    useModal()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()
  const [confirmMessage, setConfirmMessage] = useState('')

  const [taskId, setTaskId] = useState<string>()
  const [parentTaskId, setParentTaskId] = useState<string>()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isViewDetailColumn, setIsViewDetailColumn] = useState(false)

  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  const taskDependencyDataSource = useTaskDependencyDataSource(
    EntityType.Project,
    projectId ?? '',
    undefined
  )

  const [selectedRow, setSelectedRow] = useState<string>()

  const tasks = useRef<GanttTask[]>([])

  useValueChangeEffect(
    () => {
      taskDataSource
        .store()
        .load()
        .then((items) => (tasks.current = items as GanttTask[]))
    },
    [taskDataSource],
    taskDataSource
  )

  // ガントチャートが表示される場合、URLパラメータが除去される
  useEffectOnce(() => {
    navigate(`?`)

    // scaleTypeを指定しない場合、scaleType = 'auto' となり、タスク開始日とタスク終了日が同一の場合、ganttの仕様から最小単位がhoursの表示になってしまう
    // また、これを防ぐためにコンポーネントにscaleType = 'days' を指定するとズームが日単位の中で繰り返されてしまうので、refから指定する
    GanttRef.current?.instance.option('scaleType', 'days')
  })

  // タスク新規作成時にダイアログを経由させる場合
  const handleTaskInsertDialogShowing = useCallback(
    (event: TaskInsertingEvent) => {
      // 「insert対象」マークがある場合、そのまま処理する
      if (event.values.inserting) {
        return
      }
      // デフォルト値でのタスク生成をキャンセルする
      event.cancel = true

      setTaskId(undefined)
      setParentTaskId(event.values.parentTaskId)
      setIsEditMode(false)

      openTaskEntryDialog()
    },
    [openTaskEntryDialog]
  )

  // タスク編集ダイアログをオリジナルのものに置換する場合
  const handleTaskEditDialogShowing = useCallback(
    (event: TaskEditDialogShowingEvent) => {
      // 標準ダイアログをキャンセルする
      event.cancel = true

      setTaskId(event.key)
      setParentTaskId(event.values.parentTaskId)
      setIsEditMode(true)

      // 独自のダイアログを開く
      openTaskEntryDialog()
    },
    [openTaskEntryDialog]
  )

  // タスク登録・編集ダイアログのsubmit処理
  const handleTaskEntryDialogSubmit = useCallback(
    async (taskId: string) => {
      closeTaskEntryDialog()
    },
    [closeTaskEntryDialog]
  )

  // タスクの登録・更新・削除のイベントが発生した際に、タスク一覧を再読み込みするための処理
  useValueChangeEffect(
    () => {
      if (!taskEventMessage) return

      if (taskEventMessage?.eventType === NotifyEventType.Inserted) {
        const targetTask = cloneDeep(taskEventMessage.object) as GanttTask

        const selectedRowKey =
          GanttRef.current?.instance.option('selectedRowKey')
        // find the index of the selected row
        const selectedRowIndex = taskDataSource
          .items()
          .findIndex((task) => task.id === selectedRowKey)

        // get the index of the focused row
        // when target task is not the child of the focused row, add new task after the focused row
        const targetTaskIndex =
          selectedRowIndex !== -1 && targetTask.parentTaskId !== selectedRowKey
            ? selectedRowIndex + 1
            : taskDataSource.items().length + 1

        tasks.current = [...tasks.current, targetTask]

        // Find all related tasks
        const relatedTasks = findRelatedTasks(
          taskEventMessage.id,
          tasks.current
        )

        // update related tasks and target task
        const updatedTasks = updateRelatedTasksByTargetTask(
          relatedTasks,
          targetTask,
          'insert'
        )

        tasks.current = tasks.current.map((task) => {
          const updatedTask = updatedTasks.find(
            (updatedTask) => updatedTask.id === task.id
          )
          return updatedTask ?? task
        })

        // Update the task in the data source
        taskDataSource.store().push(
          updatedTasks.map((task) => ({
            type: task.id === targetTask.id ? 'insert' : 'update',
            key: task.id,
            data: {
              ...task,
              startDate: task.startDate
                ? dayjs(task.startDate).startOf('day').toDate()
                : undefined, // convert startDate to begin of day
              dueDate: task.dueDate
                ? dayjs(task.dueDate).endOf('day').toDate()
                : undefined, // convert endDate to end of day
              actualStartDate: task.actualStartDate
                ? dayjs(task.actualStartDate).toDate()
                : undefined,
              actualEndDate: task.actualEndDate
                ? dayjs(task.actualEndDate).toDate()
                : undefined,
              actualProgress: task.actualProgress ?? 0,
              version: task.version + 1, // Increment the version
            },
            // push new task after the selected row
            index: task.id === targetTask.id ? targetTaskIndex : undefined,
          }))
        )
      } else if (taskEventMessage.eventType === NotifyEventType.Updated) {
        // Find all related tasks
        const relatedTasks = findRelatedTasks(
          taskEventMessage.id,
          tasks.current
        )

        const targetTask = cloneDeep(taskEventMessage.object) as GanttTask

        // update related tasks and target task
        const updatedTasks = updateRelatedTasksByTargetTask(
          relatedTasks,
          targetTask,
          'update'
        )

        tasks.current = tasks.current.map((task) => {
          const updatedTask = updatedTasks.find(
            (updatedTask) => updatedTask.id === task.id
          )
          return updatedTask ?? task
        })

        // Update the task in the data source
        taskDataSource.store().push(
          updatedTasks.map((task) => ({
            type: 'update',
            key: task.id,
            data: {
              ...task,
              startDate: task.startDate
                ? dayjs(task.startDate).startOf('day').toDate()
                : undefined, // convert startDate to begin of day
              dueDate: task.dueDate
                ? dayjs(task.dueDate).endOf('day').toDate()
                : undefined, // convert endDate to end of day
              actualStartDate: task.actualStartDate
                ? dayjs(task.actualStartDate).toDate()
                : undefined,
              actualEndDate: task.actualEndDate
                ? dayjs(task.actualEndDate).toDate()
                : undefined,
              actualProgress: task.actualProgress ?? 0,
              version: task.version + 1, // Increment the version
            },
          }))
        )
      } else if (taskEventMessage.eventType === NotifyEventType.Deleted) {
        // Find all related tasks
        const relatedTasks = findRelatedTasks(
          taskEventMessage.id,
          tasks.current
        )

        // Get the task from the data source
        const targetTask = GanttRef.current?.instance.getTaskData(
          taskEventMessage.id
        )

        // update related tasks and target task
        const updatedTasks = updateRelatedTasksByTargetTask(
          relatedTasks,
          targetTask,
          'delete'
        )

        tasks.current = tasks.current
          .filter((task) => task.id !== taskId)
          .map((task) => {
            const updatedTask = updatedTasks.find(
              (updatedTask) => updatedTask.id === task.id
            )
            return updatedTask ?? task
          })

        // Update the task in the data source
        taskDataSource.store().push(
          updatedTasks.map((task) => ({
            type: task.id === taskId ? 'remove' : 'update',
            key: task.id,
            data: {
              ...task,
              startDate: task.startDate
                ? dayjs(task.startDate).startOf('day').toDate()
                : undefined, // convert startDate to begin of day
              dueDate: task.dueDate
                ? dayjs(task.dueDate).endOf('day').toDate()
                : undefined, // convert endDate to end of day
              actualStartDate: task.actualStartDate
                ? dayjs(task.actualStartDate).toDate()
                : undefined,
              actualEndDate: task.actualEndDate
                ? dayjs(task.actualEndDate).toDate()
                : undefined,
              actualProgress: task.actualProgress ?? 0,
              version: task.version + 1, // Increment the version
            },
          }))
        )
      } else {
        // If the event type is not Inserted, Updated, or Deleted, refetch the gantt
        taskDataSource.reload()
      }
    },
    [
      findRelatedTasks,
      taskDataSource,
      taskEventMessage,
      taskId,
      updateRelatedTasksByTargetTask,
    ],
    taskEventMessage,
    true
  )

  // タスク削除ダイアログのsubmit処理
  const handleTaskDeleted = useCallback(
    (taskId: string) => {
      closeTaskEntryDialog()
    },
    [closeTaskEntryDialog]
  )

  const [ganttWidth, setGanttWidth] = useState<number>()

  // Listen layout change to update gantt width
  // Cell item position/width will be recalculated by the devextreme after update gantt width
  const ganttWrapperRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() => {
      setGanttWidth(node.offsetWidth)
    })
    resizeObserver.observe(node)
  }, [])

  // Customize scale header cell text
  const handleScaleCellPrepared = useCallback(
    (event: ScaleCellPreparedEvent) => {
      const scaleElement = event.scaleElement
      scaleElement.style.setProperty('padding-left', '0')
      scaleElement.style.setProperty('padding-right', '0')

      if (
        event.scaleType === 'weeks' &&
        !isNaN(event.startDate.getTime()) &&
        !isNaN(event.endDate.getTime())
      ) {
        scaleElement.textContent = `${t('format.shortDateWithText', {
          value: event.startDate,
        })} - ${t('format.shortDateWithText', {
          value: event.endDate,
        })}`
      } else if (
        event.scaleType === 'days' &&
        !isNaN(event.startDate.getTime())
      ) {
        scaleElement.textContent = t('format.dayOfWeek', {
          value: event.startDate,
        })
      }
    },
    [t]
  )

  const ganttChartContainerRef = useRef(null)
  const [enableFullScreen, setEnableFullScreen] = useToggle(false)

  // 全画面表示終了（ESCボタン押下時）
  const handleExitFullScreen = useCallback(() => {
    setEnableFullScreen(false)
  }, [setEnableFullScreen])

  const isFullScreen = useFullscreen(ganttChartContainerRef, enableFullScreen, {
    onClose: handleExitFullScreen,
  })

  // Event handle when the Full screen button is clicked
  const handleFullScreenButtonClick = useCallback(() => {
    setEnableFullScreen(true)
  }, [setEnableFullScreen])

  // Event handle when the Zoom in button is clicked
  const handleZoomInButtonClick = useCallback(() => {
    const currentScaleType =
      GanttRef.current?.instance.option('scaleType') ?? 'days'

    const currentScaleTypeIndex = ScaleTypes.indexOf(currentScaleType)

    // If the current scale type is the last one, do nothing
    if (currentScaleTypeIndex === 0) return

    const nextScaleType = ScaleTypes[currentScaleTypeIndex - 1]

    if (nextScaleType === 'days') {
      // Zoom in 3 times to change narrowest display
      GanttRef.current?.instance.zoomIn()
      GanttRef.current?.instance.zoomIn()
      GanttRef.current?.instance.zoomIn()
    } else if (nextScaleType === 'weeks') {
      // Zoom in 1 times to change narrowest display
      GanttRef.current?.instance.zoomIn()
    }

    // Set the next scale type
    GanttRef.current?.instance.option('scaleType', nextScaleType)
  }, [])

  // Event handle when the Zoom out button is clicked
  const handleZoomOutButtonClick = useCallback(() => {
    const currentScaleType =
      GanttRef.current?.instance.option('scaleType') ?? 'days'

    const currentScaleTypeIndex = ScaleTypes.indexOf(currentScaleType)

    // If the current scale type is the last one, do nothing
    if (currentScaleTypeIndex === ScaleTypes.length - 1) return

    const nextScaleType = ScaleTypes[currentScaleTypeIndex + 1]
    if (nextScaleType === 'months') {
      // Zoom out 1 times to change widest display
      GanttRef.current?.instance.zoomOut()
    } else if (nextScaleType === 'quarters') {
      // Zoom out 3 times to change widest display
      GanttRef.current?.instance.zoomOut()
      GanttRef.current?.instance.zoomOut()
      GanttRef.current?.instance.zoomOut()
    } else if (nextScaleType === 'years') {
      // Zoom out 3 times to change widest display
      GanttRef.current?.instance.zoomOut()
      GanttRef.current?.instance.zoomOut()
      GanttRef.current?.instance.zoomOut()
    }

    // Set the next scale type
    GanttRef.current?.instance.option('scaleType', nextScaleType)
  }, [])

  // The option to show Full screen button on Gantt chart
  const fullScreenButtonOptions = {
    icon: 'fullscreen',
    stylingMode: 'text',
    hint: t('label.fullScreen'),
    onClick: handleFullScreenButtonClick,
  }

  const zoomInButtonOptions = {
    icon: 'dx-icon dx-gantt-i dx-gantt-i-zoom-in',
    stylingMode: 'text',
    hint: t('label.zoomIn'),
    onClick: handleZoomInButtonClick,
  }

  const zoomOutButtonOptions = {
    icon: 'dx-icon dx-gantt-i dx-gantt-i-zoom-out',
    stylingMode: 'text',
    hint: t('label.zoomOut'),
    onClick: handleZoomOutButtonClick,
  }

  // サブタスク追加ボタンクリック時
  const handleAddSubTaskButtonClick = useCallback(() => {
    // タスクが選択状態ではない場合、サブタスク追加はできないため何もしない
    if (selectedRow === undefined) return

    setTaskId(undefined)
    setParentTaskId(selectedRow)
    setIsEditMode(false)

    // ダイアログを開く
    openTaskEntryDialog()
  }, [openTaskEntryDialog, selectedRow])

  // Event handle when the Add task button is clicked
  const handleAddTaskButtonClick = useCallback(() => {
    setTaskId(undefined)

    const selectedTask = GanttRef.current?.instance.getTaskData(selectedRow)
    // 選択されたタスクがある場合は、そのタスクと同じ階層に新規タスクを作成する
    setParentTaskId(selectedTask?.parentTaskId ?? undefined)

    setIsEditMode(false)

    openTaskEntryDialog()
  }, [openTaskEntryDialog, selectedRow])

  // Event handle when the Display today button is clicked
  const handleShowTodayButtonClick = useCallback(() => {
    // Scrolls to a date of today inside Gantt chart
    GanttRef.current?.instance.scrollToDate(new Date())
  }, [])

  // Event handle when the detail button is clicked
  const handleDetailColumnButtonClick = useCallback(() => {
    // toggle the visibility of the Detail column
    setIsViewDetailColumn(!isViewDetailColumn)
  }, [isViewDetailColumn])

  // Effect to repaint the Gantt chart when the visibility of the Detail column is changed
  useValueChangeEffect(
    () => {
      GanttRef.current?.instance.repaint()
    },
    [],
    isViewDetailColumn
  )

  const taskListWidth = useMemo(() => {
    if (!ganttWidth) return DEFAULT_TASK_LIST_WIDTH

    // ガントチャートの幅が狭い場合は、ガントチャートの幅の80%を列表示の初期幅としたい
    const calcWidth = ganttWidth * TASK_LIST_WIDTH_WITH_DETAIL_COLUMN_RATIO
    // ただし、計算結果が詳細表示の列の幅より大きくなる場合は、詳細表示の列の幅を採用する
    const detailTaskWidth =
      DETAIL_TASK_LIST_WIDTH < calcWidth ? DETAIL_TASK_LIST_WIDTH : calcWidth

    // 80% of gantt width when isViewDetailColumn is true
    return isViewDetailColumn ? detailTaskWidth : DEFAULT_TASK_LIST_WIDTH
  }, [ganttWidth, isViewDetailColumn])

  // Customize header filter date column data
  // Using any type because the type of options.dataSource.postProcess is any
  const customizeHeaderFilterDateData = useCallback((options: any) => {
    options.dataSource.postProcess = (items: any) => {
      const newItems = items.map((item: any) => {
        return item
      })
      return newItems
    }
  }, [])

  // custom render add subtask button
  const renderAddSubtaskButton = useCallback(() => {
    return (
      <CrewButton
        stylingMode="text"
        disabled={!selectedRow}
        onClick={handleAddSubTaskButtonClick}
        icon={<AccountTreeOutline width={20} height={20} />}
        text={t('action.addSubtask')}
      />
    )
  }, [handleAddSubTaskButtonClick, selectedRow, t])

  // custom render add task button
  const renderAddTaskButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          stylingMode="text"
          disabled={disabled}
          onClick={handleAddTaskButtonClick}
          icon={<EventAvailable width={20} height={20} />}
          text={t('action.addTask')}
        />
      )
    },
    [handleAddTaskButtonClick, t]
  )

  // Event handle when the delete task button is clicked
  const handleTaskDeleteButtonClick = useCallback(async () => {
    // Get the selected task key
    const selectedRowKey = GanttRef.current?.instance.option('selectedRowKey')

    if (!selectedRowKey) return

    const rowData = await taskDataSource.store().byKey(selectedRowKey)

    // If the selected node has children, show confirm delete children dialog
    if (rowData.hasChildTasks) {
      setConfirmMessage(t('message.task.confirmDeleteLinkedSubtasks'))
    } else {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
    }

    // Open the confirm dialog
    openConfirmDialog()
  }, [openConfirmDialog, t, taskDataSource])

  // Event handle when click delete permit button in confirm dialog
  const handleDeletePermitButtonClick = useCallback(() => {
    // Get the selected task key
    const selectedRowKey = GanttRef.current?.instance.option('selectedRowKey')

    // Delete the task
    GanttRef.current?.instance.deleteTask(selectedRowKey)

    // Close the confirm dialog
    closeConfirmDialog()
  }, [closeConfirmDialog])

  // custom render delete task button
  const renderDeleteTaskButton = useCallback(() => {
    return (
      <CrewButton
        stylingMode="text"
        disabled={selectedRow ? false : true}
        onClick={handleTaskDeleteButtonClick}
        icon={<BaselineDelete width={20} height={20} />}
        text={t('action.deleteTask')}
      />
    )
  }, [handleTaskDeleteButtonClick, selectedRow, t])

  // custom render today button
  const renderTodayButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          stylingMode="text"
          disabled={disabled}
          onClick={handleShowTodayButtonClick}
          icon={<BaselineEvent width={20} height={20} />}
          text={t('action.toDay')}
        />
      )
    },
    [handleShowTodayButtonClick, t]
  )

  // custom render display detail button
  const renderDisplayDetailButton = useCallback(
    ({ disabled }: { disabled: boolean }) => {
      return (
        <CrewButton
          stylingMode="text"
          disabled={disabled}
          onClick={handleDetailColumnButtonClick}
          icon={<ViewAgendaOutlineSharp width={20} height={20} />}
          text={t('action.displayDetail')}
          className={classNames(
            isViewDetailColumn && '!bg-crew-gray-200 dark:!bg-crew-gray-700' // ボタンの背景色を変更
          )}
        />
      )
    },
    [handleDetailColumnButtonClick, isViewDetailColumn, t]
  )

  // Context menu items
  const getContextMenuItems = useCallback(
    (): IContextMenuProps['items'] => [
      'addTask',
      // Custom menu item
      // https://js.devexpress.com/jQuery/Demos/WidgetsGallery/Demo/Gantt/ContextMenu/MaterialBlueLight/
      {
        name: 'taskDetail',
        text: t('label.taskDetail'),
        icon: 'dx-icon dx-gantt-i dx-gantt-i-task-details',
      },
      {
        name: 'deleteTaskButton',
        text: t('action.deleteTask'),
        icon: 'dx-icon dx-gantt-i dx-gantt-i-delete',
      },
    ],
    [t]
  )

  // Event handle when the custom menu item is clicked
  const handleMenuContextClick = useCallback(
    async (event: CustomCommandEvent) => {
      const selectedRowKey = event.component?.option('selectedRowKey')

      if (!selectedRowKey) return

      if (event.name === 'taskDetail') {
        navigate(
          `/tasks/${selectedRowKey}/${getDefaultTabValue(TaskDetailListTabs)}`
        )
      } else if (event.name === 'deleteTaskButton') {
        const rowData = await taskDataSource.store().byKey(selectedRowKey)
        // If the selected node has children, show confirm delete children dialog
        if (rowData.hasChildTasks) {
          setConfirmMessage(t('message.task.confirmDeleteLinkedSubtasks'))
        } else {
          setConfirmMessage(t('message.general.confirmMessage.delete'))
        }

        // Open the confirm dialog
        openConfirmDialog()
      }
    },
    [navigate, openConfirmDialog, t, taskDataSource]
  )

  // Event handle when the task is updated
  const handleTaskUpdate = useCallback(
    async (event: TaskUpdatingEvent) => {
      try {
        // Cancel the default behavior
        event.cancel = true

        // Get the task from the data source
        const task = GanttRef.current?.instance.getTaskData(event.key)

        // If the task has a child task, do not update start date and end date
        if (
          hasChildTask(task, tasks.current) &&
          (event.newValues.startDate || event.newValues.dueDate)
        ) {
          return
        }

        // Adjust the time and format the start date
        const startDate = event.newValues.startDate
          ? adjustTimeAndFormatStartDate(event.newValues.startDate)
          : task.startDate
          ? dayjs(task.startDate).format(JsonDateFormat.YYYYMMDD)
          : undefined

        // Adjust the time and format the end date
        const dueDate = event.newValues.dueDate
          ? adjustTimeAndFormatEndDate(event.newValues.dueDate)
          : task.dueDate
          ? dayjs(task.dueDate).format(JsonDateFormat.YYYYMMDD)
          : undefined

        const updatedParams = {
          id: event.key,
          startDate: startDate
            ? dayjs(startDate).format(JsonDateFormat.YYYYMMDD)
            : undefined,
          dueDate: dueDate
            ? dayjs(dueDate).format(JsonDateFormat.YYYYMMDD)
            : undefined,
          actualProgress: event.newValues.actualProgress ?? task.actualProgress,
          version: task.version,
        }

        const updatedTask: GanttTask = {
          ...task,
          ...updatedParams,
        }
        // Find all related tasks
        const relatedTasks = findRelatedTasks(event.key, tasks.current)

        // update related tasks and target task
        const updatedTasks = updateRelatedTasksByTargetTask(
          relatedTasks,
          updatedTask,
          'update'
        )

        tasks.current = tasks.current.map((task) => {
          const updatedTask = updatedTasks.find(
            (updatedTask) => updatedTask.id === task.id
          )
          return updatedTask ?? task
        })

        // Update the task in the data source
        taskDataSource.store().push(
          updatedTasks.map((task) => ({
            type: 'update',
            key: task.id,
            data: {
              ...task,
              startDate: task.startDate
                ? dayjs(task.startDate).startOf('day').toDate()
                : undefined, // convert startDate to begin of day
              dueDate: task.dueDate
                ? dayjs(task.dueDate).endOf('day').toDate()
                : undefined, // convert endDate to end of day
              actualProgress: task.actualProgress,
              version: task.version + 1, // Increment the version
            },
          }))
        )

        await updateGanttTask(updatedParams)
      } catch (error) {
        showApiErrors(error)

        // Reload the data source to revert the changes
        taskDataSource.reload()
      }
    },
    [
      findRelatedTasks,
      hasChildTask,
      showApiErrors,
      taskDataSource,
      updateGanttTask,
      updateRelatedTasksByTargetTask,
    ]
  )

  // Event handle when the task is deleted
  const handleTaskDelete = useCallback(
    async (event: TaskDeletingEvent) => {
      try {
        // Cancel the default behavior
        event.cancel = true

        // Get the task from the data source
        const task = GanttRef.current?.instance.getTaskData(event.key)

        // Find all related tasks
        const relatedTasks = findRelatedTasks(event.key, tasks.current)

        // update related tasks and target task
        const updatedTasks = updateRelatedTasksByTargetTask(
          relatedTasks,
          task,
          'delete'
        )

        tasks.current = tasks.current
          .filter((task) => task.id !== event.key)
          .map((task) => {
            const updatedTask = updatedTasks.find(
              (updatedTask) => updatedTask.id === task.id
            )
            return updatedTask ?? task
          })

        // Update the task in the data source
        taskDataSource.store().push(
          updatedTasks.map((task) => ({
            type:
              // If the task is the target task or childs of the target task, remove the task
              task.id === event.key || task.parentTaskId === event.key
                ? 'remove'
                : 'update',
            key: task.id,
            data: {
              ...task,
              startDate: task.startDate
                ? dayjs(task.startDate).startOf('day').toDate()
                : null, // convert startDate to begin of day
              dueDate: task.dueDate
                ? dayjs(task.dueDate).endOf('day').toDate()
                : null, // convert endDate to end of day
              actualProgress: task.actualProgress,
              version: task.version + 1, // Increment the version
            },
          }))
        )

        await deleteTask(event.key, task.version)
      } catch (error) {
        showApiErrors(error)

        // Reload the data source to revert the changes
        taskDataSource.reload()
      }
    },
    [
      deleteTask,
      findRelatedTasks,
      showApiErrors,
      taskDataSource,
      updateRelatedTasksByTargetTask,
    ]
  )

  // タスクの選択行変更時
  const handleSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    setSelectedRow(e.selectedRowKey)
  }, [])

  // reset task list width when gantt width is changed
  useValueChangeEffect(
    () => {
      if (ganttWidth && GanttRef.current) {
        GanttRef.current.instance.resetOption('taskListWidth')
      }
    },
    [ganttWidth],
    ganttWidth
  )

  return (
    <div
      className={classNames(
        'h-full',
        // NOTE: 全画面表示時は親の背景スタイルが継承されないため別途テーマ背景色を設定
        isFullScreen && 'crew-bg-default'
      )}
      ref={ganttChartContainerRef}
    >
      <div className="h-full" ref={ganttWrapperRef}>
        <CrewGantt
          id="gantt-chart"
          ref={GanttRef}
          width={ganttWidth}
          height="100%"
          onTaskEditDialogShowing={handleTaskEditDialogShowing}
          onTaskInserting={handleTaskInsertDialogShowing}
          // Gantt dx-splitter-border has z-index 997 over the modal dialog, so we need to set z-index for gantt container
          className="z-10"
          onScaleCellPrepared={handleScaleCellPrepared}
          taskListWidth={taskListWidth}
          onCustomCommand={handleMenuContextClick}
          onSelectionChanged={handleSelectionChanged}
          onTaskUpdating={handleTaskUpdate}
          onTaskDeleting={handleTaskDelete}
        >
          <ContextMenu items={getContextMenuItems()} />

          <Column // 左側に表示する見出し。複数項目が必要な場合は必要分並べる。この項目でソート可
            dataField="subject"
            caption={t('label.subject')}
            width={320}
          />
          <Column
            dataField="wbsNumber"
            caption={t('label.wbsNumber')}
            visible={isViewDetailColumn}
            width={90}
            sortingMethod={compareWBSNumber}
          />
          <Column
            dataField="assignToUser"
            caption={t('label.assignedUser')}
            visible={isViewDetailColumn}
            cellRender={renderAssignToUser}
            width={170}
            calculateCellValue={calculateAssignToUserCellValue}
          />
          <Column
            dataField="startDate"
            caption={
              isViewDetailColumn
                ? t('label.scheduledStartDate')
                : t('label.startDate')
            }
            cellRender={dateFormatter}
            width={90}
          >
            <HeaderFilter dataSource={customizeHeaderFilterDateData} />
          </Column>
          <Column
            dataField="dueDate"
            caption={
              isViewDetailColumn
                ? t('label.scheduledEndDate')
                : t('label.dueDate')
            }
            cellRender={dateFormatter}
            width={90}
          >
            <HeaderFilter dataSource={customizeHeaderFilterDateData} />
          </Column>
          <Column
            dataField="estimatedWorkTimes"
            caption={t('label.scheduledWorkingTime')}
            visible={isViewDetailColumn}
            width={90}
            alignment="right"
            cssClass="filter-icon-alignment-right"
            calculateCellValue={calculateEstimatedWorkTimesCellValue}
          />
          <Column
            dataField="actualStartDate"
            caption={t('label.actualStartDate')}
            cellRender={dateFormatter}
            visible={isViewDetailColumn}
            width={90}
          >
            <HeaderFilter dataSource={customizeHeaderFilterDateData} />
          </Column>
          <Column
            dataField="actualEndDate"
            caption={t('label.actualEndDate')}
            cellRender={dateFormatter}
            visible={isViewDetailColumn}
            width={90}
          >
            <HeaderFilter dataSource={customizeHeaderFilterDateData} />
          </Column>
          <Column
            dataField="actualWorkTimes"
            caption={t('label.actualWorkingTime')}
            visible={isViewDetailColumn}
            width={90}
            alignment="right"
            cssClass="filter-icon-alignment-right"
            calculateCellValue={calculateActualWorkTimesCellValue}
          />
          <Column
            dataField="actualProgress"
            caption={t('label.progressRate')}
            visible={isViewDetailColumn}
            width={90}
            cellRender={renderProgressRate}
            cssClass="filter-icon-alignment-right"
          />

          {/* ステータス */}
          <Column
            dataField="taskState"
            caption={t('label.state')}
            visible={isViewDetailColumn}
            width={120}
            cellRender={renderTaskState}
            calculateCellValue={calculateTaskStateCellValue}
            cssClass="filter-icon-alignment-right"
          />

          {/* add column version with visible = false, we need get version from getTaskData  */}
          <Column dataField="version" visible={false} />

          <Tasks // taskの定義
            // https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxGantt/Configuration/tasks/
            dataSource={taskDataSource}
            keyExpr="id"
            parentIdExpr="parentTaskId"
            titleExpr="subject"
            startExpr="startDate"
            endExpr="dueDate"
            progressExpr="actualProgress"
          />
          <Dependencies // task間の依存関係の定義
            // https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxGantt/Configuration/dependencies/
            dataSource={taskDependencyDataSource}
            keyExpr="id"
            predecessorIdExpr="predecessorTaskId"
            successorIdExpr="successorTaskId"
            typeExpr="dependencyType"
          />

          <Toolbar // ツールバー設定
          // https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxGantt/Configuration/toolbar/
          >
            <Item name="collapseAll" />
            <Item name="expandAll" />
            <Item name="separator" />
            <Item widget="dxButton" options={zoomInButtonOptions} />
            <Item widget="dxButton" options={zoomOutButtonOptions} />
            <Item widget="dxButton" options={fullScreenButtonOptions} />
            <Item name="separator" />
            <Item name="addTask" render={renderAddTaskButton} />
            <Item widget="dxButton" render={renderAddSubtaskButton} />
            <Item widget="dxButton" render={renderDeleteTaskButton} />
            <Item name="separator" />
            <Item widget="dxButton" render={renderTodayButton} />
            <Item widget="dxButton" render={renderDisplayDetailButton} />
          </Toolbar>

          <Sorting // ソート設定
            // https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxGantt/Configuration/sorting/
            mode="multiple"
            showSortIndexes={true}
            ascendingText={t('label.ascending')}
            descendingText={t('label.descending')}
            clearText={t('label.clear')}
          />

          <Editing // 編集できる項目の設定
            // https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxGantt/Configuration/editing/
            enabled // 編集を有効化
            // デフォルトで全ての項目が編集可能
            allowResourceAdding={false}
            allowResourceDeleting={false}
            allowResourceUpdating={false}
            allowTaskResourceUpdating={false}
          />
          <Validation // 編集時の挙動の制御
            // https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxGantt/Configuration/validation/
            autoUpdateParentTasks={false} // 子タスクの変更で親タスクの開始日・終了日を自動更新しない
            validateDependencies={false} // タスクの移動に合わせて依存のあるタスクも移動する、または修正を拒否する
            enablePredecessorGap // 先行タスクの移動でタスク間の隙間を修正可能にする。デフォルトでは隙間を維持したまま後続タスクも移動する
          />

          <HeaderFilter // ヘッダーにフィルターを設定
            // https://js.devexpress.com/React/Documentation/ApiReference/UI_Components/dxGantt/Configuration/headerFilter/
            visible
          />
        </CrewGantt>

        <TaskEntryDialog
          isEditMode={isEditMode}
          title={
            isEditMode ? t('label.editTaskTitle') : t('label.addTaskTitle')
          }
          onSubmit={handleTaskEntryDialogSubmit}
          onDeleted={handleTaskDeleted}
          isOpen={isTaskEntryDialogOpen}
          onClose={closeTaskEntryDialog}
          taskId={taskId}
          projectId={projectId}
          parentTaskId={parentTaskId}
        />

        {/* 削除確認ダイアログ */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={closeConfirmDialog}
        />
      </div>
    </div>
  )
})
