import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { CrewTextAreaField } from 'components/forms/crewTextAreaField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FormValues, useFileEntryForm } from './useFileEntryForm'
import { CrewFileUploader } from 'components/devextreme/crewFileUploader/crewFileUploader'
import { UploadFile } from 'models/domain/uploadFile'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { useFocusInput } from 'hooks/useFocusInput'
import { EntityType, ProjectType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useProjectPermissions } from '@crew/hooks'
import { ComponentCallbackHandler } from '@crew/utils'
import { useToast } from 'hooks/useToast'
import { useGetFileQuery } from '@crew/apis/file/fileApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { File } from '@crew/apis/file/models/getFile/response'
import { useGetTaskQuery } from '@crew/apis/task/taskApis'
import {
  useGetEventQuery,
  useGetProjectQuery,
} from '@crew/apis/project/projectApis'
import { GetEventRequest } from '@crew/apis/project/models/getEvent/request'
import { GetTaskRequest } from '@crew/apis/task/models/getTask/request'
import { GetFileRequest } from '@crew/apis/file/models/getFile/request'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewDropDownBoxTreeField } from 'components/forms/crewDropDownBoxTreeField'
import { UnsortedFolder } from 'enums/app'
import classNames from 'classnames'
import { GetProjectRequest } from '@crew/apis/project/models/getProject/request'

type Tag = {
  id: number
  name: string
}

export type FileEntryFormProps = {
  isEditMode: boolean
  fileId?: string
  entityType?: EntityType
  entityRecordId?: string
  folderId?: string
  onCancel: () => void
  onRegistered?: (fileId: string) => void
  onUpdated?: () => void
  onDeleted?: () => void
}

export const FileEntryForm = memo((props: FileEntryFormProps) => {
  // 関連先タイプと関連先は外から渡された値を元に設定するためローカルステートで保持する
  const [entityType, setEntityType] = useState<EntityType | null>(null)
  const [entityRecordId, setEntityRecordId] = useState<string | null>(null)

  const {
    // react-hook-form関連
    formState,
    control,
    reset,
    setValue,
    setError,
    handleSubmit,
    validateRules,

    // DataSource
    entityTypeDataSource,
    entityRecordIdDataSource,
    tagDataSource,
    folderDataSource,

    // ファイル登録・更新・削除処理
    insertFile,
    updateFile,
    deleteFile,
    isLoadingInsertFile,
    isLoadingUpdateFile,
    isLoadingDeleteFile,
    isLoadingInsertTaskFile,
    isLoadingInsertEventFile,
  } = useFileEntryForm(props.isEditMode, entityType, entityRecordId)

  const { t } = useTranslation()
  const toast = useToast()

  // ダイアログを開いた際の初期フォーカスはタグ入力欄とする
  useFocusInput('tagIds')

  // 確認ダイアログ
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // エラーダイアログ
  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  // エラーダイアログメッセージ
  const [errorMessage, setErrorMessage] = useState('')

  // ファイル操作の権限取得
  const {
    hasPrjFileDeletePermission,
    hasPrjFileCreatePermission,
    hasPrjFileEditPermission,
  } = useProjectPermissions(
    entityType ?? undefined,
    entityRecordId ?? undefined
  )

  const [fileVersion, setFileVersion] = useState(0)

  // アップロード済みのファイル
  const [uploadedFile, setUploadedFile] = useState<UploadFile>()

  // 変更内容（ファイル履歴の説明）の表示状態
  const [isShowFileHistoryDescription, setIsShowFileHistoryDescription] =
    useState(false)

  // 編集時のファイル情報
  const [editTargetFile, setEditTargetFile] = useState<File | null>(null)

  // ファイル詳細の取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getFileParam: GetFileRequest | undefined = props.fileId
    ? {
        fileId: props.fileId,
      }
    : undefined
  const { data: getFileResult } = useGetFileQuery(getFileParam ?? skipToken)

  // entityTypeがTaskの場合は、表示される関連先がプロジェクトになるため、表示のためのIDを取得する
  const getTaskParam: GetTaskRequest | undefined =
    props.entityType === EntityType.Task
      ? {
          taskId: props.entityRecordId ?? '',
        }
      : undefined
  const { data: getTaskResult } = useGetTaskQuery(getTaskParam ?? skipToken)

  // entityTypeがEventの場合は、表示される関連先がプロジェクトになるため、表示のためのIDを取得する
  const getEventParam: GetEventRequest | undefined =
    props.entityType === EntityType.Event
      ? {
          eventId: props.entityRecordId ?? '',
        }
      : undefined
  const { data: getEventResult } = useGetEventQuery(getEventParam ?? skipToken)

  // APIエラー時の表示用関数を取得
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // 登録ボタンを押せるかどうか
  const canClickRegisterButton = useMemo(
    // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    // check permission
    () =>
      Object.keys(formState.errors).length === 0 &&
      !formState.isSubmitting &&
      ((!props.isEditMode && hasPrjFileCreatePermission) ||
        (props.isEditMode && hasPrjFileEditPermission)),
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [
      formState,
      hasPrjFileCreatePermission,
      hasPrjFileEditPermission,
      props.isEditMode,
    ]
  )

  // 画面上に表示される値を設定する
  let displayEntityType: EntityType | null = null
  let displayEntityRecordId: string | null = null
  let displayFolderId: string | null = null

  if (props.entityType === EntityType.Task) {
    // Taskの場合はタスクのentityRecordId = プロジェクトIDを設定する
    displayEntityType = EntityType.Project
    displayEntityRecordId = getTaskResult?.task?.entityRecordId ?? null
    displayFolderId = UnsortedFolder.value
  } else if (props.entityType === EntityType.Event) {
    // Eventの場合はEvent.projectIdを設定する
    displayEntityType = EntityType.Project
    displayEntityRecordId = getEventResult?.event?.entityRecordId ?? null
    displayFolderId = UnsortedFolder.value
  } else {
    // Taskでもプロジェクトでもない場合は、propsから受け取った値をそのまま設定する
    displayEntityType = props.entityType ?? null
    displayEntityRecordId = props.entityRecordId ?? null
    displayFolderId = props.folderId ?? UnsortedFolder.value
  }

  // Get project information
  const getProjectParams: GetProjectRequest | undefined = props.isEditMode
    ? {
        projectId: getFileResult?.file?.entityRecordId ?? '',
      }
    : displayEntityType === EntityType.Project
    ? {
        projectId: displayEntityRecordId ?? '',
      }
    : undefined
  const { data: getProjectResult } = useGetProjectQuery(
    getProjectParams ?? skipToken
  )

  // If projectType changes, check to hide 関連先タイプ and 関連先
  const isDirectChannel = useMemo(
    () => getProjectResult?.project?.projectType === ProjectType.DirectChannel,
    [getProjectResult?.project?.projectType]
  )

  // フォーム初期化処理関数
  const initializeForm = useCallback(() => {
    if (props.isEditMode) {
      // 編集時
      if (getFileResult?.file) {
        setEntityType(getFileResult.file.entityType)
        setEntityRecordId(getFileResult.file.entityRecordId)
        setEditTargetFile(getFileResult.file)
        setFileVersion(getFileResult.file.version)
        reset({
          entityType: getFileResult.file.entityType,
          entityRecordId: getFileResult.file.entityRecordId,
          tagIds: getFileResult.file.tags.map((tag) => tag.id),
          name: getFileResult.file.name,
          description: getFileResult.file.description ?? '',
          folderId: getFileResult.file.folderId ?? UnsortedFolder.value,
        })
      }
    } else {
      // 新規登録時（関連先の情報がある場合は関連先タイプ・関連先の初期値を設定）

      setEntityType(displayEntityType)
      setEntityRecordId(displayEntityRecordId)
      reset({
        entityType: displayEntityType,
        entityRecordId: displayEntityRecordId,
        folderId: displayFolderId,
      })
    }
  }, [
    props.isEditMode,
    getFileResult?.file,
    reset,
    displayEntityType,
    displayEntityRecordId,
    displayFolderId,
  ])

  // アップロード完了時のイベントハンドラ
  const handleUploaded = useCallback(
    (file: UploadFile) => {
      // ファイル登録ダイアログは単一ファイルアップロードなのでファイルリストに追加ではなくデータを差し替える
      setUploadedFile(file)

      if (!file.progress) {
        setValue('uploadedFileKey', file.keyName ?? null, {
          shouldValidate: true,
        })
      }

      // 変更内容テキストの表示
      setIsShowFileHistoryDescription(true)
    },
    [setValue]
  )

  // 登録ボタンクリック時のイベントハンドラ
  const handleRegisterButtonClick = useCallback(async () => {
    // 編集モードかどうかによって制御を振り分ける
    if (props.isEditMode) {
      // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
      const onSubmit = async (data: FormValues) => {
        try {
          // ファイル更新
          await updateFile(data, editTargetFile, uploadedFile)

          // ファイル更新に成功した旨のトーストを表示する
          toast.success(t('message.file.fileUpdated'))

          // ファイル更新後のコールバック関数を実行
          props.onUpdated?.()
        } catch (err) {
          showApiErrors(err)
        }
      }
      // ファイル更新実行
      handleSubmit(onSubmit)()
    } else {
      // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
      const onSubmit = async (data: FormValues) => {
        try {
          const result = await insertFile(
            data,
            uploadedFile,
            props.entityType,
            props.entityRecordId
          )

          // 登録結果のレスポンスがない場合は処理を中断する
          // TODO: 以下タスクで戻り値が設定されるようになるため、この条件分岐は不要になる見込み
          // https://break-tmc.atlassian.net/browse/CREW-7162
          if (!result?.file) {
            return
          }

          // ファイル登録に成功した旨のトーストを表示する
          toast.success(t('message.file.fileCreated'))

          // ファイル登録後のコールバック関数を実行
          props.onRegistered?.(result.file.id)
        } catch (err) {
          showApiErrors(err)
        }
      }
      // ファイル登録実行
      handleSubmit(onSubmit)()
    }
  }, [
    editTargetFile,
    handleSubmit,
    insertFile,
    props,
    showApiErrors,
    t,
    toast,
    updateFile,
    uploadedFile,
  ])

  // タグ追加時のイベントハンドラ
  // すでに存在するタグを入力しEnterを押した場合もこのイベントが発生する
  // CrewTagBoxFieldに紐づくDataSourceに関わる操作が必要なため、実処理はここで直接実行する
  const handleAddCustomTagValue = useCallback<
    ComponentCallbackHandler<typeof CrewTagBoxField, 'onCustomItemCreating'>
  >(
    (args) => {
      // 入力テキストがない場合は処理しない
      if (!args.text) {
        return
      }

      let newTag: Tag = {
        id: 0, //バックエンドで採番
        name: args.text,
      }

      // カスタム値の登録（{ id: tagId | undefined, name: 入力値 }）
      // カスタムデータソースのinsert内でAPIによるtagIdの取得を試みる
      args.customItem = args.component
        .getDataSource()
        .store()
        .insert(newTag)
        .then((savedTag: Tag) => {
          newTag = savedTag //レスポンスで差し替え
          return tagDataSource.load()
        })
        .then(() => newTag)
    },
    [tagDataSource]
  )

  // 削除確認ダイアログの許可ボタンクリック時のイベントハンドラ
  const handleDeletePermitButtonClick = useCallback(async () => {
    // Handle if the ID of the file to be deleted exists.
    if (props.fileId) {
      try {
        // ファイル削除
        await deleteFile(props.fileId, fileVersion)

        // ファイル削除に成功した旨のトーストを表示する
        toast.success(t('message.file.deleteSuccess'))

        // 呼び出し元で画面遷移
        props.onDeleted?.()
      } catch (err) {
        setErrorMessage(t('message.general.errorMessage.delete'))
        openErrorDialog()
      }
    }

    // 確認ダイアログを閉じる
    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    deleteFile,
    fileVersion,
    openErrorDialog,
    props,
    t,
    toast,
  ])

  // 削除ボタンクリック時のイベントハンドラ
  const handleDeleteButtonClick = useCallback(() => {
    // 確認ダイアログのメッセージを設定して表示する
    setConfirmMessage(t('message.general.confirmMessage.delete'))
    openConfirmDialog()
  }, [openConfirmDialog, t])

  // 関連先タイプ変更時のイベントハンドラ
  const handleEntityTypeChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBoxField, 'onValueChanged'>
  >(
    (event) => {
      // entityTypeが変更されたらentityRecordのドロップダウンの値をリセットする
      if (entityType !== event.value) {
        setEntityType(event.value)
        setValue('entityRecordId', null)
      }
    },
    [entityType, setValue]
  )

  // 関連先変更時のイベントハンドラ
  const handleEntityRecordIdChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBoxField, 'onValueChanged'>
  >((event) => {
    // 関連先の選択値が変更されたらローカルステートに反映する
    setEntityRecordId(event.value)
  }, [])

  useEffect(() => {
    // フォーム初期化処理を実行
    initializeForm()
  }, [initializeForm])

  // アップロードエラー時
  const handleUploadError = useCallback(() => {
    setUploadedFile(undefined)
  }, [])

  return (
    <form className="flex flex-col gap-5 h-full">
      <CrewScrollView>
        {/* モーダルの最小幅を制限し、開始日、終了日、所有者など画面の各入力項目が正しく表示されるようにする */}
        <div className="overflow-x-auto">
          <div className="py-2 px-1 min-w-[640px]">
            <div className="flex flex-col gap-y-2.5 py-2 px-1 overflow-y-auto">
              <div
                className={classNames(
                  'gap-x-1.5',
                  // 表示しないだけで、裏で項目自体は持っていて良い。正しくダイレクトチャンネルに紐づくファイルとして登録されることを確認すること
                  isDirectChannel ? 'hidden' : 'flex'
                )}
              >
                <div className="w-48">
                  <CrewSelectBoxField
                    id="entityType"
                    name="entityType"
                    control={control}
                    dataSource={entityTypeDataSource}
                    labelMode="hidden"
                    valueExpr="id"
                    displayExpr="name"
                    searchEnabled={false}
                    // NOTE: searchEnabled=falseだとデフォルト値の"1"が適用となりloadされないので"0"を設定
                    minSearchLength={0}
                    label={t('label.entityType')}
                    required={true}
                    onValueChanged={handleEntityTypeChanged}
                    rules={validateRules.entityType}
                    readOnly={
                      props.entityRecordId || props.isEditMode ? true : false
                    }
                    showClearButton={false}
                  />
                </div>
                <div className="w-full">
                  <CrewSelectBoxField
                    id="entityRecordId"
                    name="entityRecordId"
                    control={control}
                    dataSource={entityRecordIdDataSource}
                    labelMode="hidden"
                    valueExpr="id"
                    displayExpr="name"
                    searchExpr="name"
                    searchEnabled={true}
                    // NOTE: searchEnabled=falseだとデフォルト値の"1"が適用となりloadされないので"0"を設定
                    minSearchLength={0}
                    label={t('label.entityRecord')}
                    required={true}
                    onValueChanged={handleEntityRecordIdChanged}
                    rules={validateRules.entityRecordId}
                    readOnly={
                      props.entityRecordId || props.isEditMode ? true : false
                    }
                    showClearButton={false}
                  />
                </div>
              </div>

              {/* フォルダ */}
              <CrewDropDownBoxTreeField
                control={control}
                id="folderId"
                name="folderId"
                rules={validateRules.folderId}
                label={t('label.folder')}
                required={true}
                dataSource={folderDataSource}
                displayExpr="name"
                valueExpr="id"
                parentIdExpr="parentFolderId"
                showClearButton={false}
              />

              {props.isEditMode && (
                <div>
                  <CrewTextBoxField
                    id="name"
                    name="name"
                    control={control}
                    labelMode="hidden"
                    label={t('label.fileName')}
                    required={true}
                    rules={validateRules.name}
                  />
                </div>
              )}
              <div>
                <CrewTagBoxField
                  id="tagIds"
                  name="tagIds"
                  control={control}
                  displayExpr="name"
                  valueExpr="id"
                  dataSource={tagDataSource}
                  searchEnabled={true}
                  searchMode="contains"
                  searchExpr="name"
                  searchTimeout={SEARCH_TIMEOUT_MSEC}
                  minSearchLength={0}
                  labelMode="hidden"
                  label={t('label.tag')}
                  acceptCustomValue={true}
                  onCustomItemCreating={handleAddCustomTagValue}
                />
              </div>
              <div>
                <CrewTextAreaField
                  id="description"
                  name="description"
                  control={control}
                  height={80}
                  labelMode="hidden"
                  label={t('label.description')}
                  rules={validateRules.description}
                />
              </div>
              <div className="h-28 flex flex-col gap-y-1">
                <CrewFileUploader
                  isShowDropZoneAlways={true}
                  // ファイルがアップロード済みなら配列化して渡す（単一アップロードなので1ファイルのみとなる）
                  uploadedFileList={uploadedFile ? [uploadedFile] : undefined}
                  onUploaded={handleUploaded}
                  onUploadError={handleUploadError}
                />
                {/* Input handle require upload file mode insert */}
                {!props.isEditMode && (
                  <CrewTextBoxField
                    id="uploadedFileKey"
                    name="uploadedFileKey"
                    control={control}
                    visible={false}
                    rules={validateRules.uploadedFileKey}
                    showLabel={false}
                  />
                )}
              </div>
              {/* 変更内容（ファイル履歴の説明） */}
              {isShowFileHistoryDescription && (
                <div>
                  <CrewTextAreaField
                    id="fileHistoryDescription"
                    name="fileHistoryDescription"
                    control={control}
                    height={80}
                    labelMode="hidden"
                    label={t('label.changes')}
                    rules={validateRules.description}
                  />
                </div>
              )}

              {/* チャットに投稿する */}
              {/* CREW-18170で、インスタントチャンネル(個人イベント)の場合はチャット投稿を行えないように対応した */}
              {getProjectResult?.project?.projectType !==
                ProjectType.InstantChannel && (
                <CrewCheckBoxField
                  id="needNotification"
                  name="needNotification"
                  control={control}
                  label={t('label.needNotification')}
                  defaultValue={false}
                />
              )}
            </div>
          </div>
          <CrewErrorSummary formState={formState} />
        </div>
      </CrewScrollView>
      <div className="flex justify-between items-center">
        {props.isEditMode && hasPrjFileDeletePermission && (
          <CrewButton
            text={t('action.delete')}
            type="danger"
            onClick={handleDeleteButtonClick}
            disabled={isLoadingDeleteFile}
          />
        )}

        <div className="ml-auto flex gap-x-5">
          <CrewButton
            text={t('action.register')}
            type="primary"
            disabled={
              !canClickRegisterButton ||
              isLoadingInsertFile ||
              isLoadingUpdateFile ||
              isLoadingInsertTaskFile ||
              isLoadingInsertEventFile
            }
            onClick={handleRegisterButtonClick}
          />
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={props.onCancel}
          />
        </div>
      </div>

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonDisabled={isLoadingDeleteFile}
      />

      {/* エラーダイアログ */}
      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />
    </form>
  )
})
