import { MeetingAttendeeJoinState } from '@crew/enums/domain'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// TODO: 暫定的にここに定義しているが、定義箇所は変えた方が良さそう。API側にも同じ内容の別定義があるのでそれも統一させる
// https://break-tmc.atlassian.net/browse/CREW-6905
export type MediaPipeline = {
  mediaPipelineArn: string
  mediaPipelineId: string
}

type WebMeetingStateType = {
  isHost: boolean
  isEndMeeting: boolean
  mediaPipeline: MediaPipeline | null
  joinState: MeetingAttendeeJoinState | null
}

const initialWebMeetingState: WebMeetingStateType = {
  isHost: false,
  isEndMeeting: false,
  mediaPipeline: null,
  joinState: null,
}

export const webMeetingSlice = createSlice({
  name: 'webMeeting',
  initialState: initialWebMeetingState,
  reducers: {
    // 会議の開催者判定を設定
    setIsHost: (state, action: PayloadAction<boolean>) => {
      state.isHost = action.payload
    },

    // 会議からの退室判定
    setIsEndMeeting: (state, action: PayloadAction<boolean>) => {
      state.isEndMeeting = action.payload
    },

    // mediaPipeline(Id,Arn)を設定
    setMediaPipeline: (state, action: PayloadAction<MediaPipeline | null>) => {
      state.mediaPipeline = action.payload
    },
    setJoinState: (state, action: PayloadAction<MeetingAttendeeJoinState>) => {
      state.joinState = action.payload
    },
  },
})

export const { setIsHost, setIsEndMeeting, setMediaPipeline, setJoinState } =
  webMeetingSlice.actions
