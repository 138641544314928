import { memo, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { UserPendingInfo, useSignupNewAccount } from './useSignupNewAccount'
import { CrewReCaptcha } from 'components/elements/crewReCaptcha/crewReCaptcha'
import {
  CrewAvatar,
  CrewAvatarShapeType,
} from 'components/elements/crewAvatar/crewAvatar'
import {
  DEFAULT_PASSWORD_COMPLEXITY,
  DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH,
  DEFAULT_PASSWORD_MIN_LENGTH,
  PASSWORD_SYMBOLS,
} from '@crew/configs/constants'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import { SettingKeyType } from '@crew/enums/app'
import { EntityType } from '@crew/enums/domain'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { ComponentCallbackHandler, convertStringToBoolean } from '@crew/utils'
import { useGetTenantQuery } from '@crew/apis/tenantSetting/tenantSettingApis'
import { useReCaptcha } from 'hooks/useReCaptcha'
import { FormValues } from './useSignupNewAccount'
import { useParams } from 'react-router-dom'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useToast } from 'hooks/useToast'
import { useWebsocketForceDisconnect } from '@crew/providers/websocket'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { setLoggedInUser } from 'features/app/states/appSlice'
import { useTranslation } from '@crew/modules/i18n'
import { resetReduxState } from 'states/store'
import { CrewTermsOfServiceAndPrivacyPolicyLink } from 'components/elements/crewTermsOfServiceAndPrivacyPolicyLink/crewTermsOfServiceAndPrivacyPolicyLink'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'

export type SignupNewAccountProps = {
  userPending: UserPendingInfo | null
}

export const SignupNewAccount = memo((props: SignupNewAccountProps) => {
  const themeMode = useAppSelector((state) => state.app.currentTheme)
  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  const {
    control,
    handleSubmit,
    formState,
    setError,
    clearErrors,
    validateRules,
    watch,
    trigger,

    setLoginIdValidationStatus,
    loginIdValidationStatus,
    joinTenant,
    login,

    verifyLoginId,
    isLoadingLogin,
    isLoadingNewUserJoinTenant,
  } = useSignupNewAccount(props.userPending)

  const { t } = useTranslation()
  const { error } = useToast()

  const { invitationToken } = useParams()
  const { navigate } = useCrewNavigate()
  const dispatch = useAppDispatch()

  const websocketForceDisconnect = useWebsocketForceDisconnect()

  const { isReCaptchaVerified } = useReCaptcha()

  // 利用規約とプライバシーポリシーへの同意チェック状態監視
  const agree = watch('agree')

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  //送信可能な状態か
  const canSend = useMemo(
    // formState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () =>
      formState.isDirty &&
      Object.keys(formState.errors).length === 0 &&
      isReCaptchaVerified &&
      !isLoadingLogin &&
      !isLoadingNewUserJoinTenant,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState, isReCaptchaVerified, isLoadingLogin, isLoadingNewUserJoinTenant]
  )

  // LoginId changed
  const handleLoginIdChanged = useCallback<
    ComponentCallbackHandler<typeof CrewTextBoxField, 'onValueChanged'>
  >(
    async (event) => {
      // Do nothing if no value is entered
      if (event.value.length === 0) {
        return
      }

      // ログインIDのバリデーションチェック
      const isValidationSuccessful = await trigger(`loginId`)
      if (!isValidationSuccessful) {
        // エラー時はフォームにエラー情報をセットする
        setLoginIdValidationStatus('invalid')
        setError('loginId', {
          type: 'manual',
          message: t('message.signup.register.invalidLoginId'),
        })
        return
      }

      clearErrors('loginId')

      // Set loading state before call api checking duplicate login ID
      setLoginIdValidationStatus('pending')

      const userId = event.value

      // 英大文字・英小文字・数字・ハイフンを使用できます。(4文字以上)
      const regexPattern = /^[[a-zA-Z0-9-]{4,}$/
      if (!regexPattern.test(userId)) {
        setLoginIdValidationStatus('invalid')
        setError('loginId', {
          type: 'manual',
          message: t('message.signup.register.invalidLoginId'),
        })
        return
      }

      // Call API check duplicate login ID
      await verifyLoginId(userId)
    },
    [
      clearErrors,
      setError,
      setLoginIdValidationStatus,
      t,
      trigger,
      verifyLoginId,
    ]
  )

  // 「登録して参加する」ボタンクリック
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (values: FormValues) => {
      try {
        if (!invitationToken) return

        // Execute join tenant process
        await joinTenant(values, invitationToken)

        //join tenant success, go to new arrival page
        // 念のためログイン前にwebsocketを強制切断しておく
        websocketForceDisconnect()

        // ログイン
        const response = await login(values.loginId, values.password)

        // if 2 step login enable so data user may undefined so need check response have data here
        if (!response.data) {
          error(t('message.auth.failedToLogin'))
          return
        }

        // reduxを初期化
        resetReduxState()

        // 秘匿ではないuser情報を保存
        dispatch(setLoggedInUser(response.data))

        // Redirect to new arrival page
        navigate('/newArrival')
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    invitationToken,
    joinTenant,
    websocketForceDisconnect,
    login,
    dispatch,
    navigate,
    error,
    t,
    showApiErrors,
  ])

  // get tenant
  const { data: getTenant } = useGetTenantQuery()

  // tenant avatar url
  // TODO: Web: generateImageAvatarUrlのリファクタリング
  // https://break-tmc.atlassian.net/browse/CREW-9777
  const tenantAvatarUrl = generateImageAvatarUrl(EntityType.Tenant)

  const tenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [
      SettingKeyType.OrganizationForceComplexPassword,
      SettingKeyType.OrganizationPasswordLength,
    ],
  }

  const { data: getTenantSettings } = useGetTenantSettingsQuery(
    tenantSettingsRequestParams
  )

  // 複雑なパスワードを強制するか
  const tenantPasswordComplex = useMemo(() => {
    if (getTenantSettings?.tenantSettings) {
      const organizationForceComplexPassword =
        getTenantSettings.tenantSettings.find(
          (setting) =>
            setting.key === SettingKeyType.OrganizationForceComplexPassword
        )

      return organizationForceComplexPassword?.value
        ? convertStringToBoolean(organizationForceComplexPassword?.value)
        : DEFAULT_PASSWORD_COMPLEXITY
    }
  }, [getTenantSettings?.tenantSettings])

  // パスワードの最小文字数
  const tenantPasswordLength = useMemo(() => {
    if (getTenantSettings?.tenantSettings) {
      const organizationPasswordLength = getTenantSettings.tenantSettings.find(
        (setting) => setting.key === SettingKeyType.OrganizationPasswordLength
      )

      return organizationPasswordLength?.value
        ? Number(organizationPasswordLength.value) // 設定値が保存されている場合、その値を設定
        : tenantPasswordComplex // 設定値が保存されていない場合は、「複雑なパスワードを強制するか」によって設定値を判定
        ? DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH // デフォルト値（複雑なパスワードを強制する場合）
        : DEFAULT_PASSWORD_MIN_LENGTH // デフォルト値（複雑なパスワードを強制しない場合）
    }
  }, [getTenantSettings?.tenantSettings, tenantPasswordComplex])

  return props.userPending ? (
    <div className="flex flex-row h-[100dvh] pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm justify-center">
        <div className="flex flex-col justify-center gap-y-2.5 items-center">
          {/* Logo */}
          <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
          <p className="text-center">{t('label.signupNewUserMessage')}</p>

          <div className="flex items-center gap-x-2">
            {/* Tenant avatar */}
            <CrewAvatar
              displayName={getTenant?.tenant?.name ?? '-'}
              imageURL={tenantAvatarUrl}
              shape={CrewAvatarShapeType.Square}
              cacheValue={
                getTenant?.tenant // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
                  ? getTenant.tenant.id + getTenant.tenant.version
                  : ''
              }
            />

            {/* Tenant name */}
            <p className="text-2xl">{getTenant?.tenant?.name}</p>
          </div>

          <p className="text-center">
            {t('label.signupNewUserMessage2', {
              email: props.userPending.destination,
            })}
          </p>
        </div>
        <form className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            {/* ログインID */}
            <div>
              <CrewTextBoxField
                id="loginId"
                name="loginId"
                control={control}
                placeholder={t('label.loginId')}
                labelMode="floating"
                rules={validateRules.loginId}
                inputAttr={{
                  'data-testid': 'loginId',
                }}
                showLabel={false}
                validationStatus={loginIdValidationStatus}
                onValueChanged={handleLoginIdChanged}
              />
              <span className="text-sm crew-text-gray-4">
                {t('label.loginIdPolicy')}
              </span>
            </div>

            {/*  氏名 */}
            <div>
              <CrewTextBoxField
                id="fullName"
                name="fullName"
                control={control}
                placeholder={t('label.displayName')}
                labelMode="floating"
                rules={validateRules.fullName}
                inputAttr={{
                  'data-testid': 'fullName',
                }}
                showLabel={false}
              />
            </div>

            {/* パスワード */}
            <div>
              <CrewTextBoxField
                id="password"
                name="password"
                control={control}
                placeholder={t('label.password')}
                labelMode="floating"
                rules={validateRules.password}
                mode="password"
                inputAttr={{
                  'data-testid': 'password',
                }}
                showLabel={false}
              />
            </div>

            {/* パスワード確認 */}
            <div>
              <CrewTextBoxField
                id="passwordConfirm"
                name="passwordConfirm"
                control={control}
                placeholder={t('label.confirmPassword')}
                labelMode="floating"
                rules={validateRules.passwordConfirm}
                mode="password"
                elementAttr={{
                  'data-testid': 'confirmPassword',
                }}
                showLabel={false}
              />
              {/* 「複雑なパスワードを強制する」ONの場合のみ、ポリシーを表記 */}
              {tenantPasswordComplex && (
                <span className="text-sm crew-text-gray-4">
                  {t('label.passwordComplexityPolicy', {
                    symbol: PASSWORD_SYMBOLS,
                    minLength: tenantPasswordLength,
                  })}
                </span>
              )}
            </div>

            {/* 「利用規約とプライバシーポリシーに同意します」 */}
            <div className="flex flex-row justify-center gap-3">
              <CrewCheckBoxField
                id="agree"
                name="agree"
                control={control}
                rules={validateRules.agree}
              />
              <CrewTermsOfServiceAndPrivacyPolicyLink />
            </div>
          </div>

          {/* 登録して参加する */}
          <div className="flex flex-row justify-center">
            <CrewButton
              className="grow"
              type="primary"
              disabled={!canSend || !agree}
              data-testid="registerAndJoin"
              text={t('action.registerAndJoin')}
              onClick={handleSubmitButtonClick}
            />
          </div>

          {/* Recaptcha */}
          <CrewReCaptcha />
        </form>
      </div>
    </div>
  ) : null
})
