import { useTranslation } from '@crew/modules/i18n'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { FC, memo } from 'react'

type Link = {
  title: string
  url: string
}

type AboutCardProps = {
  title: string
  urls: Link[]
}

export const AboutCard: FC<AboutCardProps> = memo((props) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-2.5">
      <div className="p-2 border-b-2 border-crew-cyan-600 font-bold text-2xl">
        {t(props.title)}
      </div>

      <div className="flex flex-col gap-5 p-2">
        {props.urls.map((item, index) => (
          <CrewLink
            key={index}
            title={t(item.title)}
            to={item.url}
            className="text-xl"
            target="_blank"
          >
            {t(item.title)}
          </CrewLink>
        ))}
      </div>
    </div>
  )
})
