import { Menu } from '@headlessui/react'
import {
  DropDownButtonClassNamesMap,
  DropDownButtonSize,
  DropDownButtonType,
  StylingMode,
} from '../../crewDropDownButton'
import classNames from 'classnames'
import ArrowDropDown from '~icons/material-symbols/arrow-drop-down'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { genericMemo } from 'utils'
import { useCallback } from 'react'

type ButtonProps<TItem> = {
  type: DropDownButtonType
  stylingMode: StylingMode
  className?: string
  onButtonClick?: (selectedItem?: TItem) => void
  render?: (itemData?: TItem) => React.ReactElement
  disabled?: boolean
  size: DropDownButtonSize
  selectedItem?: TItem
  text?: string
  icon?: React.ReactNode
  showArrowIcon: boolean
}

export const DropDownSplitButton = genericMemo(
  <TItem,>({
    type,
    stylingMode,
    className,
    onButtonClick,
    render,
    disabled,
    size,
    selectedItem,
    text,
    icon,
    showArrowIcon,
  }: ButtonProps<TItem>) => {
    // Handle button click
    const handleButtonClick = useCallback(() => {
      onButtonClick?.(selectedItem)
    }, [onButtonClick, selectedItem])

    return (
      <div className="flex items-stretch">
        <CrewButton
          type={type}
          stylingMode={stylingMode}
          size={size}
          text={text}
          icon={icon}
          render={render}
          disabled={disabled}
          className={classNames(
            '!rounded-r-none',
            stylingMode === StylingMode.outlined && '!border-r-0',
            className,
            disabled && 'pointer-events-none opacity-50'
          )}
          onClick={handleButtonClick}
        />

        <Menu.Button
          as="button"
          className={classNames(
            'crew-action-base !rounded-l-none py-0.5',
            DropDownButtonClassNamesMap[type][stylingMode],
            stylingMode === StylingMode.outlined && '!border-l-0',
            disabled && 'pointer-events-none opacity-50'
          )}
        >
          <div
            className={classNames(
              'h-full border-l flex items-center',
              SplitLineClassNamesMap[type][stylingMode]
            )}
          >
            {showArrowIcon && <ArrowDropDown />}
          </div>
        </Menu.Button>
      </div>
    )
  }
)

const SplitLineClassNamesMap = {
  primary: {
    contained: 'border-crew-blue-50 dark:border-crew-blue-50',
    outlined: 'border-crew-blue-500 dark:border-crew-blue-500',
    text: 'border-crew-blue-500 dark:border-crew-blue-500',
  },
  success: {
    contained: 'border-crew-green-50 dark:border-crew-green-50',
    outlined: 'border-crew-green-500 dark:border-crew-green-500',
    text: 'border-crew-green-500 dark:border-crew-green-500',
  },
  danger: {
    contained: 'border-crew-red-50 dark:border-crew-red-50',
    outlined: 'border-crew-red-500 dark:border-crew-red-500',
    text: 'border-crew-red-500 dark:border-crew-red-500',
  },
  normal: {
    contained: 'border-crew-gray-50 dark:border-crew-gray-50',
    outlined: 'border-crew-gray-900 dark:border-crew-gray-50',
    text: 'border-crew-gray-900 dark:border-crew-gray-50',
  },
} as const satisfies {
  [key in DropDownButtonType]: {
    [key in StylingMode]: string
  }
}
