import { FormEventHandler, memo, useCallback, useMemo, useState } from 'react'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { useAppAccountRegistration } from './useAppAccountRegistration'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import classNames from 'classnames'
import { CrewReCaptcha } from 'components/elements/crewReCaptcha/crewReCaptcha'
import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useToast } from 'hooks/useToast'
import { useAppSelector } from 'states/hooks'
import { useSearchParams } from 'react-router-dom'
import { useReCaptcha } from 'hooks/useReCaptcha'
import { FormValues } from './useAppAccountRegistration'
import { validateBillingCycle, validateContractPlan } from 'utils'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewTermsOfServiceAndPrivacyPolicyLink } from 'components/elements/crewTermsOfServiceAndPrivacyPolicyLink/crewTermsOfServiceAndPrivacyPolicyLink'
import { isApiErrorResult } from '@crew/apis/errors'
import { ContractPlan } from '@crew/enums/app'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { FAQ_URL } from '@crew/configs/constants'

export const AppAccountRegistration = memo(() => {
  const {
    control,
    handleSubmit,
    formState,
    validateRules,
    registerAccount,
    watch,
    isRequestAccountRegistrationLoading,
  } = useAppAccountRegistration()

  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const { error } = useToast()
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const [searchParams] = useSearchParams()
  const plan = searchParams.get('plan')
  const billingCycle = searchParams.get('billingCycle')
  const { isReCaptchaVerified } = useReCaptcha()

  const agree = watch('agree')

  const canSend = useMemo(
    // formState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () =>
      formState.isDirty &&
      Object.keys(formState.errors).length === 0 &&
      isReCaptchaVerified &&
      !isRequestAccountRegistrationLoading,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState, isReCaptchaVerified, isRequestAccountRegistrationLoading]
  )

  const isFreePlan = plan === ContractPlan.Free

  // 「無料で試してみる」ボタンクリック
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (values: FormValues) => {
      // プランの妥当性チェック
      if (!validateContractPlan(plan)) {
        error(t('message.signup.invalidPlan'))
        return
      }
      // 請求サイクルの妥当性チェック
      if (!validateBillingCycle(billingCycle)) {
        error(t('message.signup.invalidBillingCycle'))
        return
      }

      try {
        // Execute register account process
        await registerAccount(values, plan, billingCycle)

        navigate('/verify_email')
      } catch (error) {
        if (window.grecaptcha) {
          window.grecaptcha.reset()
        }

        // ApiErrorの場合はcodeから文字列リソースを取得する
        const decodedError = isApiErrorResult(error)
        if (decodedError) {
          setServerErrorMessage(
            t(
              `message.apiError.${error.data.code}`,
              error.data as { [key: string]: any }
            )
          )
        } else {
          // ApiErrorでない場合はエラー自体が不明エラーを表示する
          setServerErrorMessage(t('message.apiError.commonHttpError'))
        }
      }
    }
    handleSubmit(onSubmit)()
  }, [handleSubmit, plan, registerAccount, billingCycle, navigate, t, error])

  // clear message error by server when change text value
  const handleEmailTextBoxValueChanged = useCallback(() => {
    setServerErrorMessage('')
  }, [])

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  const errorMessageBaseClassNames = useMemo(() => {
    return 'crew-error-text text-start text-sm'
  }, [])

  // Form内Enterキー押下によるSubmit制御
  // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
  //       https://break-tmc.atlassian.net/browse/CREW-3338
  const handleFormSubmit = useCallback<FormEventHandler>((event) => {
    event.preventDefault()
  }, [])

  return (
    <div className="flex flex-row h-[100dvh] pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm">
          <div className="flex flex-row items-center sm:mb-6">
            {/* ロゴ */}
            <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
          </div>
          <form
            className="flex flex-col gap-1"
            // Form内Enterキー押下によるSubmit制御
            // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
            //       https://break-tmc.atlassian.net/browse/CREW-3338
            onSubmit={handleFormSubmit}
          >
            <div className="flex flex-col gap-1">
              <span className="text-center text-md font-normal leading-tight py-2.5 whitespace-pre-wrap">
                {t('label.tenantSignUpDescription')}
              </span>
              <div>
                <CrewTextBoxField
                  id="email"
                  name="email"
                  control={control}
                  placeholder={t('label.mailAddress')}
                  labelMode="floating"
                  rules={validateRules.email}
                  inputAttr={{
                    'data-testid': 'username',
                  }}
                  showLabel={false}
                  onValueChanged={handleEmailTextBoxValueChanged}
                />
              </div>
              {/* サーバーエラーメッセージ */}
              <div
                className={classNames(errorMessageBaseClassNames, {
                  'h-4': serverErrorMessage.length > 0,
                })}
                data-testid="login-form-server-error-message"
              >
                {serverErrorMessage}
              </div>
            </div>
            {/* 「利用規約とプライバシーポリシーに同意します」 */}
            <div className="flex flex-row justify-center gap-3 py-2.5">
              <CrewCheckBoxField
                id="agree"
                name="agree"
                control={control}
                rules={validateRules.agree}
              />
              <CrewTermsOfServiceAndPrivacyPolicyLink />
            </div>
            {/* SignUp button */}
            <div className="flex flex-row justify-center">
              <CrewButton
                className="grow"
                type="primary"
                disabled={!canSend || !agree}
                data-testid="signup"
                text={
                  isFreePlan
                    ? t('action.tryFreePlan')
                    : t('action.startFreeTrial')
                }
                onClick={handleSubmitButtonClick}
              />
            </div>

            {/* Recaptcha */}
            <CrewReCaptcha />
          </form>
        </div>
        {/* 注意事項（無料トライアルの場合のみ表示） */}
        {!isFreePlan && (
          <div className="flex flex-col gap-2.5 max-w-xl">
            <div className="flex flex-col items-center justify-between text-lg font-bold mt-5">
              <div>{t('label.notes')}</div>
            </div>
            <div>{t('message.startFreeTrialNotes.availableFeatures')}</div>
            <div className="leading-tight whitespace-pre-wrap">
              {t('message.startFreeTrialNotes.afterTheTrialEnds')}
            </div>
            <div>
              {t('message.startFreeTrialNotes.needHelp.prefix')}
              <CrewLink to={FAQ_URL} target="_blank">
                {t('message.startFreeTrialNotes.needHelp.link')}
              </CrewLink>
              {t('message.startFreeTrialNotes.needHelp.suffix')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
