import { CrewSelectBox } from 'components/devextreme/crewSelectBox'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { useMyTaskListSearchPanel } from './useMyTaskListSearchPanel'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUserSetting } from '@crew/states'
import { SettingKeyType } from '@crew/enums/app'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { TextBox } from 'devextreme-react'
import { debounce } from 'lodash'
import { ComponentCallbackHandler, isEqualParams } from '@crew/utils'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import qs from 'qs'
import { TaskKindFilters } from 'enums/app'
import { TaskKindRef } from '@crew/models/refs'
import { useValueChangeEffect } from '@crew/hooks'
import { getParamAsString } from 'utils'

type GroupComponentType<T> = {
  data: {
    items: T[]
    key: string
  }
}

// Render task type group name
const renderTaskKindGroupName = ({ data }: GroupComponentType<TaskKindRef>) => {
  // プロジェクトに紐づかないデータは仕様上ありえないが、型制約の関係上チェック処理を追加
  const item = data.items.find((item) => item.projectId)

  if (!item) {
    return null
  }

  return <span>{item.projectName}</span>
}

export const MyTaskListSearchPanel = memo(() => {
  const { taskKindDataSource } = useMyTaskListSearchPanel()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const params = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  )

  const [taskKindId, setTaskKindId] = useState<string>(
    TaskKindFilters.AllTaskKinds
  )

  const [keyword, setKeyword] = useState<string>('')

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  //キーワード検索
  const keywordTextBoxRef = useRef<TextBox>(null)

  //delay search when keyup keyword search after 500ms
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        // nullや空文字はundefinedに置き換える
        const newParams = {
          ...params,
          keyword: value || undefined,
          // ページ数：1ページ目を指定
          pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
          // ページサイズ：現在の値を引き継ぐ
          // 現在値がない場合は、ユーザー設定の値を使用し、それも無ければデフォルト値を使用する
          pageSize: params.pageSize ?? Number(defaultListDisplayNumber),
        }

        /* Do not navigate if the parameters have not changed
         * 1. The parameters have not changed
         * 2. The keyword has not changed
         * 3. The input keyword is an empty string, and the URL keyword parameter is not specified.
         *  - The initial URL when entering "My Tasks" is "/myTask" and there is no keyword parameter.
         *    Therefore, I use (value === '' && !params.keyword) to check the case where the
         *    "back button is pressed to return to the original URL /myTask" so that the process will stop.
         */
        if (
          isEqualParams(params, newParams) ||
          value === params.keyword ||
          (value === '' && !params.keyword)
        )
          return

        const newQueryString = qs.stringify(newParams, {
          arrayFormat: 'repeat',
          skipNulls: true,
        })

        navigate(`?${newQueryString}`)
      }, SEARCH_TIMEOUT_MSEC),
    [defaultListDisplayNumber, navigate, params]
  )

  const handleKeywordTextBoxValueChanged = useCallback(() => {
    // onInputイベントでは入力値をvalueで取得できないので、instanceから直接取得する
    const input = keywordTextBoxRef.current?.instance.option('text')

    if (input !== undefined) {
      setKeyword(input)
      debouncedSearch(input)
    }
  }, [debouncedSearch])

  // クエリーパラメータが変わったら、検索条件に反映する
  useValueChangeEffect(
    () => {
      setKeyword(getParamAsString('keyword', params) ?? '')
      setTaskKindId(
        getParamAsString('taskKindId', params) ?? TaskKindFilters.AllTaskKinds
      )
    },
    [params],
    params
  )

  const handleTaskKindChanged = useCallback<
    ComponentCallbackHandler<typeof CrewSelectBox, 'onValueChanged'>
  >(
    (event) => {
      // nullや空文字はundefinedに置き換える
      const newParams = {
        ...params,
        taskKindId: event.value || undefined,
        // ページ数：1ページ目を指定
        pageIndex: DEFAULT_PAGING_PARAMS.pageIndex,
        // ページサイズ：現在の値を引き継ぐ
        // 現在値がない場合は、ユーザー設定の値を使用し、それも無ければデフォルト値を使用する
        pageSize: params.pageSize ?? Number(defaultListDisplayNumber),
      }

      // paramsが変わっていない場合はnavigateしない
      if (
        isEqualParams(params, newParams) ||
        event.value === params.taskKindId ||
        (event.value === TaskKindFilters.AllTaskKinds && !params.taskKindId)
      )
        return

      setTaskKindId(event.value)
      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [defaultListDisplayNumber, navigate, params]
  )

  return (
    <div className="flex-1 flex gap-x-2.5 items-center">
      {/* タスク種別 */}
      <CrewSelectBox
        value={taskKindId}
        dataSource={taskKindDataSource}
        displayExpr="name"
        valueExpr="id"
        searchEnabled={false}
        minSearchLength={0}
        showClearButton={false}
        onValueChanged={handleTaskKindChanged}
        groupComponent={renderTaskKindGroupName}
        grouped
      />
      {/* キーワード */}
      <CrewTextBox
        value={keyword}
        valueChangeEvent="input change"
        onValueChanged={handleKeywordTextBoxValueChanged}
        showClearButton={true}
        ref={keywordTextBoxRef}
        mode="search"
      />
    </div>
  )
})
