import MoreHoriz from '~icons/material-symbols/more-horiz'
import FolderOutline from '~icons/material-symbols/folder-outline'
import { FC, memo, useCallback, useState } from 'react'
import { UnsortedFolder } from 'enums/app'
import { useHasHover } from 'hooks/useHasHover'

type Folder = {
  id: string
  name: string
  version: number
}

type FolderItemProps = {
  folder: Folder
  onOpenFolderEntryDialog: () => void
  onOpenContextMenu: (folder: Folder) => void
  showContextMenu?: boolean
  isActive: boolean
}

export const FolderItem: FC<FolderItemProps> = memo((props) => {
  const hasHover = useHasHover()

  const [hover, setHover] = useState(false)

  // ホバーメニューを表示
  const handleMouseEnter = useCallback(() => {
    setHover(true)
  }, [])

  // ホバーメニューを非表示
  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  // Event handler for opening the context menu
  const handleOpenContextMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      props.onOpenContextMenu(props.folder)

      // stop parent event
      event.stopPropagation()
    },
    [props]
  )

  return (
    <div
      // Add padding right to the folder name for the context menu button
      className="flex gap-1.5 w-full items-center relative pr-6 py-1.5"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FolderOutline width={24} height={24} className="shrink-0" />
      <span className="line-clamp-1"> {props.folder.name}</span>

      {/* Menu context button */}
      {props.folder.id !== UnsortedFolder.value && // 1. ソート不可であること
        props.showContextMenu && // 2. メニューが有効であること
        (hasHover
          ? hover // 3-1. ホバー有効ならホバー状態であること
          : props.isActive) && ( // 3-2. ホバー無効ならアクティブ状態であること
          <span
            className="ml-auto absolute right-0"
            id={`context-menu-${props.folder.id}`}
            onClick={handleOpenContextMenu}
          >
            <MoreHoriz width={20} height={20} />
          </span>
        )}
    </div>
  )
})
