import { useEffectOnce } from '@dx-system/react-use'
import { isSupported } from 'firebase/messaging'
import { useState } from 'react'

// push通知に対応しているかどうかを判定するカスタムフック。判定中の場合はundefinedを返す
export const useIsPushNotificationSupported = () => {
  const [isPushNotificationSupported, setIsPushNotificationSupported] =
    useState<boolean | undefined>()

  useEffectOnce(() => {
    const init = async () => {
      const supported = await isSupported()
      setIsPushNotificationSupported(supported)
    }

    init()
  })

  return isPushNotificationSupported
}
