var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
import i18n from 'i18next';
import { resources } from './resources';
import { initReactI18next } from 'react-i18next';
import { Language } from '@crew/enums/i18n';
// TODO:resourcesの型をtまで適用する
// https://react.i18next.com/latest/typescript
// https://break-tmc.atlassian.net/browse/CREW-1299?atlOrigin=eyJpIjoiYmMyNGY3ZmM4N2IxNGY1MTkyNDEwNTg0NTM1MDc5ZmEiLCJwIjoiaiJ9
// TODO:i18nextのresources、interpolationを棚卸しする
// https://break-tmc.atlassian.net/browse/CREW-1300?atlOrigin=eyJpIjoiMzRiMDRkMGE4NWZiNDcyZmFhZTZmZmJmNzI5ODdhZWIiLCJwIjoiaiJ9
i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: Language.Ja,
    fallbackLng: Language.Ja,
    resources,
    interpolation: {
        escapeValue: false,
    },
    // TODO: CREW-9341 i18next v22 のデフォルト設定が破壊的変更をされたので戻す。v23からは不要になる見込
    // https://break-tmc.atlassian.net/browse/CREW-9341
    // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
    // https://github.com/i18next/i18next/issues/1884
    returnNull: false,
});
export const DefaultTimeStampFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
};
export const DefaultDateTimeFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
};
export const DefaultDateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
};
export const DefaultShortDateFormat = {
    month: '2-digit',
    day: '2-digit',
};
export const DefaultShortDateWithTextFormat = {
    month: 'numeric',
    day: 'numeric',
};
export const DefaultYearMonthWithTextFormat = {
    year: 'numeric',
    month: 'numeric',
};
export const DefaultTimeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
};
export const DefaultShortTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
};
export const DefaultRelativeTimeFormat = {
    numeric: 'auto',
    style: 'narrow',
};
export const DefaultNumberFormat = {};
export const DefaultCurrencyFormat = {
    style: 'currency',
    // currency: 'JPY',
    // 通貨は言語やロケールで決定されるとは限らない
    // 呼び出し側で指定すること
    // usage: t('format.currency', { value: 123456789, currency: 'JPY' })
};
export const DefaultDateWithTextFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};
export const DafaultDateWithDayOfWeek = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
};
export const DefaultYearWithTextFormat = {
    year: 'numeric',
};
export const DefaultDayOfWeekFormat = {
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
};
export const DefaultDayOfWeekShortFormat = {
    day: 'numeric',
    weekday: 'short',
};
export const DefaultWeekDayFormat = {
    weekday: 'short',
};
export const DefaultWeekDayLongFormat = {
    weekday: 'long',
};
export const DefaultWeekOrdinalFormat = {
    style: 'unit',
};
export const DefaultLongMonthFormat = {
    month: 'long',
};
export const DefaultMonthDayTimeFormat = {
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
};
// 例 2022/01/01 09:00:00
(_a = i18n.services.formatter) === null || _a === void 0 ? void 0 : _a.add('timestamp', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultTimeStampFormat), { timeZone: options.timeZone })).format(Date.parse(value));
});
// 例 2022/01/01 09:00
(_b = i18n.services.formatter) === null || _b === void 0 ? void 0 : _b.add('datetime', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultDateTimeFormat), { timeZone: options.timeZone })).format(Date.parse(value));
});
// 例 2022/01/01
(_c = i18n.services.formatter) === null || _c === void 0 ? void 0 : _c.add('date', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultDateFormat), options)).format(Date.parse(value));
});
// 例 01/01
(_d = i18n.services.formatter) === null || _d === void 0 ? void 0 : _d.add('shortdate', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultShortDateFormat), options)).format(Date.parse(value));
});
// 例 09:00:00
(_e = i18n.services.formatter) === null || _e === void 0 ? void 0 : _e.add('time', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultTimeFormat), options)).format(Date.parse(value));
});
// 例 09:00
(_f = i18n.services.formatter) === null || _f === void 0 ? void 0 : _f.add('shorttime', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultShortTimeFormat), options)).format(Date.parse(value));
});
// 例 12/23 09:00
(_g = i18n.services.formatter) === null || _g === void 0 ? void 0 : _g.add('monthDayTime', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultMonthDayTimeFormat), options)).format(Date.parse(value));
});
// 例 5日後, 5日前
(_h = i18n.services.formatter) === null || _h === void 0 ? void 0 : _h.add('relativetime', (value, lng, options) => {
    const rtf = new Intl.RelativeTimeFormat(lng, Object.assign(Object.assign({}, DefaultShortTimeFormat), options));
    return rtf.format(value, 'day');
});
// 例
// （同じ日の場合） 2021/11/30 10:30 - 13:00
// （違う日の場合） 2021/11/30 10:30 - 2021/12/01 19:30
(_j = i18n.services.formatter) === null || _j === void 0 ? void 0 : _j.add('daterange', (value, lng, options) => {
    const startDate = new Date(value.start);
    const endDate = new Date(value.end);
    const isStartEndSameDay = startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();
    const startFormat = Intl.DateTimeFormat(lng, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    }).format(Date.parse(value.start));
    const endFormat = isStartEndSameDay
        ? Intl.DateTimeFormat(lng, {
            hour: '2-digit',
            minute: '2-digit',
        }).format(Date.parse(value.end))
        : Intl.DateTimeFormat(lng, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).format(Date.parse(value.end));
    return `${startFormat} - ${endFormat}`;
});
// 例 123,456,789
(_k = i18n.services.formatter) === null || _k === void 0 ? void 0 : _k.add('number', (value, lng, options) => {
    return Intl.NumberFormat(lng, Object.assign(Object.assign({}, DefaultNumberFormat), options)).format(value);
});
// 例 \123,456,789
(_l = i18n.services.formatter) === null || _l === void 0 ? void 0 : _l.add('currency', (value, lng, options) => {
    if (options.currency) {
        return Intl.NumberFormat(lng, Object.assign(Object.assign({}, DefaultCurrencyFormat), options)).format(value);
    }
    else {
        // 通貨の指定がない場合は、既定の数値フォーマットで返す
        return Intl.NumberFormat(lng, DefaultNumberFormat).format(value);
    }
});
// 例 2022年1月1日
(_m = i18n.services.formatter) === null || _m === void 0 ? void 0 : _m.add('dateWithText', (value, lng, options) => {
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultDateWithTextFormat), options));
    const parts = rtf.formatToParts(date);
    if (lng === Language.Ja) {
        // Manually construct the formatted date string
        const formattedDate = parts
            .map((part) => {
            switch (part.type) {
                case 'year':
                    return part.value + '年';
                case 'month':
                    return part.value + '月';
                case 'day':
                    return part.value + '日';
            }
        })
            .join('');
        return formattedDate;
    }
    return rtf.format(value);
});
// 例 1月1日
(_o = i18n.services.formatter) === null || _o === void 0 ? void 0 : _o.add('shortDateWithText', (value, lng, options) => {
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultShortDateWithTextFormat), options));
    const parts = rtf.formatToParts(date);
    if (lng === Language.Ja) {
        // Manually construct the formatted date string
        const formattedDate = parts
            .map((part) => {
            switch (part.type) {
                case 'month':
                    return part.value + '月';
                case 'day':
                    return part.value + '日';
            }
        })
            .join('');
        return formattedDate;
    }
    return rtf.format(value);
});
// 例 5月9日(木)
(_p = i18n.services.formatter) === null || _p === void 0 ? void 0 : _p.add('dayOfWeek', (value, lng, options) => {
    var _a, _b, _c;
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultDayOfWeekFormat), options));
    const parts = rtf.formatToParts(date);
    if (lng === Language.Ja) {
        const month = (_a = parts.find((part) => part.type === 'month')) === null || _a === void 0 ? void 0 : _a.value;
        const day = (_b = parts.find((part) => part.type === 'day')) === null || _b === void 0 ? void 0 : _b.value;
        const weekday = (_c = parts.find((part) => part.type === 'weekday')) === null || _c === void 0 ? void 0 : _c.value;
        return `${month}月${day}日(${weekday})`;
    }
    return rtf.format(value);
});
// 例 9日(木)
(_q = i18n.services.formatter) === null || _q === void 0 ? void 0 : _q.add('dayOfWeekShort', (value, lng, options) => {
    var _a, _b;
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultDayOfWeekShortFormat), options));
    const parts = rtf.formatToParts(date);
    if (lng === Language.Ja) {
        const day = (_a = parts.find((part) => part.type === 'day')) === null || _a === void 0 ? void 0 : _a.value;
        const weekday = (_b = parts.find((part) => part.type === 'weekday')) === null || _b === void 0 ? void 0 : _b.value;
        return `${day}日(${weekday})`;
    }
    return rtf.format(value);
});
// 例 2022年
(_r = i18n.services.formatter) === null || _r === void 0 ? void 0 : _r.add('yearWithText', (value, lng, options) => {
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultYearWithTextFormat), options));
    const parts = rtf.formatToParts(date);
    if (lng === Language.Ja) {
        // Manually construct the formatted date string
        const formattedDate = parts
            .map((part) => {
            if (part.type === 'year') {
                return part.value + '年';
            }
        })
            .join('');
        return formattedDate;
    }
    return rtf.format(value);
});
// 例 2023年4月28日(月)
(_s = i18n.services.formatter) === null || _s === void 0 ? void 0 : _s.add('dateWithDayOfWeek', (value, lng, options) => {
    var _a, _b, _c, _d;
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DafaultDateWithDayOfWeek), options));
    const parts = rtf.formatToParts(date);
    if (lng === Language.Ja) {
        const year = (_a = parts.find((part) => part.type === 'year')) === null || _a === void 0 ? void 0 : _a.value;
        const month = (_b = parts.find((part) => part.type === 'month')) === null || _b === void 0 ? void 0 : _b.value;
        const day = (_c = parts.find((part) => part.type === 'day')) === null || _c === void 0 ? void 0 : _c.value;
        const weekday = (_d = parts.find((part) => part.type === 'weekday')) === null || _d === void 0 ? void 0 : _d.value;
        return `${year}年${month}月${day}日(${weekday})`;
    }
    return rtf.format(value);
});
// 例 2023年4月
(_t = i18n.services.formatter) === null || _t === void 0 ? void 0 : _t.add('yearMonthWithText', (value, lng, options) => {
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultYearMonthWithTextFormat), options));
    const parts = rtf.formatToParts(date);
    if (lng === Language.Ja) {
        // Manually construct the formatted date string
        const formattedDate = parts
            .map((part) => {
            switch (part.type) {
                case 'year':
                    return part.value + '年';
                case 'month':
                    return part.value + '月';
            }
        })
            .join('');
        return formattedDate;
    }
    return rtf.format(value);
});
// 例 日曜
(_u = i18n.services.formatter) === null || _u === void 0 ? void 0 : _u.add('weekday', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultWeekDayFormat), options)).format(Date.parse(value));
});
// 例 日曜日
(_v = i18n.services.formatter) === null || _v === void 0 ? void 0 : _v.add('longWeekday', (value, lng, options) => {
    return Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultWeekDayLongFormat), options)).format(Date.parse(value));
});
// 例 1st 2nd 3rd 4th 5th
(_w = i18n.services.formatter) === null || _w === void 0 ? void 0 : _w.add('numberOrdinal', (value, lng, options) => {
    // Suffixes for week ordinal
    const suffixes = ['th', 'st', 'nd', 'rd'];
    if (lng === Language.En) {
        return `${value}${suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]}`;
    }
    return Intl.NumberFormat(lng, Object.assign(Object.assign({}, DefaultWeekOrdinalFormat), options)).format(value);
});
// 例 ja: 1月, en: January
(_x = i18n.services.formatter) === null || _x === void 0 ? void 0 : _x.add('longMonth', (value, lng, options) => {
    const date = new Date(value);
    const rtf = new Intl.DateTimeFormat(lng, Object.assign(Object.assign({}, DefaultLongMonthFormat), options));
    if (lng === Language.Ja) {
        // get the month value
        const month = date.getMonth() + 1;
        return month.toString();
    }
    return rtf.format(value);
});
export default i18n;
