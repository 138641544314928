import {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import Scheduler, {
  Resource,
  SchedulerTypes,
  View,
} from 'devextreme-react/scheduler'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { useProjectDetailEventCalendar } from './useProjectDetailEventCalendar'
import { useParams } from 'react-router-dom'
import { DateCell } from './components/dateCell/dateCell'
import { DataCell } from './components/dataCell/dataCell'
import { useTranslation } from '@crew/modules/i18n'
import { Appointment } from './components/appointment/appointment'
import {
  CrewEventPopover,
  EventPopover,
} from 'components/elements/crewEventPopover/crewEventPopover'
import { useAppSelector } from 'states/hooks'
import { ContentReadyEvent } from 'devextreme/ui/scheduler'
import Toolbar from 'devextreme/ui/toolbar'
import { isArray } from 'lodash'
import { ProjectDetailEventTimePeriod } from 'enums/app'
import { useModal } from 'components/layouts/modal/useModal'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'
import { useValueChangeEffect } from '@crew/hooks'
import { EventKindRef, UserRef, ProjectRef } from '@crew/models/refs'
import { ProjectType } from '@crew/enums/domain'
import { EventEntryDialog } from 'features/event/components/eventEntryDialog/eventEntryDialog'
import { CrewScheduler } from 'components/devextreme/crewScheduler/crewScheduler'
import { CrewTimeCell } from 'components/devextreme/crewScheduler/components/crewTimeCell/crewTimeCell'
import { CrewUserItem } from 'components/elements/crewUserItem/crewUserItem'
import { ValueChangedEvent } from 'devextreme/ui/select_box'
import classNames from 'classnames'
import {
  CrewBadgeInvertedColorClass,
  CrewBadgeInvertedColorToHex,
} from 'enums/color'
import { WeekAppointment } from './components/weekAppointment/weekAppointment'

// Render resource cell (user)
const renderResourceCell = ({ data }: { data: UserRef }) => {
  return (
    <div className="py-0.5">
      <CrewUserItem
        id={data.id}
        displayName={data.displayName}
        version={data.version}
      />
    </div>
  )
}

// Render time cell
const renderTimeCell = ({ date, text }: { date: Date; text: string }) => {
  return <CrewTimeCell date={date} displayTime={Boolean(text)} />
}

const groups = ['user.id']

const TOOLBAR_ITEM_VIEW_SELECT_BOX = 'ViewSelectBox'

type Event = {
  id: string | null
  subject: string | null
  description: string | null
  startDatetime: string
  endDatetime: string
  entityRecordId: string | null
  eventKind: EventKindRef | null
  eventAttendees: UserRef[]
  project: ProjectRef
  projectType: ProjectType
  isAllDay: boolean
}

type AppointmentType = {
  data: { appointmentData: Event }
}

export const ProjectDetailEventCalendar: FC = memo(() => {
  const { t } = useTranslation()
  const { projectId } = useParams()

  const schedulerRef = useRef<Scheduler>(null)

  const [currentDate, setCurrentDate] = useState(new Date())
  const [currentView, setCurrentView] = useState<string>(
    ProjectDetailEventTimePeriod.Monthly.value
  )

  const [isEventEntryDialogOpen, openEventEntryDialog, closeEventEntryDialog] =
    useModal()

  const {
    eventKindDataSource,
    eventDataSource,
    projectMembersDataSource,
    timePeriodDataSource,
    eventScheduleGroupMonthDataSource,
  } = useProjectDetailEventCalendar(projectId, schedulerRef, currentDate)

  const eventEventMessage = useAppSelector(
    (state) => state.app.eventEventMessage
  )

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  useValueChangeEffect(
    () => {
      eventDataSource.reload()
    },
    [eventEventMessage, eventDataSource],
    eventEventMessage
  )

  // Event handler for when the view is changed
  const handleCurrentViewChange = useCallback((event: ValueChangedEvent) => {
    setCurrentView(event.value)
  }, [])

  // Event handler for when the to day button is clicked
  const handleTodayButtonClick = useCallback(() => {
    schedulerRef.current?.instance.scrollTo(new Date())
  }, [])

  // Event handler for when the content is ready
  // https://supportcenter.devexpress.com/ticket/details/t958543/scheduler-customize-toolbar
  const onContentReady = useCallback(
    (e: ContentReadyEvent) => {
      // Scroll to the current day
      e.component.scrollTo(new Date())

      // find group header
      const allDayHeaderElement = e.element.querySelector(
        '.dx-scheduler-header-panel-empty-cell .dx-scheduler-all-day-title'
      )

      if (allDayHeaderElement) {
        // set all day header text
        allDayHeaderElement.textContent = t('label.allDay')
        allDayHeaderElement.classList.add('font-normal')
      }

      const dxToolbar = e.element.querySelector('.dx-toolbar')
      if (!dxToolbar) return

      const toolbarInstance = Toolbar.getInstance(dxToolbar)

      const toolbarItems = toolbarInstance.option('items')

      // If the toolbar items are the default count, add the select box and button
      if (isArray(toolbarItems) && toolbarItems.length === 2) {
        // check has toolbar item view select box
        if (
          toolbarItems.find(
            (item) => item.name === TOOLBAR_ITEM_VIEW_SELECT_BOX
          )
        ) {
          return
        }

        const newToolbarItems = [toolbarItems[0]]

        if (currentView === ProjectDetailEventTimePeriod.GroupMonthly.value) {
          newToolbarItems.push({
            widget: 'dxButton',
            location: 'after',
            options: {
              text: t('action.toDay'),
              onClick: handleTodayButtonClick,
            },
          })
        }

        newToolbarItems.push({
          widget: 'dxSelectBox',
          name: TOOLBAR_ITEM_VIEW_SELECT_BOX,
          options: {
            dataSource: timePeriodDataSource,
            displayExpr: 'name',
            valueExpr: 'id',
            value: currentView,
            onValueChanged: handleCurrentViewChange,
            deferRendering: false,
          },
          location: 'after',
        })

        toolbarInstance.option('items', newToolbarItems)
      }
    },
    [
      currentView,
      handleCurrentViewChange,
      handleTodayButtonClick,
      t,
      timePeriodDataSource,
    ]
  )

  // Customize date navigator display text
  const customizeDateNavigatorText = useCallback(
    (info: SchedulerTypes.DateNavigatorTextInfo) => {
      const currentView = schedulerRef.current?.instance.option('currentView')
      if (currentView === 'week') {
        const startDate = t('format.dateWithText', {
          value: info.startDate,
        })

        const endDate = t('format.dateWithText', {
          value: info.endDate,
        })
        return `${startDate} - ${endDate}`
      } else {
        return t('format.yearMonthWithText', {
          value: info.startDate,
        })
      }
    },
    [t]
  )

  // Event handler for when the current date is changed
  const handleCurrentDateChange = useCallback(
    (value: string | number | Date) => {
      setCurrentDate(new Date(value))
    },
    []
  )

  // Event handler for when an appointment is rendered
  const handleAppointmentRendered = useCallback(
    (e: SchedulerTypes.AppointmentRenderedEvent) => {
      // If is private event (id is null), set the background color to gray
      if (!e.appointmentData.id) {
        e.appointmentElement.style.backgroundColor = '#d4d4d4'
        // Add disabled class
        e.appointmentElement.className += ' dx-state-disabled'
      } else {
        if (e.appointmentData.eventKind) {
          // Set the background color to the event color
          e.appointmentElement.style.backgroundColor =
            CrewBadgeInvertedColorToHex[
              e.appointmentData.eventKind
                .displayColor as CrewBadgeInvertedColorClass
            ]
        }
      }
    },
    []
  )

  // Event handler for when an appointment is clicked
  const handleAppointmentClick = useCallback(
    (e: SchedulerTypes.AppointmentClickEvent) => {
      // If is private event (id is null), cancel the event
      if (!e.appointmentData.id) {
        e.cancel = true
      }
    },
    []
  )

  // Event handler for when an appointment is double clicked
  const handleAppointmentDblClick = useCallback(
    (e: SchedulerTypes.AppointmentDblClickEvent) => {
      e.cancel = true

      // If is private event (id is null), cancel the event
      if (!e.appointmentData.id) return
    },
    []
  )

  // close appointment tooltip
  const handleCloseTooltip = useCallback(() => {
    schedulerRef.current?.instance.hideAppointmentTooltip()
  }, [])

  // Render appointment tooltip
  const renderAppointmentTooltip = useCallback(
    (item: AppointmentType) => {
      const data: EventPopover = {
        id: item.data.appointmentData.id,
        description: item.data.appointmentData.description,
        attendees: item.data.appointmentData.eventAttendees,
        startDatetime: item.data.appointmentData.startDatetime,
        endDatetime: item.data.appointmentData.endDatetime,
        entityRecordId: item.data.appointmentData.entityRecordId,
        eventKind: item.data.appointmentData.eventKind,
        subject: item.data.appointmentData.subject,
        project: item.data.appointmentData.project,
        isAllDay: item.data.appointmentData.isAllDay,
      }
      return (
        <CrewEventPopover
          key={data.id}
          data={data}
          onCloseTooltip={handleCloseTooltip}
        />
      )
    },
    [handleCloseTooltip]
  )

  // Register event finish
  const handleEventRegistered = useCallback(
    (eventId: string, deleteFlg: boolean = false) => {
      // Close entry dialog
      closeEventEntryDialog()
    },
    [closeEventEntryDialog]
  )

  const startDatetime = useRef<Date>()
  const endDatetime = useRef<Date>()

  // 空欄をクリックすると新規イベント登録画面を表示する。
  const handleOpenEventEntryDialog = useCallback(
    (e: SchedulerTypes.AppointmentFormOpeningEvent) => {
      e.cancel = true

      if (currentView === ProjectDetailEventTimePeriod.Monthly.value) {
        // 開始日/終了日=クリックした日付
        // 開始時刻=システム時刻(分は切り上げ)
        // 終了時刻=開始時刻+1時間
        startDatetime.current = new Date(
          dayjs(e.appointmentData?.startDatetime)
            .tz(String(defaultUserProfileRegion))
            .format(JsonDateFormat.YYYYMMDDHHmmss)
        )

        startDatetime.current.setHours(new Date().getHours() + 1)

        endDatetime.current = new Date(
          dayjs(e.appointmentData?.endDatetime)
            .tz(String(defaultUserProfileRegion))
            .subtract(1, 'day')
            .format(JsonDateFormat.YYYYMMDDHHmmss)
        )
        endDatetime.current.setHours(new Date().getHours() + 1)
      } else {
        // 開始日/終了日=ドラッグして選択した時間
        // 開始時刻/終了時刻=ドラッグして選択した時間
        startDatetime.current = new Date(
          dayjs(e.appointmentData?.startDatetime)
            .tz(String(defaultUserProfileRegion))
            .format(JsonDateFormat.YYYYMMDDHHmmss)
        )
        endDatetime.current = new Date(
          dayjs(e.appointmentData?.endDatetime)
            .tz(String(defaultUserProfileRegion))
            .format(JsonDateFormat.YYYYMMDDHHmmss)
        )
      }

      // Open crew event entry dialog
      openEventEntryDialog()
    },
    [currentView, defaultUserProfileRegion, openEventEntryDialog]
  )

  // Render date cell
  const renderDateCell = useCallback(
    ({ date }: { date: Date }) => {
      return <DateCell date={date} currentView={currentView} />
    },
    [currentView]
  )

  // render data cell
  const renderDataCell = useCallback(
    (
      props: PropsWithChildren<{
        data: {
          startDate: Date
        }
      }>
    ) => {
      return <DataCell {...props} currentView={currentView} />
    },
    [currentView]
  )

  const [schedulerWidth, setSchedulerWidth] = useState<number>()

  // Listen layout change to update schedule width
  // Cell item position/width will be recalculated by the devextreme after update schedule width
  const schedulerWrapperRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() =>
      setSchedulerWidth(node.offsetWidth)
    )
    resizeObserver.observe(node)
  }, [])

  const displayView = useMemo(() => {
    if (currentView === ProjectDetailEventTimePeriod.Monthly.value) {
      return 'month'
    } else if (currentView === ProjectDetailEventTimePeriod.Weekly.value) {
      return 'week'
    } else {
      return 'timelineMonth'
    }
  }, [currentView])

  // Appointment collector render
  const handleAppointmentCollectorRender = useCallback(
    (data: SchedulerTypes.AppointmentCollectorTemplateData) => {
      return (
        <div className="crew-link">
          {t('label.selectMore', {
            value: data.appointmentCount,
          })}
        </div>
      )
    },
    [t]
  )

  return (
    <div
      className={classNames(
        'overflow-hidden',
        currentView !== ProjectDetailEventTimePeriod.GroupMonthly.value &&
          'flex-1'
      )}
      ref={schedulerWrapperRef}
    >
      <CrewScheduler
        id="projectDetailScheduler"
        // Set the `custom-scheduler` class  to display Scheduler like Figma
        className="custom-scheduler"
        // DBに保存されている日時はUTC時間であるため、スケジューラが自動的にクライアントのタイムゾーンに変換できるようにタイムゾーンを指定する必要がある。
        timeZone={defaultUserProfileRegion as string}
        ref={schedulerRef}
        dataSource={
          currentView === ProjectDetailEventTimePeriod.GroupMonthly.value
            ? eventScheduleGroupMonthDataSource
            : eventDataSource
        }
        defaultCurrentView="month"
        defaultCurrentDate={currentDate}
        currentView={displayView}
        startDateExpr="startDatetime"
        endDateExpr="endDatetime"
        dateCellRender={renderDateCell}
        dataCellComponent={renderDataCell}
        onCurrentDateChange={handleCurrentDateChange}
        customizeDateNavigatorText={customizeDateNavigatorText}
        showCurrentTimeIndicator={false}
        onAppointmentRendered={handleAppointmentRendered}
        onAppointmentClick={handleAppointmentClick}
        onAppointmentDblClick={handleAppointmentDblClick}
        height="100%"
        appointmentTooltipComponent={renderAppointmentTooltip}
        onAppointmentFormOpening={handleOpenEventEntryDialog}
        onContentReady={onContentReady}
        allDayExpr="isAllDay"
        width={schedulerWidth}
        timeCellRender={renderTimeCell}
        appointmentCollectorRender={handleAppointmentCollectorRender}
      >
        <View type="month" appointmentComponent={Appointment} />
        <View type="week" appointmentComponent={WeekAppointment} />
        <View
          type="timelineMonth"
          groups={groups}
          resourceCellRender={renderResourceCell}
          maxAppointmentsPerCell={3}
          appointmentComponent={Appointment}
        />

        <Resource
          dataSource={eventKindDataSource}
          fieldExpr="eventKind.id"
          useColorAsDefault={true}
          colorExpr="displayColor"
        />

        <Resource fieldExpr="user.id" dataSource={projectMembersDataSource} />
      </CrewScheduler>

      <EventEntryDialog
        isEditMode={false}
        projectId={projectId ?? ''}
        title={t('label.registerNewMeeting')}
        isOpen={isEventEntryDialogOpen}
        onClose={closeEventEntryDialog}
        onSubmit={handleEventRegistered}
        startDatetime={startDatetime.current}
        endDatetime={endDatetime.current}
      />
    </div>
  )
})
