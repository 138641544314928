import { useCrewNotificationProject } from 'components/elements/crewNotificationProject/useCrewNotificationProject'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useToast } from 'hooks/useToast'
import { useTranslation } from '@crew/modules/i18n'
import BaselineNotifications from '~icons/ic/baseline-notifications'
import BaseLineNotificationsOff from '~icons/ic/baseline-notifications-off'
import Check from '~icons/material-symbols/check'
import {
  PersonalProjectSetingType,
  SettingNotificationProject,
} from '@crew/enums/app'
import { CrewDropDownButton } from '../crewDropDownButton/crewDropDownButton'
import { GetPersonalProjectSettingsRequest } from '@crew/apis/setting/models/getPersonalProjectSettings/request'
import { useGetPersonalProjectSettingsQuery } from '@crew/apis/setting/settingApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { useValueChangeEffect } from '@crew/hooks'
import { useAppSelector } from 'states/hooks'

type MenuItemData = {
  value: string
  text: string
}

export type CrewNotificationProjectProps = {
  projectId: string
}

export const CrewNotificationProject: FC<CrewNotificationProjectProps> = memo(
  (props) => {
    const {
      updateNotificationSetting,
      isLoadingReplacePersonalProjectSettings,
    } = useCrewNotificationProject(props.projectId)

    const { t } = useTranslation()
    const toast = useToast()

    // プロジェクト通知設定の更新を検知して再レンダリングを走らせるためのトリガー
    const projectNotificationSettingEventMessage = useAppSelector(
      (state) => state.app.personalProjectSettingEventMessage
    )

    const [selectedNotificationSetting, setSelectedNotificationSetting] =
      useState<string>(SettingNotificationProject.All.value)

    // プロジェクトの通知設定を取得する
    const projectSettingsRequestParams:
      | GetPersonalProjectSettingsRequest
      | undefined = props.projectId
      ? {
          projectId: props.projectId,
          keys: [PersonalProjectSetingType.PersonalProjectNotification],
        }
      : undefined
    const { data: getPersonalProjectSettings, refetch } =
      useGetPersonalProjectSettingsQuery(
        projectSettingsRequestParams ?? skipToken
      )

    // プロジェクトの通知設定
    const projectNotificationSetting = useMemo(
      () =>
        getPersonalProjectSettings?.settings?.find(
          (setting) =>
            setting.key ===
            PersonalProjectSetingType.PersonalProjectNotification
        )?.value,
      [getPersonalProjectSettings]
    )

    // 通知アイコンクリック時に表示するメニューのデータ
    const contextMenuData = useMemo(() => {
      return Object.values(SettingNotificationProject)
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((item) => {
          return {
            value: item.value,
            text: t(item.text),
          }
        })
    }, [t])

    // 通知ありかどうかを扱う説明変数
    const isNotify =
      projectNotificationSetting !== SettingNotificationProject.None.value

    // アイコンの色
    // 通知ありかどうかに応じて色を変える
    const iconColorClassName = useMemo(() => {
      return isNotify ? 'text-crew-green-500' : 'text-crew-gray-400'
    }, [isNotify])

    // 通知アイコン描画
    // 通知ありかどうかに応じてアイコンを変える
    const renderNotificationIcon = useMemo(
      () =>
        // オフ(通知なし)の場合のみ斜線ベルアイコン、それ以外はベルアイコンを表示する
        isNotify ? (
          <BaselineNotifications
            width={20}
            height={20}
            className={iconColorClassName}
          />
        ) : (
          <BaseLineNotificationsOff
            width={20}
            height={20}
            className={iconColorClassName}
          />
        ),
      [iconColorClassName, isNotify]
    )

    // メニューアイテム描画
    // 選択されている通知設定に応じてチェックマークを表示する
    const renderMenuItem = useCallback(
      (itemData: MenuItemData) => {
        return (
          <div className="flex flex-row items-center gap-2.5">
            <div className="w-5">
              {selectedNotificationSetting === itemData.value && (
                <Check width={20} height={20} className="text-crew-blue-500" />
              )}
            </div>

            <span>{itemData.text}</span>
          </div>
        )
      },
      [selectedNotificationSetting]
    )

    // メニュークリック時のイベントハンドラ
    const handleMenuItemClick = useCallback(
      async (itemData: MenuItemData) => {
        try {
          // クリックされたアイテムに応じて処理を分ける
          // 呼び出す処理は同じだが、MenuItemDataとSettingNotificationProjectは厳密には別物なので敢えて分岐を書いている
          switch (itemData.value) {
            case SettingNotificationProject.All.value:
              await updateNotificationSetting(SettingNotificationProject.All)
              setSelectedNotificationSetting(
                SettingNotificationProject.All.value
              )
              break
            case SettingNotificationProject.Mention.value:
              await updateNotificationSetting(
                SettingNotificationProject.Mention
              )
              setSelectedNotificationSetting(
                SettingNotificationProject.Mention.value
              )
              break
            case SettingNotificationProject.MentionAndReply.value:
              await updateNotificationSetting(
                SettingNotificationProject.MentionAndReply
              )
              setSelectedNotificationSetting(
                SettingNotificationProject.MentionAndReply.value
              )
              break
            case SettingNotificationProject.None.value:
              await updateNotificationSetting(SettingNotificationProject.None)
              setSelectedNotificationSetting(
                SettingNotificationProject.None.value
              )
              break
          }

          // 従来のフォローボタンや通知ボタンと同様、更新に成功したときのトーストは表示しない
        } catch (err) {
          // 更新に失敗した場合はトーストを表示する
          toast.error(t('message.general.failedToUpdate'))
        }
      },
      [t, toast, updateNotificationSetting]
    )

    // プロジェクトの通知設定が変更された場合、選択された通知設定を更新する
    useValueChangeEffect(
      () => {
        if (projectNotificationSetting) {
          setSelectedNotificationSetting(projectNotificationSetting)
        }
      },
      [projectNotificationSetting],
      projectNotificationSetting
    )

    // プロジェクト通知設定の変更を検知したら再取得する
    useValueChangeEffect(
      () => {
        refetch()
      },
      [projectNotificationSetting, refetch],
      projectNotificationSettingEventMessage
    )

    return (
      <CrewDropDownButton
        icon={renderNotificationIcon}
        items={contextMenuData}
        keyExpr="value"
        displayExpr="text"
        onItemClick={handleMenuItemClick}
        selectedItemKey={selectedNotificationSetting}
        showArrowIcon={false}
        showDivider={false}
        highlightSelected={false}
        stylingMode="text"
        itemRender={renderMenuItem}
        buttonSize="sm"
        disabled={isLoadingReplacePersonalProjectSettings}
      />
    )
  }
)
